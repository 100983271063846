import CreationSuccess from "../components/signup/CreationSuccess";
import SideFrame from "../components/signup/SideFrame";

export default function AccountCreationSuccess(){
    return(
        <div className="create-account-container">
        <SideFrame />
        
          {<CreationSuccess />} 

    </div>
    )
}