import { useContext, useState } from "react";
import { Toast } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { variableManager } from "../../context-api/VariableContex";
import copy from "../../icons/copy.svg";
import "../../styles/modals.scss";
export default function AccountNumber(props) {
  const [modalShow, setModalShow] = useState(false);
  const { user, name, nameCase, } = useContext(variableManager);
  const [show, setShow] = useState(false)
  // console.log(name)
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="modalContent">
        <h2>Accounts</h2>
        <p>Your Naira Account</p>

        <div className="account-area">
          <div className="details">
            <p>Account Holder</p>
            <b>{`${nameCase(user.businessName)}`}</b>
          </div>

          <Toast onClose={() => setShow(false)} show={show} delay={2000} autohide className="toast"> 
          <Toast.Body>Copied to clipboard!</Toast.Body>
          {/* <Toast.Header></Toast.Header> */}
        </Toast>


          {/* <div className="icon pointer" >
            <img src={copy} draggable="false" alt="copy to clipboard" />
          </div> */}
        </div>

        <div className="account-area">
          <div className="details">
            <p>Account Number</p>
            <b>{user.Accounts &&  user.Accounts[0]?( user.Accounts[0].accountNumber):null}</b>
          </div>

          <div className="icon pointer" onClick={() => { 
          navigator.clipboard.writeText( user.Accounts &&  user.Accounts[0]? user.Accounts[0].accountNumber:null) 
          setShow(true)
        }}>
            <img src={copy} draggable="false" alt="copy to clipboard" />
          </div>
        </div>

      

        <div className="account-area">
          <div className="details">
            <p>Bank</p>
            <b>{user.Accounts &&  user.Accounts[0]? nameCase(user.Accounts[0].bankName):""}</b>
          </div>

          {/* <div className="icon pointer" >
            <img src={copy} draggable="false" alt="copy to clipboard" />
          </div> */}
        </div>
        
        <div className="note">
          <div className="part">
            <h5>KINDLY NOTE: </h5>
            <p>
              This is your unique Bank Account, money sent here will be
              automatically funded to your wallet
            </p>
          </div>
          <div className="part">
            <h5>How to use</h5>
            <div>
             <ol>
                <li>Customer log into any bank app or Mongoro App</li>
                <li>Select "{user.Accounts &&  user.Accounts[0]? nameCase(user.Accounts[0].bankName):""}" as recipient bank</li>
                <li>Enter your virtual account and confirm transfer details</li>
             </ol>
            </div>
          </div>
        </div>

        {/* <div className="input-area">
                    <div className="label">
                        <label htmlFor="back">Bank</label>
                    </div>
                    <div className="input">
                        <input type="text" className='accountInput' value={"Mongoro"} disabled/>                 
                    </div>
                </div>
                
                   <div className="input-area">
                    <div className="label">
                        <label htmlFor="enterEvn">Account Number</label>
                    </div>
                    <div className="input">
                        <input type="text" className='accountInput' id="enterBvn"  value={user.Accounts &&  user.Accounts[0]?( user.Accounts.data.accountNumber):null} disabled />
                    </div>
                </div>


                   <div className="input-area">
                    <div className="label">
                        <label htmlFor="enterEvn">Account Name</label>
                    </div>
                    <div className="input">
                        <input type="text" className='accountInput' id="enterBvn"   value={`${nameCase(user.firstName)} ${nameCase(user.middleName)} ${nameCase(user.surname)}`} disabled     />
                    </div>
                </div>

 */}

        {/* <div className="buttons">
          <button className="btn-active ">Continue</button>
        </div> */}
      </Modal.Body>
    </Modal>
  );
}
