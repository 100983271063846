import { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../../../styles/modals.scss";
import { KycManager } from "../../../context-api/KycContext";
import { variableManager } from "../../../context-api/VariableContex";
import { formatAmount } from "../../../utils/formatAmount";
import { Spinner } from "react-bootstrap";
import axiosInstance from "../../../utils/axios_instance";
export default function MarkInvoicePaid(props) {
  const [loading, setLoading] = useState(false);

  const { getInvoices, setShowResolveInvoice } = useContext(variableManager);

  const invoiceDetails = props.invoiceDetails;

  async function markAsPaid(invoiceId) {
    setLoading(true);
    try {
      await axiosInstance
        .put(`/business/invoice/status`, {
          invoiceID: invoiceId,
        })
        .then(() => {
          setLoading(false);
          getInvoices();
          setShowResolveInvoice();
        });
    } catch (error) {
      console.log(error);

      setLoading(false);
    }
  }

  return (
    <div className="sendpayment">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="modalContent">
          <h2>Clear Invoice</h2>

          <div className="input-area">
            <div className="label">
              <label htmlFor="invoiceNumber">Invoice Number</label>
            </div>
            <div className="inpu d-flex ">
              <input
                type="text"
                id="invoiceNumber"
                name="invoiceNumber"
                value={invoiceDetails?.invoiceID}
                disabled
              />
            </div>

            <div className=" ">
              <label htmlFor="username">Amount </label>
              <div className="d-flex">
                <input
                  type="text"
                  id="username"
                  placeholder="00,000,000 "
                  value={`₦${formatAmount(invoiceDetails?.total)}`}
                  disabled
                  name="amount"
                />
              </div>
            </div>

            <div className="button-section">
              <button
                className={"btn-active"}
                disabled={loading}
                onClick={() => { 
                  markAsPaid(invoiceDetails.invoiceID);
                }}
              >
                {loading ? <Spinner size="sm" /> : "Mark as Paid"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
