import React, { useState } from "react";
import Alert from "../../../asset/icon/AlertIcon.svg";
import Close from "../../../asset/icon/ModalClose.svg";

const CardRequest = ({ handleClose, next }) => {
  const [insufficientBalance] = useState(false);

  return (
    <div className="manageCard-modalContainerStep">
      {insufficientBalance ? (
        <div className="manageCard-alert">
          <img src={Alert} alt="alert" />
          <p>Insufficient Funds</p>
          <p>
            You do not have up to $5 in your USD balance, please add funds and
            try again
          </p>
          <button>Fund USD Balance</button>
          <p>Okay</p>
        </div>
      ) : (
        <div className="manageCard-inputFormContainer">
          <div className="manageCard-inputFormClose">
            <img src={Close} alt="close" onClick={handleClose} />
          </div>
          <p>Card Request</p>
          <p>No Virtual cards, no physical card</p>
          <div className="manageCard-inputForm">
            <div className="input-area">
              <div className="label">
                <label htmlFor="physicalCard">Card Type</label>
              </div>
              <div className="input">
                <select
                  name="physicalCard"
                  id="physicalCard"
                  //   onChange={(e) => {
                  //     handleChange(e);
                  //   }}
                >
                  <option>Select Card</option>
                  <option value="naira">Naira Card</option>
                  <option value="dollar">Dollar Card</option>
                </select>
              </div>
            </div>

            <div className="input-area">
              <div className="label">
                <label htmlFor="phone">Phone Number</label>
              </div>
              <div className="input">
                <input
                  type="tel"
                  id="phone"
                  placeholder="Enter Phone Number"
                  //   value={user.walletTag}
                  style={{ backgroundColor: "#ffffff" }}
                />
              </div>
            </div>

            <div className="input-area">
              <div className="label">
                <label htmlFor="state">State</label>
              </div>
              <div className="input">
                <select
                  name="state"
                  id="state"
                  //   onChange={(e) => {
                  //     handleChange(e);
                  //   }}
                >
                  <option>Select State</option>
                  <option value="naira">Naira Card</option>
                  <option value="dollar">Dollar Card</option>
                </select>
              </div>
            </div>

            <div className="input-area">
              <div className="label">
                <label htmlFor="lga">LGA</label>
              </div>
              <div className="input">
                <input
                  type="text"
                  id="lga"
                  placeholder="Select Local Government"
                  //   value={user.walletTag}
                  style={{ backgroundColor: "#ffffff" }}
                />
              </div>
            </div>

            <div className="input-area">
              <div className="label">
                <label htmlFor="address">Address</label>
              </div>
              <div className="input">
                <input
                  type="text"
                  id="address"
                  placeholder="Select Card Type"
                  //   value={user.walletTag}
                  style={{ backgroundColor: "#ffffff" }}
                />
              </div>
            </div>

            <div className="input-area">
              <div className="label">
                <label htmlFor="street">Street no</label>
              </div>
              <div className="input">
                <input
                  type="text"
                  id="street"
                  placeholder="Enter Street no"
                  //   value={user.walletTag}
                  style={{ backgroundColor: "#ffffff" }}
                />
              </div>
            </div>

            <div className="input-area">
              <div className="label">
                <label htmlFor="note">Additional Note</label>
              </div>
              <div className="input">
                <input
                  type="text"
                  id="note"
                  placeholder="Leave a Message"
                  //   value={user.walletTag}
                  style={{ backgroundColor: "#ffffff" }}
                />
              </div>
            </div>
            <button onClick={next}>Proceed</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CardRequest;
