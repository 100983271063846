import { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { KycManager } from "../../../context-api/KycContext";
import { variableManager } from "../../../context-api/VariableContex";
import choose_contact from "../../../icons/choose_contact.svg";
import close from "../../../icons/close.svg";
import search from "../../../icons/search.png";
import "../../../styles/modals.scss";
import axiosInstance from "../../../utils/axios_instance";
export default function SendToUsername(props) {
  const { payModalShow, setPayModalShow, initPayModal } =
    useContext(KycManager);
  const {
    nameCase,
    mongoroReceiver,
    setMongoroReceiver,
    walletTag,
    setwalletTag,
    handleMutateUsername,
  } = useContext(variableManager);

  const [walletTagError, setwalletTagError] = useState("");

  async function handleValidateUsername(username) {
    username = handleMutateUsername(username);
    setwalletTagError("");
    if (username !== "@") {
      try {
        await axiosInstance.get(`/business/${username}`).then((resp) => {
          const user = resp.data.data; 
          setwalletTag(
            nameCase(user.businessName?user.businessName :user.firstName + " " + user.surname)
          );
          // setwalletTag(nameCase(user.firstName) + " " + nameCase(user.surname));
          setwalletTagError("");
        });
      } catch (error) {
        if (error.response) {
          setwalletTagError(error.response.data.message);
        }
        setwalletTag("");
      }
    }
  }

  useEffect(() => {
    if (!walletTag) {
      const timeOutId = setTimeout(
        () => handleValidateUsername(mongoroReceiver.walletTag),
        500
      );
      return () => clearTimeout(timeOutId);
    }
  }, [mongoroReceiver.walletTag]);

  // console.log(mongoroReceiver)
  return (
    <div className="sendpayment">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header
          onHide={() => {
            setwalletTag("");
            setwalletTagError("");
          }}
        >
          <div
            className="modal-header pointer"
            onClick={() => {
              setPayModalShow({ ...payModalShow, toUsername: false });
              setwalletTag("");
              setMongoroReceiver({ ...mongoroReceiver, walletTag: "" });
            }}
          >
            <img draggable="false" src={close} alt="close" />
          </div>
        </Modal.Header>

        <Modal.Body className="modalContent">
          <div className="input-area">
            <div className="username dflex">
              <label htmlFor="username">@</label>
              <div className="d-flex align-items-center">
                <input
                  type="text"
                  id="username"
                  placeholder="enter username"
                  value={handleMutateUsername(mongoroReceiver.walletTag)}
                  onChange={(e) => {
                    // setUsername(e.target.value)
                    setMongoroReceiver({
                      ...mongoroReceiver,
                      walletTag: "@" + e.target.value,
                    });

                    setwalletTag("");
                    setwalletTagError("");
                  }}
                />

                {!mongoroReceiver.walletTag || !walletTag ? (
                  <span
                    className="search-username"
                    onClick={() =>
                      handleValidateUsername(mongoroReceiver.walletTag)
                    }
                  >
                    <img draggable="false" src={search} alt="search" />
                  </span>
                ) : null}
              </div>

              {mongoroReceiver.walletTag && walletTag && <p>{walletTag}</p>}
              {walletTagError && <p className="error">{walletTagError}</p>}
              <div
                className="text-primary pointer"
                onClick={() =>
                  setPayModalShow({
                    ...initPayModal,
                    chooseContact: true,
                    toUsername: true,
                  })
                }
              >
                <img
                  draggable="false"
                  src={choose_contact}
                  alt="choose contact"
                />
              </div>
            </div>

            <div className="buttons">
              {walletTag ? (
                <button
                  className={
                    mongoroReceiver.walletTag ? "btn-active" : "btn-inactive"
                  }
                  onClick={() =>
                    setPayModalShow({ ...initPayModal, payNow: true })
                  }
                >
                  Confirm
                </button>
              ) : (
                <button
                  className={
                    mongoroReceiver.walletTag ? "btn-inactive" : "btn-inactive"
                  }
                >
                  Confirm
                </button>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* <div className="modals">
                <ContactList
                show={payModalShow.chooseContact}
                onHide={()=>setPayModalShow({...payModalShow, chooseContact:false})}
                />
            </div> */}
    </div>
  );
}
