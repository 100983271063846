import { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import avatar from "../../../icons/avatar.jpg";
import close from "../../../icons/close.svg";

import { BiSearch } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";
import "../../../styles/modals.scss";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { KycManager } from "../../../context-api/KycContext";
import PayNow from "./PayNow";
import axios from "axios";
import { variableManager } from "../../../context-api/VariableContex";
import { Spinner } from "react-bootstrap";
import axiosInstance from "../../../utils/axios_instance";
export default function ContactList(props) {
  const [userName, setUsername] = useState("");
  const [modalShowPayNow, setModalShowPayNow] = useState(false);
  const {
    setRerender,
    payModalShow,
    setPayModalShow,
    initPayModal,
    profile,
    setProfile,
  } = useContext(KycManager);
  const {
    url,
    msg,
    setMsg,
    user, nameCase,
    mongoroReceiver,
    setMongoroReceiver,
    setwalletTag,
    accountDetails,
    setAccountDetails,
    handleDeleteBeneficiary,
  } = useContext(variableManager);
 
  const [bankBeneficiaries, setBankBeneficiaries] = useState([]);
  const [mongoroBeneficiaries, setMongoroBeneficiaries] = useState([]);
  const [clicked, setClicked] = useState({
    id: "",
    search: "",
  });

  const [searchResult, setSearchResult] = useState("");

  useEffect(() => {
    handleGetBeneficiaries();
  }, [user]); 
 
  async function handleGetBeneficiaries() {
    try {
      const list = await axiosInstance.get(`/business/beneficiary/user`);
      const data = list.data.data; 
      setBankBeneficiaries(data.bankBeneficiary);
      setMongoroBeneficiaries(data.mongoroBeneficiary);
    } catch (error) {
      console.log(error);
    }
  }
  

  function searchData(data, query) {
    let result;
    if (payModalShow.toUsername) {
      result = data.filter((a) => {
        if (
          a.beneficiaryName.toLowerCase().includes(query) ||
          a.walletTag.includes(query)
        ) {
          return a;
        } else {
          return false;
        }
      });
    } else {
      result = data.filter((a) => {
        if (
          a.beneficiaryName.toLowerCase().includes(query) ||
          a.bankName.toLowerCase().includes(query) ||
          a.accountNumber.includes(query)
        ) {
          return a;
        } else {
          return false;
        }
      });
    }

    if (result.length) {
      return result;
    } else {
      return [{ beneficiaryName: "No results found" }];
    }
  }

  return (
    <div className="sendpayment">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header>
          <div
            className="modal-header pointer"
            onClick={() => {
              setMongoroReceiver({ ...mongoroReceiver, walletTag: "" });
              setPayModalShow({ ...payModalShow, chooseContact: false });
            }}
          >
            <img draggable="false" src={close} alt="close" />
          </div>
        </Modal.Header>

        <Modal.Body className="modalContent" style={{ height: "100vh" }}>
          <div className="input-area">
            <div className="choose-contact">
              <div className="head mb-4">
                <BsChevronLeft
                  className="pointer"
                  onClick={() =>
                    setPayModalShow({
                      ...payModalShow,
                      chooseContact: false,
                    })
                  }
                />
                <h5>Choose Beneficiary</h5>
                <div className="search">
                  <BiSearch
                    onClick={() => setClicked({ ...clicked, search: true })}
                  />
                  {clicked.search && (
                    <div className="box">
                      <AiOutlineClose
                        className="mo-danger pointer"
                        onClick={() => {
                          setClicked({ ...clicked, search: false });
                          setSearchResult("");
                        }}
                      />
                      <input
                        type="search"
                        autoFocus
                        placeholder="find beneficiary"
                        onChange={(e) => {
                          if (payModalShow.toUsername) {
                            setSearchResult(
                              searchData(mongoroBeneficiaries, e.target.value)
                            );
                          } else {
                            setSearchResult(
                              searchData(bankBeneficiaries, e.target.value)
                            );
                          }
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>

              {payModalShow.toUsername &&
              !searchResult &&
              mongoroBeneficiaries.length ? (
                mongoroBeneficiaries.map((e, i) => {
                  return (
                    <div
                      className="contact"
                      onClick={() => {
                        setMongoroReceiver({
                          ...mongoroReceiver,
                          walletTag: e.walletTag,
                        });
                        setwalletTag(e.beneficiaryName);
                        setPayModalShow({
                          ...initPayModal,
                          toUsername: true,
                        });
                      }}
                      key={i}
                    >
                      <div className="details pointer">
                        <div className="image">
                          {e.image && (
                            <img src={e.image} alt={e.beneficiaryName} />
                          )}
                        </div>
                        <div className="names">
                          <p className="first">{nameCase(e.beneficiaryName)}</p>
                          <p>@{e.walletTag}</p>
                          {/* <p>{e.accountNumber}</p> */}
                          <input type="hidden" value={e.accountBank} />
                        </div>
                      </div>

                      <div
                        className="right"
                        onClick={(event) => {
                          setClicked({ ...clicked, id: e.beid });
                          event.stopPropagation();
                          setProfile({
                            ...profile,
                            deletePrompt: {
                              ...profile.deletePrompt,
                              beneficiary: true,
                              beneficiaryId: e.beid,
                            },
                          });
                        }}
                      >
                        {/* <BsChevronRight /> */}

                        {msg.spinner && e.beid === clicked.id ? (
                          <Spinner size="sm" variant="warning" />
                        ) : (
                          <AiOutlineClose className="mo-danger pointer" />
                        )}
                      </div>
                    </div>
                  );
                })
              ) : payModalShow.toUsername &&
                searchResult &&
                searchResult.length ? (
                searchResult.map((e, i) => {
                  return (
                    <div
                      className="contact"
                      onClick={() => {
                        setMongoroReceiver({
                          ...mongoroReceiver,
                          walletTag: e.walletTag,
                        });
                        setwalletTag(e.beneficiaryName);
                        setPayModalShow({
                          ...initPayModal,
                          toUsername: true,
                        });
                      }}
                      key={i}
                    >
                      <div className="details pointer">
                        <div className="image">
                          {e.image && (
                            <img src={e.image} alt={e.beneficiaryName} />
                          )}
                        </div>
                        <div className="names">
                          <p className="first">{nameCase(e.beneficiaryName)}</p>
                          <p>{e.walletTag}</p>
                          {/* <p>{e.accountNumber}</p> */}
                          <input type="hidden" value={e.accountBank} />
                        </div>
                      </div>

                      <div
                        className="right"
                        onClick={(event) => {
                          setClicked({ ...clicked, id: e.beid });
                          event.stopPropagation();
                          setProfile({
                            ...profile,
                            deletePrompt: {
                              ...profile.deletePrompt,
                              beneficiary: true,
                              beneficiaryId: e.beid,
                            },
                          });
                        }}
                      >
                        {/* <BsChevronRight /> */}
                        {msg.spinner && e.beid === clicked.id ? (
                          <Spinner size="sm" variant="warning" />
                        ) : (
                          e._id && (
                            <AiOutlineClose className="mo-danger pointer" />
                          )
                        )}
                      </div>
                    </div>
                  );
                })
              ) : payModalShow.toUsername &&
                !searchResult &&
                !mongoroBeneficiaries.length ? (
                <p>
                  You have no saved beneficiaries yet, transact now to save
                  beneficiaries
                </p>
              ) : null}

              {!payModalShow.toUsername &&
              !searchResult &&
              bankBeneficiaries.length ? (
                bankBeneficiaries.map((e, i) => {
                  return (
                    <div className="contact" key={i}>
                      <div
                        className="details pointer"
                        onClick={() => {
                          setAccountDetails({
                            ...accountDetails,
                            accountNumber: e.accountNumber,
                            accountBank: e.accountBank,
                            accountName: e.beneficiaryName,
                            bankName: e.bankName,
                          });
                          setPayModalShow({
                            ...payModalShow,
                            chooseContact: false,
                          });
                        }}
                      >
                        {/* <div className="image">
                            <img src={avatar} alt="contact" />
                          </div> */}
                        <div className="names">
                          <p className="first">{nameCase(e.beneficiaryName)}</p>
                          <p>{e.bankName ? e.bankName : null}</p>
                          <p>{e.accountNumber}</p>
                          {/* <p>{e.accountNumber}</p> */}
                          <input type="hidden" value={e.accountBank} />
                        </div>
                      </div>

                      <div
                        className="right"
                        onClick={(event) => {
                          setClicked({ ...clicked, id: e.beid });
                          event.stopPropagation();
                          setProfile({
                            ...profile,
                            deletePrompt: {
                              ...profile.deletePrompt,
                              beneficiary: true,
                              beneficiaryId: e.beid,
                            },
                          });
                        }}
                      >
                        {/* <BsChevronRight /> */}
                        {msg.spinner && e.beid === clicked.id ? (
                          <Spinner size="sm" variant="warning" />
                        ) : (
                          <AiOutlineClose className="mo-danger pointer" />
                        )}
                      </div>
                    </div>
                  );
                })
              ) : !payModalShow.toUsername &&
                searchResult &&
                searchResult.length ? (
                searchResult.map((e, i) => {
                  return (
                    <div className="contact" key={i}>
                      <div
                        className="details pointer"
                        onClick={() => {
                          setAccountDetails({
                            ...accountDetails,
                            accountNumber: e.accountNumber,
                            accountBank: e.accountBank,
                            accountName: e.beneficiaryName,
                            bankName: e.bankName,
                          });
                          setPayModalShow({
                            ...payModalShow,
                            chooseContact: false,
                          });
                        }}
                      >
                        {/* <div className="image">
                            <img src={avatar} alt="contact" />
                          </div> */}
                        <div className="names">
                          <p className="first">{nameCase(e.beneficiaryName)}</p>
                          <p>{e.bankName ? e.bankName : null}</p>
                          <p>{e.accountNumber}</p>
                          {/* <p>{e.accountNumber}</p> */}
                          <input type="hidden" value={e.accountBank} />
                        </div>
                      </div>

                      <div
                        className="right"
                        onClick={(event) => {
                          setClicked({ ...clicked, id: e.beid });
                          event.stopPropagation();
                          setProfile({
                            ...profile,
                            deletePrompt: {
                              ...profile.deletePrompt,
                              beneficiary: true,
                              beneficiaryId: e.beid,
                            },
                          });
                        }}
                      >
                        {/* <BsChevronRight /> */}
                        {msg.spinner && e.beid === clicked.id ? (
                          <Spinner size="sm" variant="warning" />
                        ) : (
                          <AiOutlineClose className="mo-danger pointer" />
                        )}
                      </div>
                    </div>
                  );
                })
              ) : !payModalShow.toUsername &&
                !searchResult &&
                !bankBeneficiaries.length ? (
                <p>
                  You have no saved beneficiaries yet, transact now to save
                  beneficiaries
                </p>
              ) : null}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* <div className="modal">
                <PayNow
                show={payModalShow.payNow}
                onHide={()=> setPayModalShow({...initPayModal, payNow:false})}
                />
            </div> */}
    </div>
  );
}
