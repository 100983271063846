import { useContext } from "react";
import axiosInstance from "../../utils/axios_instance";
import KycContext from "../../context-api/KycContext";

export async function rechargeCableTv(payload) {
  // const {setShowBIll} = useContext(KycContext)
  console.log("loading");
  
  let data = "";
  let error = "";

  try {
    await axiosInstance.post("/business/bill/payment", payload).then((resp) => {
        data = resp.data.data; 
    });
  } catch (err) {
    error= err;
  }

  return {data,error}
}


  