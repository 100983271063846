import toggle from "../../../icons/toggle2.svg";
import activate from "../../../icons/activate_pos.png";
import deactivate from "../../../icons/deactivate_pos.png";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import { useContext, useEffect, useState } from "react";
import { KycManager } from "../../../context-api/KycContext";
import { variableManager } from "../../../context-api/VariableContex";
import axiosInstance from "../../../utils/axios_instance";
import { nameCase } from "../../../utils/formatName";
import { BsThreeDotsVertical } from "react-icons/bs";
import TerminalDetails from "./TerminalDetails";
export default function MyTerminals() {
  const { user } = useContext(variableManager);
  const [showBalance, setShowBalance] = useState(false);
  const { showPos, setshowPos } = useContext(KycManager);

  const [posList, setPosList] = useState([
    {
      terminalId: "#1234534",
      activationDate: "June 1, 2020, 08:22AM",
      type: "Mongoro Amal",
      quantity: "1",
      location: "Abuja, Nigeria",
      activated: false,
      tid: 1,
    },
    {
      terminalId: "#1234534",
      activationDate: "June 1, 2020, 08:22AM",
      type: "Mongoro Amal",
      quantity: "1",
      location: "Abuja, Nigeria",
      activated: true,
      tid: 2,
    },
  ]);

  const [showOptions, setShowOptions] = useState({
    action: false,
    actionId: "",
    selected: {},
  });

  const [togglePage, setTogglePage] = useState({
    posList: true,
    posDetails: false,
  });

  const [selectedPOS, setSelectedPOS] = useState({
    terminalId: "",
  });

  async function handleToggleShowBalance() {
    setShowBalance(!showBalance);

    try {
      await axiosInstance.post(`/business/balance`, {
        balance: !showBalance,
      });
    } catch (error) {
      setShowBalance(showBalance);
    }
  }
  async function getUserPOsRequests() {
    try {
      await axiosInstance.get(`business/mpos/business`).then((resp) => {
        if (resp.data.data) {
          setPosList(resp?.data?.data);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getUserPOsRequests();
  }, []);

  return togglePage.posList ? (
    <div
      onClick={() =>
        setShowOptions({
          ...showOptions,
          action: false,
          actionId: "",
        })
      }
    >
      <div className="account-balance-container my-0 p-0 align-items-end">
        <div className="cards-section ">
          <div className="account my-3">
            <p>Account Status: REGULAR NGN</p>
            {user && user.Accounts && user.Accounts[0] ? (
              <p className="w-75 text-center accountNumber">
                {user.Accounts[0].accountNumber} |{" "}
                {nameCase(user && user.Accounts && user.Accounts[0].bankName)}
              </p>
            ) : (
              "**********"
            )}
            {(user && user.Accounts && user.Accounts[0]) ||
            user.walletBalance ? (
              <div className="h-100">
                <div className="d-flex align-items-center gap-2">
                  {showBalance ? (
                    <MdOutlineVisibility
                      className="pointer"
                      onClick={() => handleToggleShowBalance()}
                    />
                  ) : (
                    <MdOutlineVisibilityOff
                      className="pointer"
                      onClick={() => handleToggleShowBalance()}
                    />
                  )}
                  {showBalance ? (
                    <h4>₦{parseInt(user.walletBalance).toLocaleString()}</h4>
                  ) : (
                    <h4>*****</h4>
                  )}
                </div>
                <p className="last">
                  Last Transaction :{" "}
                  {new Date(user.walletUpdatedAt).toDateString()}{" "}
                </p>
              </div>
            ) : (
              <div className="h-100">
                <h4>₦ ***, ***, ***</h4>
                <p className="last">Last Transaction */**/**</p>
              </div>
            )}
          </div>
          <div className="toggle">
            <img src={toggle} alt="toggle" />
          </div>
        </div>

        <div className="activate-btn d-flex justify-content-end ">
          <button
            className="mo-active-bt w-100 h-0"
            onClick={() => {
              setshowPos({ ...showPos, terminal_id: true });
            }}
          >
            Activate POS Terminal
          </button>
        </div>
      </div>

      <div className="transaction-invoices-container mt-3">
        <b className="my-4 ms-2">Terminals</b>

        <div className="table-section">
          <table>
            <thead>
              <tr>
                <td>Device Code</td>
                <td>Activation Date</td>
                <td>POS Type</td>
                <td>Quantity</td>
                <td>Location</td>
                <td>Status</td>
              </tr>
            </thead>

            <tbody>
              {posList?.length
                ? posList.map((pos, i) => {
                    return (
                      <tr
                        onClick={() => {
                          setTogglePage({
                            ...togglePage,
                            posDetails: true,
                            posList: false,
                          });
                          setSelectedPOS({
                            ...selectedPOS,
                            terminalId: pos?.pid,
                          });
                        }}
                        key={i}
                      >
                        <td>{pos.terminalId}</td>
                        <td>
                          {" "}
                          {/* {new Date(pos?.timeCreated).toDateString()} June 1, 2020, 08:22AM */}
                          {new Date(pos?.timeCreated).toDateString()},{" "}
                          {new Date(pos?.timeCreated).toLocaleTimeString()}
                        </td>
                        <td>
                          <p>{nameCase(pos.type)}</p>
                        </td>
                        <td>{pos.quantity}</td>
                        <td>{`${nameCase(pos.city)}, ${nameCase(
                          pos.country
                        )}`}</td>
                        <td className="d-flex align-items-center position position-relative">
                          <b
                            style={{
                              color: pos.activationStatus ? "#32D16D" : "red",
                            }}
                          >
                            {pos?.activationStatus ? "ACTIVE" : "INACTIVE"}
                          </b>
                          <BsThreeDotsVertical
                            className="ms-3 pointer"
                            onClick={(event) => {
                              event.stopPropagation();
                              setShowOptions({
                                ...showOptions,
                                action: !showOptions.action,
                                actionId: pos.pid,
                                selected: pos,
                              });
                            }}
                          />
                          {showOptions.action &&
                            showOptions.actionId === pos.pid && (
                              <div className="action-center position position-absolute">
                                <div className="my-2">
                                  <button
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      setshowPos({
                                        ...showPos,
                                        terminal_id: true,
                                      });
                                    }}
                                    style={{ color: "#32D16D" }}
                                  >
                                    <img
                                      src={activate}
                                      width={15}
                                      alt="activate POS"
                                    />{" "}
                                    Activate Pos
                                  </button>
                                </div>

                                <div className="my-2">
                                  <button
                                    onClick={(event) => {
                                      event.stopPropagation();
                                    }}
                                    style={{ color: "red" }}
                                  >
                                    <img
                                      width={15}
                                      src={deactivate}
                                      alt="deactivate POS"
                                    />{" "}
                                    Deactivate pos
                                  </button>
                                </div>
                              </div>
                            )}
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>

        <div className="add-container ">
          <div className="add-new">{/* <img src={add} alt="add new" /> */}</div>
        </div>

        {posList?.length ? (
          <p className="showing">
            {" "}
            Showing{" "}
            <span className="shown">
              {/* {handleTruncateHistory(posList)} */}
              {posList.length}
            </span>{" "}
            of <span className="total">{posList.length}</span> enteries
          </p>
        ) : null}
      </div>
    </div>
  ) : togglePage.posDetails ? (
    <TerminalDetails
      closeFunc={() =>
        setTogglePage({ ...togglePage, posDetails: false, posList: true })
      }
      terminalId={selectedPOS.terminalId}
    />
  ) : null;
}
