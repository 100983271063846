import React from "react";
import Alert from "../../../asset/icon/ConfirmAlertIcon.svg";
import Close from "../../../asset/icon/ModalClose.svg";
import Back from "../../../asset/icon/Back.svg";
import Location from "../../../asset/icon/Location.svg";

const ConfirmCardDetails = ({ handleClose, prev }) => {
  return (
    <div className="manageCard-modalContainerStep">
      <div className="manageCard-modalConfirmDetails">
        <div>
          <img src={Back} alt="back" onClick={prev} />
          <p>Confirm Details</p>
          <img src={Close} alt="close" onClick={handleClose} />
        </div>
        <p>No Virtual cards, no physical card</p>
        <div>
          <div>
            <img src={Alert} alt="alert" />
          </div>
          <p>
            Make sure the address and phone number provided will be valid in
            least three weeks
          </p>
          <div>
            <div>
              <img src={Location} alt="location" />
              <div>
                <p>Address</p>
                <p>
                  No 24, W.F. kumuyi Street, Kado, Abuja Municpal Area Council
                </p>
              </div>
            </div>
            <div>
              <img src={Location} alt="location" />
              <div>
                <p>Phone Number</p>
                <p>+23480143312429</p>
              </div>
            </div>
          </div>
          <div>
            <button>Confirm</button>
            <button>Edit Address</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmCardDetails;
