import React, { useState } from "react";
import TrackComplete from "../../../asset/icon/TrackingStepCompletedIcon.svg";
import TrackPending from "../../../asset/icon/TrackPending.svg";

const TrackCard = () => {
  const [tab, setTab] = useState("naira");
  const [trackCompleted] = useState(true);
  //   const [processing, setProcessing] = useState(true);

  const handleCardSelection = (card) => () => {
    setTab(card);
  };

  const cardTracking = [
    {
      state: "Processing",
      address: "Mongoro Headquartes Wuse 2, Abuja",
      date: "17 Oct. 2023",
      done: true,
    },
    {
      state: "Shipping",
      address: "23 Chicago street, Gwarinpa, Abuja",
      date: "17 Oct. 2023",
      done: false,
    },
    {
      state: "Out For Delivery",
      address: "23 Chicago street, Gwarinpa, Abuja",
      date: "17 Oct. 2023",
      done: false,
    },
    {
      state: "Delivered",
      address: "No 24, W.F. Kumuyi Street Kado, F.C.T, Abuja",
      date: "17 Oct. 2023",
      done: false,
    },
  ];

  return (
    <div className="manageCard-stateContainer">
      <div className="manageCard-selectCardTypeContainer">
        <p
          className={tab === "naira" ? "manageCard-activeCardType" : ""}
          onClick={handleCardSelection("naira")}
        >
          Naira Card
        </p>
        <p
          className={tab === "dollar" ? "manageCard-activeCardType" : ""}
          onClick={handleCardSelection("dollar")}
        >
          Dollar Card
        </p>
      </div>
      <div className="manageCard-deliveryStatusContainer">
        <div className={trackCompleted && "manageCard-deliveryStatus"}>
          {cardTracking.map((progress, i) => (
            <div key={i}>
              <div>
                {progress.done ? (
                  <img src={TrackComplete} alt="tracking" />
                ) : (
                  <img src={TrackPending} alt="tracking" />
                )}
                <div>
                  <p>{progress.state}</p>
                  <p>{progress.address}</p>
                </div>
              </div>
              <p>{progress.date}</p>
            </div>
          ))}
        </div>
        <button className={trackCompleted && "manageCard-activateCardBtn"}>
          Activate Card
        </button>
      </div>
    </div>
  );
};

export default TrackCard;
