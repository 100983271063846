import React from 'react';
import { AlertArea, ModalChild, CloseBtn, Center, SmallBtn } from './style';
import * as Icon from 'react-feather';

const Alert = ({ closeFunc, message, img, miniMessage, type, pdfFile }) => {
  return (
    <>
      <AlertArea>
        <ModalChild>
          <CloseBtn onClick={() => closeFunc()}>
            <Icon.X />
          </CloseBtn>
          <Center>
            <img src={img} alt="Icon" />
            <h3>{message}</h3>
            <p>{miniMessage}</p>
            {
              type === 'pdf' ?
                <>
                  <a
                    href={pdfFile}
                    download="Invoice Document"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <SmallBtn>
                      <button>Download PDF</button>
                    </SmallBtn>
                  </a>
                  <SmallBtn 
                      onClick={() => closeFunc()}>
                        
                      <button>Done</button>
                    </SmallBtn>
                  </>
              : <SmallBtn onClick={() => closeFunc()}>
              <button>Done</button>
            </SmallBtn>
            }
          </Center>
        </ModalChild>
      </AlertArea>
    </>
  );
};

export default Alert;
