import React, { useState } from "react";
import CardPin from "./CardPin";
import CardPinSuccess from "./CardPinSuccess";

const CardPinModal = ({ handleClose }) => {
  const [step, setStep] = useState(0);

  const handleForwardStep = () => {
    setStep(step + 1);
  };

  //   const handleBackwardStep = () => {
  //     setStep(step - 1);
  //   };

  const stepComponent = () => {
    switch (step) {
      case 0:
        return <CardPin handleClose={handleClose} next={handleForwardStep} />;
      case 1:
        return <CardPinSuccess handleClose={handleClose} />;
      //   case 2:
      //     return <Completed />;
      default:
        return null;
    }
  };

  return <>{stepComponent()}</>;
};

export default CardPinModal;
