import React, { useContext } from "react";
import { KycManager } from "../../../context-api/KycContext";
import Modal from "react-bootstrap/Modal";
import close from "../../../icons/close.svg";
import Dropdown from "../../../asset/icon/Dropdown.svg";
import Flag from "../../../asset/icon/Flag.svg";
import NigeriaFlag from "../../../asset/icon/NigeriaFlag.svg";
import "../../../styles/swapCurrencies.scss";

const SwapCurrenciesModal = (props) => {
  const { openSwapCurrencyModal, setOpenSwapCurrencyModal } =
    useContext(KycManager);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <div className="swapCurrencies-modalHeaderContainer">
          <p>Swap Currency</p>
          <div
            className="modal-header pointer"
            onClick={() => {
              setOpenSwapCurrencyModal(false);
            }}
          >
            <img draggable="false" src={close} alt="close" />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="modalContent">
        <div className="swapCurrency-formContainer">
          <p>
            Your default account balance will be credited for this transaction
          </p>
          <div className="input-area">
            <div className="label">
              <label htmlFor="phone">Dollar Amount</label>
            </div>
            <div className="input swapCurrencies-input">
              <input
                type="tel"
                id="phone"
                placeholder="0.00"
                //   value={user.walletTag}
                style={{ backgroundColor: "#ffffff" }}
              />
              <div>
                <div>
                  <img src={Flag} alt="flag" />
                </div>
                <div>
                  <img src={Dropdown} alt="dropdown" />
                </div>
              </div>
            </div>
          </div>

          <div className="swapCurrency-rateContainer">
            <p>Rate: $1.00 - #750.00</p>
            <p>Charge: #50.00</p>
            <p>Amount: #7,550.00</p>
          </div>

          <div className="input-area">
            <div className="label">
              <label htmlFor="naira">Debit Amount</label>
            </div>
            <div className="input swapCurrencies-input">
              <input
                type="text"
                id="naira"
                placeholder="0.00"
                //   value={user.walletTag}
                style={{ backgroundColor: "#ffffff" }}
              />
              <div>
                <div>
                  <img src={NigeriaFlag} alt="flag" />
                </div>
                <div>
                  <img src={Dropdown} alt="dropdown" />
                </div>
              </div>
            </div>
          </div>

          <div className="input-area">
            <div className="label">
              <label htmlFor="description">Payment Description</label>
            </div>
            <div className="input">
              <input
                type="tel"
                id="description"
                //   value={user.walletTag}
                style={{ backgroundColor: "#ffffff" }}
              />
            </div>
          </div>

          <button>Proceed</button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SwapCurrenciesModal;
