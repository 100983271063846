import { AiFillCheckCircle } from "react-icons/ai";
import "../../styles/dashboard.scss"; 
import { useContext, useEffect, useState } from "react"; 
import {
  variableManager,
} from "../../context-api/VariableContex";
export default function VerificationStatus() {
  const { user, nameCase } = useContext(variableManager); 
  const [completed, setCompleted] = useState(0);

  useEffect(() => {
    handleCalcCompleted();
  }, [user]);
  function handleCalcCompleted() {
    if (user && user.setupComplete) {
      setCompleted(1);
    }
    if (user && user.verificationBvn && user.idDoc !== 2) {
      setCompleted(2);
    }
    if (user && user.verificationBvn && user.idDoc === 2) {
      setCompleted(3);
    }
  }
  return (
    <div className="verification-status-container">
      <span>
        Welcome <b>{nameCase(user.businessName)}!</b>
      </span>

      <div className="finish-prompt">
        <h5>Finish Your Account Setup {completed}/3 </h5>
        <p>
          Complete your KYC and identity verification to enable you perform
          transactions on Mongoro
        </p>

        <div className="progress">
          <div className="tier ">
            <AiFillCheckCircle
              className={user && user.setupComplete ? "done" : "undone"}
            />{" "}
            <span className={user && user.setupComplete ? "done" : ""}>
              Finish Account Setup
            </span>
          </div>
          <div className="tier">
            <AiFillCheckCircle
              className={user && user.verificationBvn ? "done" : "undone"}
            />{" "}
            <span className={user && user.verificationBvn ? "done" : ""}>
              BVN Verification
            </span>
          </div>
          <div className="tier">
            <AiFillCheckCircle
              className={user && user.idDoc === 2 ? "done" : "undone"}
            />{" "}
            <span className={user && user.idDoc === 2 ? "done" : ""}>
              Identity Verification
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
