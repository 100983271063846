import "../../../styles/settings.scss";
import { useContext, useState } from "react";
import { variableManager } from "../../../context-api/VariableContex";
import { Spinner } from "react-bootstrap";
import axiosInstance from "../../../utils/axios_instance";

export default function NotificationSettings() {
  const { user, msg, setMsg, handleGetUser } = useContext(variableManager);

  const [notification, setNotification] = useState({
    // welcomeMessage: {
    //   notification: user && user.welcomeMessage?user.welcomeMessage.notification:"",
    //   email: user && user.welcomeMessage?user.welcomeMessage.email:"",
    // },
    transactionAlert: {
      sms: user ? user.transactionAlert.sms : "",
      notification: user ? user.transactionAlert.notification : "",
      email: user ? user.transactionAlert.email : "",
    },
    loginAlert: {
      notification: user ? user.loginAlert.notification : "",
      email: user ? user.loginAlert.email : "",
    },
  });

  function handleNotificationSetting() {
    setMsg({ ...msg, spinner: true });

    try {
      axiosInstance.put(`/business/edit`, notification).then((resp) => {
        setMsg({ ...msg, spinner: false });
        console.log(resp.data);

        if (resp.data.status) {
          setMsg({ ...msg, success: "Profile updated successfully" });
          handleGetUser();

          setTimeout(() => {
            setMsg({ ...msg, success: "" });
          }, 2000);
        }
      });
    } catch (error) {
      setMsg({ ...msg, spinner: false });

      console.log(error.response.data);
    }
  }

  const isAtLeastOneSelected =
    notification.transactionAlert.email || notification.transactionAlert.sms;

  return (
    <div className="settings-container">
      <div className="list-body">
        <div className="pt-4">
          <h5 className="mo-info">Notification</h5>

          <div className="biometrics">
            <div className="item">
              <div className="parameter">
                <b>Transaction Alerts</b>
                <p>Notifications on the status of your transactions</p>
              </div>

              <div className="notification-value">
                <div className="notify">
                  <input
                    type="checkbox"
                    checked={notification.transactionAlert.notification}
                    disabled
                    id="trans-notification"
                    onChange={(e) =>
                      setNotification({
                        ...notification,
                        transactionAlert: {
                          ...notification.transactionAlert,
                          notification: e.target.checked,
                        },
                      })
                    }
                  />
                  <label htmlFor="trans-notification">Notification</label>
                </div>

                <div className="via">
                  <input
                    type="checkbox"
                    id="trans-email"
                    checked={notification.transactionAlert.email}
                    onChange={(e) =>
                      setNotification({
                        ...notification,
                        id: user._id,
                        transactionAlert: {
                          ...notification.transactionAlert,
                          email: e.target.checked,
                        },
                      })
                    }
                  />
                  <label htmlFor="trans-email">Email</label>
                </div>

                <div className="via">
                  <input
                    type="checkbox"
                    id="trans-sms"
                    checked={notification.transactionAlert.sms}
                    onChange={(e) =>
                      setNotification({
                        ...notification,
                        transactionAlert: {
                          ...notification.transactionAlert,
                          sms: e.target.checked,
                        },
                      })
                    }
                  />
                  <label htmlFor="trans-sms">SMS</label>
                </div>
              </div>
            </div>

            <div className="item">
              <div className="parameter">
                <b>Login Alert</b>
                <p>Notifications on successful login to your account</p>
              </div>

              <div className="notification-value">
                <div className="notify">
                  <input
                    type="checkbox"
                    checked={notification.loginAlert.notification}
                    disabled
                    id="login-notification"
                    onChange={(e) =>
                      setNotification({
                        ...notification,
                        id: user._id,
                        loginAlert: {
                          ...notification.loginAlert,
                          notification: e.target.checked,
                        },
                      })
                    }
                  />
                  <label htmlFor="login-notification">Notification</label>
                </div>

                <div className="via">
                  <input
                    type="checkbox"
                    checked={notification.loginAlert.email}
                    id="login-email"
                    onChange={(e) =>
                      setNotification({
                        ...notification,
                        id: user._id,
                        loginAlert: {
                          ...notification.loginAlert,
                          email: e.target.checked,
                        },
                      })
                    }
                  />
                  <label htmlFor="login-email">Email</label>
                </div>
              </div>
            </div>

            <div className="button">
              <button
                disabled={!isAtLeastOneSelected || msg.spinner}
                className={`${
                  !isAtLeastOneSelected && "btn-disabled"
                } mo-active-btn`}
                onClick={() => handleNotificationSetting()}
              >
                {!msg.spinner ? "Save Changes" : <Spinner size="sm" />}
              </button>
              {!isAtLeastOneSelected && (
                <p className="error">
                  At least one option for the transaction alert should be
                  selected
                </p>
              )}
            </div>

            {msg.success && (
              <p className="success text-center mt-2">{msg.success}</p>
            )}
          </div>
        </div>
      </div>

      <div className="modals"></div>
    </div>
  );
}
