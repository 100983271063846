import { useContext, useState } from "react";
import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "../../../../styles/modals.scss";
import "../../../../styles/bills.scss";
import logo from "../../../../icons/Vector.png";
import card from "../../../../icons/card_logo.svg";
import done from "../../../../icons/done.svg";
import { variableManager } from "../../../../context-api/VariableContex";
import { KycManager } from "../../../../context-api/KycContext";
export default function PaymentMethod(props) {
  const { user, msg } = useContext(variableManager);
  const { handleBillStatusChange, billPayload} = useContext(KycManager);
  const [error, setError] = useState("")

  const [selectedMethod, setSelectedMethod] = useState({
    wallet: false,
    card: false,
  });


  function handleValidation(){
    if(selectedMethod.wallet || selectedMethod.card){
        handleBillStatusChange("global", "enterPin")
    }else{
        setError("Please Select payment method")
    }
  } 
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton
      onHide={()=>{
        setSelectedMethod({
          ...selectedMethod,
          wallet: false,
          card: false,
        });
      }}
      ></Modal.Header>
      <Modal.Body className="modalContent">
        <center>
          {" "}
          <h2>₦{billPayload.airtimeData.amount}</h2>
        </center>

        <div className="request-pos-container payment-method-container">
          <div className="bill-summary">
            <div className="item">
              <p className="key">Amount</p>
              <b className="value">₦{billPayload.airtimeData.amount}</b>
            </div>

            <div className="item">
              <p className="key">Product Name</p>
              <b className="value">{billPayload.airtimeData.type}</b>
            </div>
          </div>

          <div
            className={
              selectedMethod.wallet ? "active-method pointer" : "method pointer"
            }
            onClick={() => {
              setSelectedMethod({
                ...selectedMethod,
                wallet: true,
                card: false,
              });
              setError("")
            }}
          >
          {selectedMethod.wallet && <p className="heading">PAYMENT METHOD</p>}
            <div className="content">
              <div className="icon">
                <img src={logo} alt="pay with mogoro wallet" />
              </div>

              <div className="details-status-section">
                <div className="details">
                  <b>Mongoro Wallet</b>
                  {/* <p>Wallet: {user && user.Accounts[0] ? user.Accounts[0].accountNumber:""}</p> */}
                </div>

                {selectedMethod.wallet && (
                  <div className="status">
                    <div className="icon">
                      <img src={done} alt="selected method" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div
            className={
              selectedMethod.card ? "active-method pointer" : "method pointer"
            }
            onClick={() => {
              setSelectedMethod({
                ...selectedMethod,
                wallet: false,
                card: true,
              });
              setError("")
            }}
          >
            {selectedMethod.card && <p className="heading">PAYMENT METHOD</p>}

            <div className="content">
              <div className="icon">
                <img src={card} alt="pay with card" />
              </div>

              <div className="details-status-section">
                <div className="details">
                  <b>Pay with Card</b>
                </div>

                {selectedMethod.card && (
                  <div className="status">
                    <div className="icon">
                      <img src={done} alt="selected method" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
{
    error && (<p className="mo-danger">{error}</p>)
}
        <div className="buttons">
          <button
            className={"btn-active"}
            onClick={() => {
                handleValidation()
            }}
            disabled={msg.spinner ? "{true}" : ""}
          >
            {msg.spinner ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Confirm"
            )}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
