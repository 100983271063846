import "../../../styles/settings.scss";
import { useContext, useState, useEffect } from "react";
import { variableManager } from "../../../context-api/VariableContex";
import axiosInstance from "../../../utils/axios_instance";
import CreateSub from "./createModal";
import { DateWrap, Role, TableFlex, TableName, TableWrap } from "./style";
import EmptyState from "../emptyState";
import { StatusCard } from "../../../styles/all";
import { useNavigate } from "react-router-dom";
import SubAccountRequest from "../../notification/SubAccountRequest";

export default function SubAccountsIndexComponent() {
  const navigate = useNavigate();
  const { nameCase, user } = useContext(variableManager);
  const [accounts, setAccounts] = useState([]);
  const [showCreate, setShowCreate] = useState(false);
    const [showSubAccountRequestAlert, setShowSubAccountRequestAlert] =
      useState(false);


  function handleTruncateHistory(arr) {
    const short = [];
    for (let i = 0; i <= 4; i++) {
      if (short.includes(arr[i])) {
        return;
      } else {
        if (arr[i]) {
          short.push(arr[i]);
        }
      }
    }
    return short.length;
  }

  const getSubAccounts = () => {
    console.log("getting sub accounts");
    try {
      axiosInstance.get(`/business/sub/business`).then((resp) => {
        // setAccounts([resp.data.data]);
        setAccounts( resp.data.data);
      });
    } catch (error) {
      // setMsg({ ...msg, error: error.response.data.message });
    }
  };
console.log(user)
  useEffect(() => {
    getSubAccounts();
  }, []);


  useEffect(() => {
    if (user.subAccountRequest === 0) {
      setShowSubAccountRequestAlert(true);
    }
  }, [user]);


  return (
    <>
      <div className="settings-container">
        <h5>
          <b>Sub Accounts</b>
        </h5>
        <div className="mpos-section d-flex justify-content-end ">
          <button
            className="mo-active-btn w-1 mt-2"
            style={{
              width: "15rem",
            }}
            onClick={() => setShowCreate(true)}
          >
            Add Sub-Account
          </button>
        </div>
        <div
          className="transaction-history-container"
          style={{
            margin: "2rem 0",
          }}
        >
          <div
            className="table-section"
            style={{
              margin: "0rem 0",
              padding: "0",
            }}
          >
            <TableWrap>
              <TableFlex>
                <DateWrap>
                  <h3>I.D</h3>
                </DateWrap>
                <TableName>
                  <h3>Bank Name</h3>
                </TableName>
                <DateWrap>
                  <h3>Account Number</h3>
                </DateWrap>
                <DateWrap>
                  <h3>Account Number</h3>
                </DateWrap>
                <DateWrap>
                  <h3>Status</h3>
                </DateWrap>
              </TableFlex>
              {accounts.length > 0 ? (
                accounts.map((e, i) => {
                  if (i <= 4) {
                    return (
                      <TableFlex
                        key={i}
                        usecursor="true"
                        onClick={() =>
                          navigate(
                            `/user/dashboard/sub-account/${e.said}?bId=${
                              e.inviteeID
                            }&bn=${e.subAccountName.replace(
                              new RegExp(" ", "g"),
                              "_"
                            )}`
                          )
                        }
                      >
                        <DateWrap>
                          <p>{e.accountID}</p>
                        </DateWrap>
                        <TableName>
                          <h3>{e.subAccountName ? e.subAccountName : ""}</h3>
                          <p>{e.bankName}</p>
                        </TableName>
                        <DateWrap>
                          <p>{e.accountNumber ? e.accountNumber : ""}</p>
                        </DateWrap>
                        <DateWrap>
                          <p>{e.currency}</p>
                        </DateWrap>
                        <DateWrap>
                          {" "}
                          <StatusCard
                          // bg={
                          //   e.status?.toLowerCase() === "active"
                          //     ? "#d4fac5"
                          //     : "#fff"
                          // }
                          // color={
                          //   e.status && e.status.toLowerCase() === "active"
                          //     ? "green"
                          //     : e.status.toLowerCase() === "rejected"
                          //     ? "#c82b32"
                          //     : "black"
                          // }
                          >
                            {/* {e.diasbled ? 'Disabled' : 'Active'} */}
                            {e.status}
                          </StatusCard>
                        </DateWrap>
                      </TableFlex>
                    );
                  }
                })
              ) : (
                <EmptyState />
              )}
            </TableWrap>
          </div>
          <div className="add-container ">
            <div className="add-new">
              {/* <img src={add} alt="add new" /> */}
            </div>
          </div>
          {accounts.length ? (
            <p className="showing">
              {" "}
              Showing{" "}
              <span className="shown">
                {handleTruncateHistory(accounts)}
              </span>{" "}
              of <span className="total">{accounts.length}</span> enteries
            </p>
          ) : null}
        </div>

        {showCreate ? (
          <div className="modals">
            <CreateSub
              closeFunc={() => setShowCreate(false)}
              action={getSubAccounts}
            />
          </div>
        ) : null}

        {/* <SubAccountRequest
          show={showSubAccountRequestAlert}
          onHide={() => setShowSubAccountRequestAlert(false)}
        /> */}
      </div>
    </>
  );
}
