import { useContext, useEffect, useState } from "react";
import "../../styles/createAccount.scss";
import { Link, useNavigate } from "react-router-dom"; 
import Spinner from "react-bootstrap/Spinner";
import { variableManager } from "../../context-api/VariableContex";
import eye from "../../icons/eye.svg";
import no_eye from "../../icons/no_eye.svg"; 

export default function LoginForm() {
  const [empty, setEmpty] = useState(false); 
  const {
    url,
    isLoggedIn,
    handleLogin,
    loginBody,
    setLoginBody,
    msg,
    setMsg,
    showPassword,
    setShowPassword,
  } = useContext(variableManager);

  const navigate = useNavigate(); 

useEffect(()=>{
  if(isLoggedIn){
    return navigate("/user/dashboard") 
  }
},[])


  return (
    <div className="login-form-container create-account-container">
      <div className="form-area">
        <Link className="text-dark" to={"/signup"}>
          {" "}
          <span className="signup-redirect-link">
            New user? <b className="pointer"> &nbsp; Create an account</b>
          </span>
        </Link>
        <div className="content">
          <h2>Welcome back!</h2>
          <p className="mantra">
            We make it easy for everyone to maximise their business profit
          </p>

          {msg.error && (
            <div className="error "> 
              {msg.error}
            </div>
          )}
          {msg.success && <div className="success ">{msg.success}</div>}

          <div className="input-area">
            <div className="label">
              <label htmlFor="email">Email Address</label>
            </div>
            <div className="input">
              <input
                type="text"
                id="email"
                placeholder="user@gmail.com"
                onChange={(e) => {
                  setLoginBody({ ...loginBody, email: e.target.value });
                }}
                value={loginBody.email}
              />
            </div>
            {empty && loginBody.email === "" ? (
              <p className="mo-danger">Email is required</p>
            ) : null}
          </div>

          <div className="input-area">
            <div className="label">
              <label htmlFor="password">Password</label>
            </div>
            <div className="input">
              <input
                type={showPassword.password ? "text" : "password"}
                id="password"
                placeholder="***************"
                onChange={(e) => {
                  setLoginBody({ ...loginBody, password: e.target.value });
                }}
                value={loginBody.password}
              />{" "}
              
             {
              showPassword.password?(
                <img
                draggable="false"
                src={no_eye}
                className=" eye pointer"
                onClick={() =>
                  setShowPassword({
                    ...showPassword,
                    password: !showPassword.password,
                  })
                }
                alt="view password"
              />
              ):(
                <img
                draggable="false"
                src={eye}
                className=" eye pointer"
                onClick={() =>
                  setShowPassword({
                    ...showPassword,
                    password: !showPassword.password,
                  })
                }
                alt="view password"
              />
              )
             }
            </div>

            {empty && loginBody.password === "" ? (
              <p className="mo-danger">Password is required to login</p>
            ) : null}
          </div>
          <div className="forgot-password">
            <div className="checkbox-area">
              <div className="checkbox">
                <input className="" type="checkbox" id="remember-me" />
              </div>
              <label htmlFor="remember-me">Remember me</label>
            </div>
            <Link className="text-dark" to={"/forgot-password"}>
              {" "}
              <p className="pointer">Forgot password?</p>
            </Link>
          </div>
          <button
            onClick={() => {
              if (loginBody.email && loginBody.password) {
                handleLogin();
              } else {
                setEmpty(true);
              }
            }}
            
            disabled={msg.spinner?"{true}":""}
          >
            {msg.spinner ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Login"
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
