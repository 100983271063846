// import "../../../styles/settings.scss";
// import { BsPlusLg } from "react-icons/bs";
// import { HiOutlineTrash } from "react-icons/hi";
import { useContext, useEffect, useState } from "react";
import { variableManager } from "../../../context-api/VariableContex";
import { KycManager } from "../../../context-api/KycContext";
import axios from "axios";
import { Spinner, Toast } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { validUsername } from "../../regex/Regex";
import axiosInstance from "../../../utils/axios_instance";

export default function ProfileSettings() {
  const {
    msg,
    setMsg,
    url,
    user,
    // setUser,
    // bankList,
    // userBank,
    handleGetUserBanks,
    name,
    nameCase,
    // image,
    // handleImageChange,
    handleGetUser,
  } = useContext(variableManager);
  const { profile, setProfile, setModalShowCompletesetup } =
    useContext(KycManager);
  const [empty, setEmpty] = useState(false);

  // const [userBank, setUserbank] = useState([]);
  const [bank, setBank] = useState({
    add: false,
    show: true,
    details: {
      real_bank_accountNumber: "",
      real_bankName: "",
      real_bank_code: "",
      userId: "",
    },
    card: {
      add: false,
      show: true,
      details: { cardNumber: "", expiryDate: "", cvv: "" },
    },
  });

  const [changes, setChanges] = useState({
    address: "",
    walletTag: "",
  });

  const [error, setError] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // handleGetUserBanks(user._id)
  }, []);

  async function handleAddBank() {
    setMsg({ ...msg, spinner: true });
    try {
      await axios.post(`${url}/bank`, bank.details).then((resp) => {
        setMsg({ ...msg, spinner: false });

        setBank({ ...bank, show: true, add: false });
        setProfile({ ...profile, bankAddSuccess: true });
        handleGetUserBanks(user._id);
      });
    } catch (error) {
      setMsg({ ...msg, spinner: false, error: error.response.data.message });

      setTimeout(() => {
        setMsg({ ...msg, error: "" });
      }, 1000);
    }
  }

  // console.log(validUsername.test(changes.walletTag));

  function handleCardValidation() {
    if (
      bank.card.details.cardNumber === "" ||
      bank.card.details.expiryDate === "" ||
      <bank className="card details cvv"></bank> === ""
    ) {
      setEmpty(true);
    } else {
      setEmpty(false);
      // handleAddCard();
      alert("all fields filled");
    }
  }
  function handleValidation() {
    if (
      bank.details.real_bank_accountNumber === "" ||
      bank.details.real_bankName === ""
    ) {
      setEmpty(true);
    } else {
      setEmpty(false);
      handleAddBank();
    }
  }
  async function handleSettings(payload, type) {
    if (
      type === "username" &&
      !validUsername.test(changes.walletTag.substr(1))
    ) {
      return setError(
        "Username cannot be less than 4 digits or more than 8 digits"
      );
    }
    setMsg({ ...msg, spinner: true });
    try {
      await axiosInstance.put(`/business/edit`, payload).then((resp) => {
        setChanges({ ...changes, walletTag: "" });
        handleGetUser();
        setMsg({
          ...msg,
          spinner: false,
          toast: true,
          toastBody: "Profile Updated!",
        });
      });
    } catch (error) {
      console.log(error);
      if (type === "username") {
        setChanges({
          ...changes,
          walletTag: user.walletTag,
        });
      } else {
        setChanges({
          ...changes,
          address: user.address,
        });
      }
      setMsg({
        ...msg,
        spinner: false,
        toast: true,
        toastBody: error.response
          ? error.response.data.message
          : "Can't Update Profile at the Moment",
      });
    }
  }

  //  console.log(changes)

  // function handleGetUserBanks(id) {
  //   try {
  //     axios.get(`${url}/bank/user/${id}`).then((resp) => {
  //       setUserbank(resp.data.bank);
  //     });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  // console.log(bankList)

  // console.log(userBank)
  // console.log(userBank.bank)

  function getBusinessAddress(address, city, country) {
    if (!address || !city || !country) return;

    const businessAddress = `${nameCase(address)}, ${nameCase(
      city
    )}, ${nameCase(country)}.`;

    return businessAddress;
  }
 

  return (
    <div className="settings-container">
      <div className="list-body">
        <div className="pt-4">
          <h5 className="mo-info">Personalize</h5>

          <div className="user-profile">
            <div className="image-section">
              {user.image ? (
                <div className="shown-img">
                  <label
                    htmlFor="profile-im"
                    onClick={() =>
                      setProfile({ ...profile, imageUpload: true })
                    }
                  >
                    <img src={user.image} alt="" />
                    <input
                      id="profile-img"
                      type="file"
                      max={1}
                      // onChange={(e) => handleImageChange(e)}
                    />
                  </label>
                </div>
              ) : (
                // :  image.toDisplay?(
                //   <div className="shown-img">
                //   <label htmlFor="profile-img">
                //   <img src={image.toDisplay} alt="" />
                //     <input id="profile-img" type="file" max={1} onChange={(e)=>handleImageChange(e)} />
                //   </label>
                // </div>
                // )
                <div className="image">
                  <label
                    className="pointer"
                    htmlFor="profile-img"
                    onClick={() =>
                      setProfile({ ...profile, imageUpload: true })
                    }
                  >
                    {/* <input id="profile-img" type="file" max={1} onChange={(e)=>handleImageChange(e)} /> */}
                  </label>
                </div>
              )}

              <label
                htmlFor="profile-im"
                className="mo-blue pointer"
                onClick={() => setProfile({ ...profile, imageUpload: true })}
              >
                Upload profile image
              </label>
            </div>

            <div className="name">
              <h5>
                {nameCase(user.firstName)}{" "}
                {nameCase(user.middleName) ? nameCase(user.middleName) : null}{" "}
                {nameCase(user.surname)}
              </h5>
            </div>
          </div>

          <div className="biometrics">
            <div className="item">
              <div className="parameter">
                <b>Username:</b>
                <p>Username to Login to the app</p>
              </div>

              <div className="value">
                <div className="input-area">
                  <div className="label">
                    <label htmlFor="username">Username</label>
                  </div>
                  <div className="input">
                    {!changes.walletTag && (
                      <input
                        type="text"
                        id="username"
                        value={user.walletTag ? user.walletTag : ""}
                        onChange={(e) =>
                          setChanges({
                            ...changes,
                            walletTag: e.target.value,
                            id: user._id,
                          })
                        }
                        onFocus={() =>
                          setChanges({
                            ...changes,
                            walletTag: user.walletTag,
                            id: user._id,
                          })
                        }
                        placeholder="@username"
                      />
                    )}
                    {changes.walletTag && (
                      <input
                        autoFocus
                        type="text"
                        id="username"
                        onChange={(e) => {
                          setError("");

                          let value = e.target.value;

                          if (value.charAt(0) === "@") {
                            value = value;
                          } else {
                            value = "@" + value;
                          }

                          setChanges({
                            ...changes,
                            walletTag: value,
                          });
                        }}
                        onKeyDown={(e) => {
                          if (e.keyCode === 32) {
                            e.preventDefault();
                          }
                        }}
                        value={
                          changes && changes.walletTag.charAt(0) === "@"
                            ? changes.walletTag.substr(1)
                            : changes.walletTag
                        }
                        placeholder="@username"
                      />
                    )}
                    {/* {console.log(user)} */}
                  </div>
                </div>
              </div>
            </div>

            <div className="item">
              <div className="parameter">
                <b>Date of Birth</b>
              </div>

              <div className="value">
                {/* <p className="mo-blue ">01/01/1990</p> */}
                <p className="mo-blue ">
                  {user.dateOfBirth
                    ? new Date(user.dateOfBirth).toLocaleDateString()
                    : ""}
                </p>
              </div>
            </div>

            <div className="item">
              <div className="parameter">
                <b>Gender</b>
              </div>

              <div className="value">
                <p className="mo-blue ">
                  {user.gender
                    ? user.gender.charAt(0).toUpperCase() +
                      user.gender.substr(1)
                    : null}
                </p>
              </div>
            </div>

            <div className="item">
              <div className="parameter">
                <b>Email Address</b>
              </div>

              <div className="value">
                <p className="mo-blue ">{user.email}</p>
              </div>
            </div>

            <div className="item">
              <div className="parameter">
                <b>Phone Number</b>
              </div>

              <div className="value">
                <p className="mo-blue ">{user.phone}</p>
              </div>
            </div>

            <div className="item">
              <div className="parameter">
                <b>Address:</b>
                <p>Business Address</p>
              </div>

              <div className="value">
                <div className="input-area">
                  <div className="label">
                    <label htmlFor="password">Address</label>
                  </div>
                  <div className="input">
                    {/* <input
                      type="text"
                      id="password"
                      disabled
                      placeholder="No info"
                      className="address-display-input"
                      value={
                        user.businessAddress
                          ? getBusinessAddress(
                              user.businessAddress,
                              user.city,
                              user.country
                            )
                          : ""
                      }
                      onChange={(e) =>
                        setChanges({
                          ...changes,
                          address: e.target.value,
                        })
                      }
                      onFocus={() =>
                        setChanges({
                          ...changes,
                          address: user.address,
                        })
                      }
                    /> */}

                    {!changes.address && (
                      <textarea
                        name=""
                        id=""
                        rows={"auto"}
                        cols={1}
                        disabled 
                        placeholder="No info, please complete your account setup to provide your address" 
                        onChange={(e) =>
                          setChanges({
                            ...changes,
                            address: e.target.value,
                          })
                        }
                        onFocus={() =>
                          setChanges({
                            ...changes,
                            address: user.address,
                          })
                        }
                        value={
                          user.businessAddress
                            ? getBusinessAddress(
                                user.businessAddress,
                                user.city,
                                user.country
                              )
                            : ""
                        }
                      ></textarea>
                    )}
                    {changes.address && (
                      <input
                        type="text"
                        id="password"
                        autoFocus
                        placeholder="No 1, VOO Crescent ..."
                        onChange={(e) =>
                          setChanges({
                            ...changes,
                            address: e.target.value,
                          })
                        }
                        value={changes.address}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="item">
              <div className="parameter">
                <b>Account Upgrade</b>
              </div>

              <div
                className="value"
                onClick={() => navigate("/user/dashboard/upgrade")}
              >
                <p className="mo-info pointer ">Proceed</p>
              </div>
            </div>
          </div>

          {/* USERNAME VALIDATION ERROR */}
          {error && <p className="error">{error}</p>}

          {user.address &&
          changes.address != "" &&
          changes.address.length !== user.address.length ? (
            <div className=" w-75 d-flex justify-content-end">
              <button
                onClick={() => handleSettings({ address: changes.address })}
                className={msg.spinner ? "mo-inactive-btn" : "mo-active-btn"}
                style={{ width: "200px" }}
                disabled={msg.spinner ? "{true}" : ""}
              >
                {msg.spinner ? (
                  <Spinner size="sm" variant="warning" />
                ) : (
                  "Save Changes"
                )}
              </button>
            </div>
          ) : user.walletTag &&
            changes.walletTag != "" &&
            changes.walletTag.toLowerCase() !== user.walletTag.toLowerCase() ? (
            <div className=" w-75 d-flex justify-content-end">
              <button
                onClick={(e) =>
                  handleSettings({ walletTag: changes.walletTag }, "username")
                }
                className={msg.spinner ? "mo-inactive-btn" : "mo-active-btn"}
                style={{ width: "200px" }}
                disabled={msg.spinner ? "{true}" : ""}
              >
                {msg.spinner ? (
                  <Spinner size="sm" variant="warning" />
                ) : (
                  "Save Changes"
                )}
              </button>
            </div>
          ) : ((changes.walletTag &&
              changes.walletTag.toLowerCase() !==
                user.walletTag.toLowerCase()) ||
              (changes.address &&
                changes.address.toLowerCase() !==
                  user.address.toLowerCase())) &&
            (!user.address || !user.dateOfBirth || !user.gender) ? (
            <div className="d-flex justify-content-end">
              <button
                onClick={() => {
                  setModalShowCompletesetup(true);
                  navigate("/user/dashboard");
                }}
                className={msg.spinner ? "mo-inactive-btn" : "mo-active-btn"}
                style={{ width: "200px" }}
                disabled={msg.spinner ? "{true}" : ""}
              >
                Update Profile
              </button>
            </div>
          ) : null}

          {/* ADD DEBIT CARD */}
          {/* <h5 className="mo-info">Add Card Details</h5>
          {bank.card.show && (
            <div className="bank-details">
              <div className="cards">
                <div
                  className="add-bank mo-info  pointer"
                  onClick={() => {
                    setBank({
                      ...bank,
                      card: { ...bank.card, add: true, show: false },
                    });
                    setMsg({ ...msg, error: "" });
                  }}
                >
                  <BsPlusLg className="mo-info icon" />
                  <p>Add card details</p>
                </div>

                {userBank.length
                  ? userBank.map((bank) => {
                      return (
                        <div className="added-bank" key={bank._id}>
                          <div
                            className="delete-bank pointer"
                            onClick={() =>
                              setProfile({
                                ...profile,
                                deletePrompt: {
                                  ...profile.deletePrompt,
                                  bank: true,
                                  bankId: bank._id,
                                },
                              })
                            }
                          >
                            <p className="mo-danger">
                              <HiOutlineTrash className="mo-danger" /> Delete
                              Card
                            </p>
                          </div>
                          <div className="holder">
                            <h5>{bank.accountName}</h5>
                            <p>{bank.real_bank_accountNumber}</p>

                            <p className="bank-name">{bank.real_bankName}</p>
                          </div>
                        </div>
                      );
                    })
                  : ""}
              </div>
            </div>
          )} */}

          {/*TO ADD DEBIT CARD  */}

          {bank.card.add && (
            <div className="add-bank-form-section">
              <div className="text">
                <h5>Add card details:</h5>
                <p>You can fund your mongoro wallet with the card details</p>
              </div>

              <div className="form">
                <div className="input-area">
                  <div className="label">
                    <label htmlFor="card_number">Card Number</label>
                  </div>
                  <div className="input">
                    <input
                      type="text"
                      id="card_number"
                      placeholder="Enter card number"
                      onChange={(e) => {
                        setBank({
                          ...bank,
                          card: {
                            ...bank.card,
                            details: {
                              ...bank.card.details,
                              cardNumber: e.target.value,
                            },
                          },
                        });
                        setMsg({ ...msg, error: "" });
                      }}
                    />
                  </div>
                  {empty && bank.card.details.cardNumber === "" ? (
                    <div className="mo-danger">Card number is required</div>
                  ) : null}
                </div>

                <div className="input-area">
                  <div className="label">
                    <label htmlFor="expiry">Expiry Date</label>
                  </div>
                  <div className="input">
                    <input
                      type="month"
                      id="expiry"
                      onChange={(e) => {
                        setBank({
                          ...bank,
                          card: {
                            ...bank.card,
                            details: {
                              ...bank.card.details,
                              expiryDate: e.target.value,
                            },
                          },
                        });
                        setMsg({ ...msg, error: "" });
                      }}
                    />
                  </div>
                  {empty && bank.card.details.expiryDate === "" ? (
                    <div className="mo-danger">Expiry date is required</div>
                  ) : null}
                </div>

                <div className="input-area">
                  <div className="label">
                    <label htmlFor="cvv">CVV</label>
                  </div>
                  <div className="input">
                    <input
                      type="password"
                      placeholder="XXX"
                      id="cvv"
                      maxLength={3}
                      onChange={(e) => {
                        setBank({
                          ...bank,
                          card: {
                            ...bank.card,
                            details: {
                              ...bank.card.details,
                              cvv: e.target.value,
                            },
                          },
                        });
                        setMsg({ ...msg, error: "" });
                      }}
                    />
                  </div>
                  {empty && bank.card.details.cvv === "" ? (
                    <div className="mo-danger">CVV is required</div>
                  ) : null}

                  <div className="button d-flex justify-content-end mt-3">
                    <button
                      className="mo-active-btn w-25 "
                      onClick={() => handleCardValidation()}
                    >
                      {msg.spinner ? <Spinner size="sm" /> : "Add Card"}
                    </button>
                  </div>
                </div>

                {/* <div className="input-area">
                  <div className="label">
                    <label htmlFor="password">Select Bank</label>
                  </div>
                  <div className="input">
                    <select
                      name="gender"
                      id=""
                      onChange={(e) => {
                        setBank({
                          ...bank,
                          details: {
                            ...bank.details,
                            real_bankName: e.target.value.split(",")[0],
                            real_bank_code: e.target.value.split(",")[1],
                            userId: user._id,
                          },
                        });

                        setMsg({ ...msg, error: "" });
                      }}
                    >
                      <option value="">Select from the Dropdown</option>
                      {bankList.length ? (
                        bankList.map((e) => {
                          return (
                            <option value={[e.name, e.code]} key={e.code}>
                              {e.name}
                            </option>
                          );
                        })
                      ) : (
                        <option value="load">Loading list ...</option>
                      )}
                    </select>
                    {empty && bank.details.real_bankName === "" ? (
                      <div className="mo-danger">Select bank name to add</div>
                    ) : null}

                    <div className="button d-flex justify-content-end mt-3">
                      <button
                        className="mo-active-btn w-25 "
                        onClick={() => handleValidation()}
                      >
                        {msg.spinner ? <Spinner size="sm" /> : "Add bank"}
                      </button>
                    </div>
                  </div>

                  {msg.error && <p className="error">{msg.error}</p>}
                </div> */}
              </div>
            </div>
          )}

          {/* BANK ADDINTION CONTROLLER */}

          {/* 

<h5 className="mo-info">Bank details</h5>
          {bank.show && (
            <div className="bank-details">
              <div className="cards">
                <div
                  className="add-bank mo-info  pointer"
                  onClick={() => {
                    setBank({ ...bank, show: false, add: true });
                    setMsg({ ...msg, error: "" });
                  }}
                >
                  <BsPlusLg className="mo-info icon" />
                  <p>Add bank Account</p>
                </div>

                {userBank.length
                  ? userBank.map((bank) => {
                      return (
                        <div className="added-bank" key={bank._id}>
                          <div
                            className="delete-bank pointer"
                            onClick={() =>
                              setProfile({
                                ...profile,
                                deletePrompt: {
                                  ...profile.deletePrompt,
                                  bank: true,
                                  bankId: bank._id,
                                },
                              })
                            }
                          >
                            <p className="mo-danger">
                              <HiOutlineTrash className="mo-danger" /> Delete
                              Bank
                            </p>
                          </div>
                          <div className="holder">
                            <h5>{bank.accountName}</h5>
                            <p>{bank.real_bank_accountNumber}</p>

                            <p className="bank-name">{bank.real_bankName}</p>
                          </div>
                        </div>
                      );
                    })
                  : ""}
              </div>
            </div>
          )} */}

          {/* TO ADD BANK ACCOUNT */}
          {/* {bank.add && (
            <div className="add-bank-form-section">
              <div className="text">
                <h5>Add Bank Account:</h5>
                <p>Withdrawals are paid into your bank account</p>
              </div>

              <div className="form">
                <div className="input-area">
                  <div className="label">
                    <label htmlFor="password">Account Number</label>
                  </div>
                  <div className="input">
                    <input
                      type="number"
                      onChange={(e) => {
                        setBank({
                          ...bank,
                          details: {
                            ...bank.details,
                            real_bank_accountNumber: e.target.value,
                            userId: user._id,
                          },
                        });
                        setMsg({ ...msg, error: "" });
                      }}
                    />
                  </div>
                  {empty && bank.details.real_bank_accountNumber === "" ? (
                    <div className="mo-danger">Enter account number to add</div>
                  ) : null}
                </div>

                <div className="input-area">
                  <div className="label">
                    <label htmlFor="password">Select Bank</label>
                  </div>
                  <div className="input">
                    <select
                      name="gender"
                      id=""
                      onChange={(e) => {
                        setBank({
                          ...bank,
                          details: {
                            ...bank.details,
                            real_bankName: e.target.value.split(",")[0],
                            real_bank_code: e.target.value.split(",")[1],
                            userId: user._id,
                          },
                        });

                        setMsg({ ...msg, error: "" });
                      }}
                    >
                      <option value="">Select from the Dropdown</option>
                      {bankList.length ? (
                        bankList.map((e) => {
                          return (
                            <option value={[e.name, e.code]} key={e.code}>
                              {e.name}
                            </option>
                          );
                        })
                      ) : (
                        <option value="load">Loading list ...</option>
                      )}
                    </select>
                    {empty && bank.details.real_bankName === "" ? (
                      <div className="mo-danger">Select bank name to add</div>
                    ) : null}

                    <div className="button d-flex justify-content-end mt-3">
                      <button
                        className="mo-active-btn w-25 "
                        onClick={() => handleValidation()}
                      >
                        {msg.spinner ? <Spinner size="sm" /> : "Add bank"}
                      </button>
                    </div>
                  </div>

                  {msg.error && <p className="error">{msg.error}</p>}
                </div>
              </div>
            </div>
          )} */}
        </div>
      </div>

      <div className="Modals and Toasts">
        <Toast
          onClose={() => setMsg({ ...msg, toast: false, toastBody: "" })}
          show={msg.toast}
          delay={6000}
          autohide
          className="general-toast"
        >
          <Toast.Header className="d-flex justify-content-end w-100"></Toast.Header>

          <Toast.Body>{msg.toastBody}</Toast.Body>
        </Toast>
      </div>
    </div>
  );
}
