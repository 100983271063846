import axios from "axios";
import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../../../styles/modals.scss";
import { ModalChild, ModalWrap } from "../../../styles/all";

export default function EnterPin({props, closeFunc, action, handleTransfer}) {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [verify, setVerify] = useState(new Array(6).fill(""));
  const [error, setError] = useState("");

  const handleChange = (element, index, toMap) => {
    if (isNaN(element.value)) return false;

    if (toMap === "email") {
      setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    } else if (toMap === "verify") {
      setVerify([
        ...verify.map((d, idx) => (idx === index ? element.value : d)),
      ]);
    }
    //Focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  useEffect(() => {
    action(otp.join().replace(new RegExp(',', 'g'), ''));
  }, [otp])
 
  return (
    <ModalWrap>
        <ModalChild
            width='500px'
            height='40%'
        >
            <Modal.Header closeButton
                onHide={()=>{
                    setOtp(new Array(6).fill(""));
                    closeFunc();
            }}
            ></Modal.Header>
            <Modal.Body className="modalContent">
                <h2>Enter your PIN to </h2>

                <div className="">
                <div className="content verify-account-form-container w-100 pin-container">
                    <div className="email-sms-otp">
                    <div className="email-otp">
                        {/* <p className="mt-3">Create PIN</p> */}
                        <div className="otp-box mb-4">
                        {otp.map((data, index) => {
                            return (
                            <input
                                className="otp-field"
                                type="password"
                                name="otp"
                                maxLength="1"
                                key={index}
                                value={data}
                                onChange={(e) => {
                                    handleChange(e.target, index, "email");
                                }}
                                onFocus={(e) => e.target.select()}
                                onKeyDown={(e) => {
                                if (e.keyCode === 8) {
                                    // handleDelete(e, index)
                                } else if (e.key === "ArrowLeft") {
                                    e.target.previousSibling.focus();
                                } else if (e.key === "ArrowRight") {
                                    e.target.nextSibling.focus();
                                }
                                }}
                            />
                            );
                        })}
                        </div>
                    </div>
                    </div>
                    {otp.join("").length === 6 ? (
                    <button
                        className="mo-active-btn"
                        onClick={(e) => {
                            handleTransfer(e);
                        }}
                    >
                        Continue
                    </button>
                    ) : (
                    <button className="mo-inactive-btn">Continue</button>
                    )}
                </div>
                </div>
            </Modal.Body>
      </ModalChild>
    </ModalWrap>
  );
}
