import "../../../styles/settings.scss";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import { ClipLoader } from "react-spinners";
import Alert from "../../../reusable/alert";
import { useSearchParams } from "react-router-dom";
import { variableManager } from "../../../context-api/VariableContex";

export default function SubAccountsInviteComponent() {

    const loggedUser = localStorage.getItem('mg-u-tkn') || '{}';
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const BN = searchParams.get('bn')
    const navigate = useNavigate();
    const [onProcess, setOnProcess] = useState(false);
    const [rejectOnProcess, setRejectOnProcess] = useState(false)
    const [error, setError] = useState('')
    const [success, setSuccess] = useState(false);
    const [rejectSuccess, setRejectSuccess] = useState(false)

    const { 
      user
    } = useContext(variableManager);

    const acceptSub = (e) => {
      setOnProcess(true)
      axios.get(`${process.env.REACT_APP_BASE_URL}/business/sub/accept/${id}`, {
          headers: {
              "x-token": `${loggedUser}`,
              "x-signature": process.env.REACT_APP_SIGNATURE
          }
      }).then((res) => {
          setSuccess(true);
          setOnProcess(false);
          navigate('/')
      }).catch((err) => {
          setOnProcess(false);
          setError(err.response.data.message)
          setTimeout(() => {
              setError('')
          }, 4000)
      })
    }

    const rejectSub = (e) => {
      setRejectOnProcess(true)
      axios.delete(`${process.env.REACT_APP_BASE_URL}/business/sub/reject/${id}`, {
          headers: {
              "x-token": `${loggedUser}`,
              "x-signature": process.env.REACT_APP_SIGNATURE
          }
      }).then((res) => {
          setRejectSuccess(true);
          setRejectOnProcess(false);
          navigate('/')
      }).catch((err) => {
          setRejectOnProcess(false);
          setError(err.response.data.message)
          setTimeout(() => {
              setError('')
          }, 4000)
      })
  }

  return (
    <>
    <div className="settings-container">
        <h5><b>Invitation as a Sub-Account</b></h5>
        <div 
            className="transaction-history-container"
            style={{
                margin: '2rem 0'
            }}
        >
      <div 
        className="table-section"
        style={{
          margin: '0rem 0',
          padding: '20px'
        }}
      >
        <br />
        <h5>Hi, {user ? user.businessName : ''}</h5>
        <br />
        <p>You have been invited by (<b>{BN ? BN.replace(new RegExp('%20', 'g'), ' ') : ''}</b>), to be a sub-account. <br />Kindly click on Accept to agree to this service.</p>
        <div
          style={{
            margin: '2rem 0',
            display: 'flex',
            gap: '20px'
          }}
        >
          <button
            style={{
              padding: '10px 30px',
              background: '#3E3E0D',
              color: '#fff',
              border: '1px solid #3E3E0D',
              borderRadius: '4px'
            }}
            onClick={() => acceptSub()}
          >{onProcess ? <ClipLoader color='#fff' /> : 'Accept'}</button>
          <button
            style={{
              padding: '10px 30px',
              color: '#ED1C24',
              background: 'transparent',
              border: '1px solid #ED1C24',
              borderRadius: '4px'
            }}
            onClick={() => rejectSub()}
          >{rejectOnProcess ? <ClipLoader color='#c82b32' /> : 'Reject'}</button>
        </div>
      </div>
    </div>
        
    </div>
    {
        success ? 
            <Alert
                closeFunc={() => {
                    setSuccess(false);
                }}
                img='/success.png'
                message='Accepted Successfully'
                miniMessage="You have successfully being added as a sub-business, you will be redirected back to the dashboard."
            />
        :null
    }
    {
        rejectSuccess ? 
            <Alert
                closeFunc={() => {
                    setRejectSuccess(false);
                }}
                img='/success.png'
                message='Rejected Successfully'
                miniMessage="You have successfully rejected this invite as a sub-business, you will be redirected back to the dashboard."
            />
        :null
    }
    {
          error ? 
            <Alert
                closeFunc={() => setError('')}
                img='/warning.png'
                message='An error occured!'
                miniMessage={error}
            />
            :null
    }
    </>
    
  );
}
