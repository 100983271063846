import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { KycManager } from "../../../context-api/KycContext";
import { variableManager } from "../../../context-api/VariableContex";
import close from "../../../icons/close.svg";
import "../../../styles/modals.scss";
import { BsArrowRight } from "react-icons/bs";
import { formatAmount } from "../../../utils/formatAmount";
import axiosInstance from "../../../utils/axios_instance";
export default function PayInvoice(props) {
  const { payModalShow, setPayModalShow, showCreate, setShowCreate } =
    useContext(KycManager);
  const [filled, setFilled] = useState(false);

  const { handleValidateBalance, msg, setMsg, user } =
    useContext(variableManager);

  const [error, setError] = useState("");

  const [invoiceDetails, setInvoiceDetails] = useState({
    invoiceNumber: "",
    amount: "",
    narration: "",
  });

  useEffect(() => {
    handleValidation();
  }, [invoiceDetails]);

  async function handleValidateInvoice() {
    setMsg({ ...msg, spinner: true });
    try {
      await axiosInstance
        .get(`/business/invoice/${invoiceDetails.invoiceNumber}`)
        .then((resp) => {
          setMsg({ ...msg, spinner: false });
          setInvoiceDetails({
            ...invoiceDetails,
            amount: resp?.data?.data?.total,
          });
        });
    } catch (error) { 
      setMsg({
        ...msg,
        spinner: false,
        error: "",
      });
    }
  }

  function handleValidation() {
    if (!invoiceDetails.invoiceNumber || !invoiceDetails.amount) {
      setFilled(false);
    } else {
      setFilled(true);
    }
  }
  function handleChange(event) {
    setError("");
    setMsg({ ...msg, error: "", success: "" });
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    if (event.target.name === "amount") {
      const value = event.target.value.replace(/,/g, "");
      setInvoiceDetails({
        ...invoiceDetails,
        [event.target.name]: value ? value : "",
      });
    } else {
      setInvoiceDetails({
        ...invoiceDetails,
        [event.target.name]: value ? value : "",
      });
    }
  }

  return (
    <div className="pay-now-container">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div className="modal-header  d-flex justify-content-between  w-100">
            <h4>Pay for an Invoice</h4>
            <img
              draggable="false"
              src={close}
              className="pointer"
              alt="close"
              onClick={() => {
                setPayModalShow({ ...payModalShow, payInvoice: false });
                setError("");
                setMsg({ ...msg, error: "", success: "", spinner: false });
                setInvoiceDetails({
                  ...invoiceDetails,
                  invoiceNumber: "",
                  pin: "",
                  narration: "",
                  amount: "",
                });
              }}
            />
          </div>
        </Modal.Header>
        <Modal.Body className="modalContent">
          <div className="input-area">
            <div className="label">
              <label htmlFor="invoiceNumber">Invoice Number</label>
            </div>
            <div className="inpu d-flex ">
              <input
                type="text"
                id="invoiceNumber"
                name="invoiceNumber"
                onChange={(e) => {
                  handleChange(e);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (invoiceDetails.invoiceNumber) {
                      handleValidateInvoice();
                    } else {
                      setError(true);
                    }
                  }
                }}
              />
              <div
                className="enter"
                onClick={() => {
                  if (invoiceDetails.invoiceNumber) {
                    handleValidateInvoice();
                  } else {
                    setError(true);
                  }
                }}
              >
                {msg.spinner ? (
                  <Spinner size="sm" variant="light" />
                ) : (
                  <BsArrowRight />
                )}
              </div>
            </div>

            <div className=" ">
              <label htmlFor="username">Amount </label>
              <div className="d-flex">
                <input
                  type="text"
                  id="username"
                  placeholder="00,000,000 "
                  disabled
                  name="amount"
                  value={formatAmount(invoiceDetails.amount)}
                  style={{ borderRight: "none" }}
                />

                <div className="input-balance">
                  <div>
                    {" "}
                    <div className="currency">NGN</div>
                    <div className="bal">
                      Bal:{formatAmount(user.walletBalance)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" ">
              <label htmlFor="description">Payment Description </label>
              <input
                type="text"
                id="description"
                placeholder=" "
                name="narration"
                value={invoiceDetails.narration}
                onChange={(e) => {
                  handleChange(e);
                  // handleValidation();
                }}
              />
            </div>

            <div className="checkbox">
              <label htmlFor="saveBeneficiary">
                <input
                  type="checkbox"
                  name="saveBeneficiary"
                  id="saveBeneficiary"
                  value={invoiceDetails.saveBeneficiary}
                  onChange={(e) => handleChange(e)}
                />{" "}
                Save Beneficiary
              </label>
            </div>

            <div className="button-section">
              {/* FOR INSUFFICIENT BALANCE */}
              {error && <p className="mo-danger">{error}</p>}
              {payModalShow.toBank &&
              msg.error &&
              msg.error !== "Name cannot be verified at the moment" ? (
                <p className="mo-danger">{msg.error}</p>
              ) : null}{" "}
              {payModalShow.toBank && msg.success ? (
                <p className="mo-danger fw-bold">{msg.success}</p>
              ) : null}
              <button
                className={filled ? "btn-active" : "btn-inactive"}
                onClick={() => {
                  // handleSendtoBank();
                  if (
                    filled &&
                    handleValidateBalance(invoiceDetails.amount, user)
                  ) {
                    setShowCreate({ ...showCreate, enterPin: true });
                  } else {
                    if (!handleValidateBalance(invoiceDetails.amount, user)) {
                      setError(
                        "Insufficient funds. Kindly fund your wallet to continue transaction"
                      );
                    }
                  }
                }}
              >
                {msg.spinner ? <Spinner size="sm" /> : "Proceed"}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
