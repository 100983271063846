import { useContext, useState } from "react";
import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { KycManager } from "../../context-api/KycContext";
import { variableManager } from "../../context-api/VariableContex";
import "../../styles/modals.scss";
import "../../styles/kycDocUpload.scss";
import axios from "axios";
import axiosInstance from "../../utils/axios_instance";
import { RenameFile } from "../../utils/RenameFiles";
import upload from "../../icons/upload.svg";
export default function KycUtilityDocUpload(props) {
  const {
    toverify,
    // handleVerifyBvn,
    showVeriSuccess,
    setShowVeriSuccess,

    modalShowUtilityDocUpload,
    setModalShowUtilityDocUpload,
  } = useContext(KycManager);

  const { user, msg, setMsg, handleGetUser } = useContext(variableManager);
  const [empty, setEmpty] = useState(false);

  const [doc, setDoc] = useState({
    cacCertificate: "",
    meMart: "",
    forms: "",
  });

  const [docToUpload, setDocToUpload] = useState({
    cacCertificate: "",
    meMart: "",
    forms: "",
  });

  const [docPreview, setDocPreview] = useState({
    cacCertificate: "",
    meMart: "",
    forms: "",
  });

  function handleDocChange(e) {
    setMsg({ ...msg, spinner: false });
    const value = RenameFile(e.target.files[0], user.businessName);
    const field = e.target.name;
    setDoc({
      ...doc,
      [field]: value,
    });
    setDocPreview({
      ...docPreview,
      [field]: URL.createObjectURL(value),
    });
  }

  function handleValidation() {
    //doc.cacCertificate is utility in this case
    if (doc.cacCertificate) {
      handleKycDocumentsUpload();
    } else {
      setEmpty(true);
    }
  }

  async function handleKycDocumentsUpload() {
    setMsg({ ...msg, spinner: true });
    const files = [doc.cacCertificate];

    // An array to store the promises from the Axios POST requests
    const uploadPromises = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const formdata = new FormData();

      formdata.append("file", file);
      formdata.append("upload_preset", "business-utility-documents");

      // Push the promise into the array
      uploadPromises.push(
        axios
          .post(
            `${process.env.REACT_APP_CLOUDINARY_URL}/${process.env.REACT_APP_CLOUDINARY_USERNAME}/image/upload`,
            formdata
          )
          .then((resp) => resp.data.secure_url)
      );
    }

    try {
      // Wait for all upload promises to complete
      const uploadedUrls = await Promise.all(uploadPromises);

      // Update the docToUpload object with the URLs
      setDocToUpload({
        ...docToUpload,
        cacCertificate: uploadedUrls[0],
        forms: uploadedUrls[1],
        meMart: uploadedUrls[2],
      });

      // make the final axios POST request with the updated docToUpload
      const resp = await axiosInstance.post(
        `/business/utility/submit`,
        {utility:uploadedUrls[0]}
      );

      setMsg({ ...msg, spinner: false });
      handleGetUser();
      setModalShowUtilityDocUpload({
        ...modalShowUtilityDocUpload,
        upload: false,
        success: true,
      });
    } catch (error) {
      console.error("Error:", error);
      setMsg({ ...msg, spinner: false });
    }
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        onHide={() => {
          setShowVeriSuccess({ ...showVeriSuccess, error: "" });
          setMsg({ ...msg, spinner: false });
        }}
      ></Modal.Header>

      <Modal.Body className="modalContent width-90 kyc-document-upload-container">
        <h2>Utility Bill Upload</h2>
        <p>
          Kindly provide us with a utility bill of your current business
          organization
        </p>

        <center className="document-upload-field pointer ">
          <label htmlFor="cacCertUpload" className="pointer">
            <img src={upload} alt="upload document" /> <br />
            Click here to upload Utility Bill <br />
            PNG, JPG up to 5mb
          </label>
          <div className="input-section">
            <input
              type="file"
              id="cacCertUpload"
              accept="image/*"
              name="cacCertificate"
              className="opacity-0"
              onChange={(e) => {
                handleDocChange(e);
              }}
            />
           
          </div>
          {docPreview.cacCertificate ? (
              <a href={docPreview.cacCertificate} target="blank" className="btn-secondary btn text-light">
                Preview
              </a>
            ) : null}

          {empty && !doc.cacCertificate ? (
            <div className="mo-danger">This field is required</div>
          ) : null}
        </center>

        <p className="text-danger text-center fw-bold">
          {showVeriSuccess.error ? showVeriSuccess.error : null}
        </p>

        <div className="buttons">
          <button
            className={doc.cacCertificate ? "btn-active" : "btn-inactive"}
            disabled={msg.spinner ? "{true}" : ""}
            onClick={() => {
              handleValidation();
            }}
          >
            {msg.spinner ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Upload & Proceed"
            )}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
