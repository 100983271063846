import React from "react";
import Close from "../../../asset/icon/ModalClose.svg";
import PinChangeSuccess from "../../../asset/icon/PinChangeSuccess.svg";

const CardPinSuccess = ({ handleClose }) => {
  return (
    <div>
      <div className="manageCardOptionsHeader">
        <p></p>
        <img src={Close} alt="close" onClick={handleClose} />
      </div>
      <div className="manageCard-cardPinSuccessContainer">
        <img src={PinChangeSuccess} alt="success" />
        <p>PIN Changed</p>
        <p>You have successfully Changed your Card PIN</p>
        <button onClick={handleClose} className="defaultBtn">
          Okay
        </button>
      </div>
    </div>
  );
};

export default CardPinSuccess;
