import axios from "axios";
import { Navigate } from "react-router-dom";

const token = localStorage.getItem("mg-u-tkn");

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    common: {
      "Content-Type": "application/json",
      "x-token": token,
      [process.env.REACT_APP_SIGN_KEY]: process.env.REACT_APP_SIGNATURE,
    },
  },
});

axiosInstance.interceptors.response.use(
  (response) => handleSuccess(response),
  (error) => handleError(error)
);

function handleSuccess(response) {
  return response;
}

function handleError(error) {
  if (error.response && error.response.code === 401) {
    Navigate({ to: "/login", replace: true });
  }

  throw error;
}

export default axiosInstance;
