import "../styles/dashboard.scss";
import { useNavigate } from "react-router-dom";
import Navigation from "../components/dashboard/Navigation";
import Sidebar from "../components/dashboard/Sidebar";
import { useContext } from "react";
import { KycManager } from "../context-api/KycContext";
import Invoices from "../components/dashboard/invoice"; 

export default function InvoicesPage() {
  const navigate = useNavigate();
  const { showSidebar, setShowSidebar } = useContext(KycManager);

  return (
    <div className="dashboard-container">
      <div className="navigation-section">
        <Navigation />
      </div>
      <div className="body-section">
        <div className={showSidebar ? "sidebar" : "hide-sidebar"}>
          <Sidebar />
        </div>

        <div className="dashboard-body" onClick={() => setShowSidebar(false)}>
          <Invoices />
        </div>
      </div>

      <div className="modals">

      </div>
    </div>
  );
}
