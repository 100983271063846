import styled from 'styled-components';

export const TableWrap = styled.div`
    margin:2rem 0;
    border-radius:30px;
    padding:20px 20px;
    background:#fff;
    position:relative;
    
    @media (max-width:728px){
        width:90%;
        padding:20px 5%;
        overflow:auto;
        border-radius:4px;
    }
`

export const HeroCover = styled.div`
    position:absolute;
    width:100%;
    height:3rem;
    top:-1.8rem;
    left:0;
    background: url('/images/mini-bg.png');
    // background-size:cover;
    z-index:-1;
`

export const TableFlex = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    cursor: ${p => p.usecursor="true" ? 'pointer' : ''};
    margin:0 0 1rem 0;
    padding: 0 1rem 0 0;
    position:relative;

    h3 {
        font-weight: ${p => p.usecursor="true" ? 500 : 700};
        font-size: 15px;
        line-height: 23px;
        color: #000000;
        width:100%;
        overflow:hidden;
        white-space:nowrap;
        text-overflow:ellipsis;
        text-align:left;
    }

    p {
        width:100%;
        overflow:hidden;
        white-space:nowrap;
        text-overflow:ellipsis;
        font-weight: 200;
        font-size: 13px;
        line-height: 23px;
        color: #000000;
        text-align:left;
    }

    @media (max-width:728px){  
        width:70rem;
    }
`

export const TableName = styled.div`
    width:25%;
`

export const Role = styled.div`
    width:15%;
`

export const DateWrap = styled.div`
    width:18%;
`

export const SmallTableSpace = styled.div`
    width:7%;
    text-align:center;
`

export const MenuSpace = styled.div`
    width:2%;

    svg {
        cursor:pointer;
    }
`

export const Line = styled.hr`
    border:none;
    border-bottom:1px solid #f4f4f4;
`

// Modal Section

export const ModalWrap = styled.div`
    width:100%;
    height:100%;
    background: rgba(0, 0, 0, 0.8);
    position:fixed;
    top:0;
    left:0;
    display:flex;
    align-items:center;
    justify-content:center;
    z-index:1000;

    @media (max-width:728px){
        align-items:flex-start;
    }
`

export const ModalChild = styled.div`
    width:350px;
    max-height:80%;
    overflow-y:auto;
    padding:5px 40px 30px 40px;
    background: #fff;
    box-shadow: 0px 8px 40px rgba(9, 44, 76, 0.16);
    border-radius: 8px;

    > section {
        height:70vh;
        overflow-y:auto;
    }

    > button {
        margin: 1rem 0 0 0;
    }

    @media (max-width:728px){
        width:100%;
        height:100vh;
        max-height:none;
        border-radius:0;

        > section{
            height:88vh;
        }
    }
`

export const Button = styled.button`
    background: ${p => p.bg || 'transparent'};
    color: ${p => p.color || 'var(--text-black)'};
    border: ${p => p.border || 'none'};
    padding: ${p => p.padding || '0.7rem 1.5rem'};
    width: ${p => p.width || 'auto'};
    display:flex;
    align-items:Center;
    justify-content:center;
    gap:5px;
`;