import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "../../../styles/modals.scss";
import { useContext, useState } from "react";
import axiosInstance from "../../../utils/axios_instance";
import { KycManager } from "../../../context-api/KycContext";
export default function ActivatePOS(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [posDetails, setPosDetails] = useState({
    terminalCode: "",
    deviceId: "",
  });

  const { showPos, setshowPos } = useContext(KycManager);
  function handleValidation() {
    if (posDetails.deviceId && posDetails.terminalCode) {
      handlePosActivation(posDetails.deviceId);
    } else {
      setEmpty(true);
    }
  }

  async function handlePosActivation(deviceId) {
    setIsLoading(true);

    try {
      const { data } = await axiosInstance.post(
        `/business/mpos/activate/${deviceId}`,
        posDetails
      );
      if (data) {
        setshowPos({ ...showPos, terminal_id: false, activate_success: true });
      }
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        onHide={() =>
          setPosDetails({ ...posDetails, deviceId: "", terminalCode: "" })
        }
      ></Modal.Header>
      <Modal.Body className="modalContent">
        <h2>Activate POS</h2>
        <p>Please Enter the following details</p>
        <div className="input-area">
          <div className="label">
            <label htmlFor="enterEvn">Terminal ID</label>
          </div>
          <div className="input">
            <input
              type="text"
              id="enterBvn"
              placeholder="Enter Terminal ID"
              value={posDetails.deviceId}
              onChange={(e) =>
                setPosDetails({ ...posDetails, deviceId: e.target.value })
              }
            />
            {empty && !posDetails.deviceId ? (
              <div className="text-danger">
                {" "}
                Enter the device code from your POS listing
              </div>
            ) : null}
          </div>
        </div>
        <div className="input-area">
          <div className="label">
            <label htmlFor="deviceId">Device Code</label>
          </div>
          <div className="input">
            <input
              type="text"
              id="deviceId"
              placeholder="Enter the 6 digit ID on your device"
              value={posDetails.terminalCode}
              onChange={(e) =>
                setPosDetails({ ...posDetails, terminalCode: e.target.value })
              }
            />
            {empty && !posDetails.terminalCode ? (
              <div className="text-danger">Enter the ID on your device</div>
            ) : null}
          </div>
        </div>
        <div className="buttons">
          <button
            className={
              posDetails.terminalCode && posDetails.deviceId
                ? "btn-active"
                : "btn-inactive"
            }
            onClick={() => {
              handleValidation();
            }}
          >
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
