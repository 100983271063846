import { useContext, useState } from "react";
import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { KycManager } from "../../context-api/KycContext";
import { variableManager } from "../../context-api/VariableContex";
import "../../styles/modals.scss";
import doc_upload_success from "../../icons/utility_success.svg";
export default function KycUtilityUploadSuccess(props) {
  const { 
    toverify, 
    showVeriSuccess,
    setShowVeriSuccess, 
    modalShowUtilityDocUpload,
    setModalShowUtilityDocUpload,
  } = useContext(KycManager);
  const [bvn, setBvn] = useState(false);

  const { msg, setMsg, } =
    useContext(variableManager);
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        onHide={() => {
          setShowVeriSuccess({ ...showVeriSuccess, error: "" });
          setMsg({ ...msg, spinner: false });
        }}
      ></Modal.Header>
      <Modal.Body className="modalContent text-center width-90">
        <center>
          <img src={doc_upload_success} draggable="false" alt="Upload success" />
        </center>

        <h2>Welldone! your documents are currently under review</h2>
        <p>You'll get an email as soon as your documents are being reviewd and approved</p>

        <div className="buttons">
          <button
            className={toverify.bvn ? "btn-active" : "btn-active"}
            disabled={msg.spinner ? "{true}" : ""}
            onClick={() => { 
                setModalShowUtilityDocUpload({
                    ...modalShowUtilityDocUpload,
                    success: false,
                  }); 
            }}
          >
            {msg.spinner ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Go Back Home"
            )}
          </button> 
        </div>
      </Modal.Body>
    </Modal>
  );
}
