import styled from 'styled-components';

export const EmptyMainWrap = styled.div`
    position:relative;
    display:flex;
    align-items:center;
    justify-content:Center;
    margin:4rem 0 2rem 0;

    > div {
        display:flex;
        flex-direction:column;
        align-items:Center;
        gap:20px;
    }
`

export const EmptyImage = styled.img`
    width: 6rem;
`