import React, { useState } from "react";
import Close from "../../../asset/icon/ModalClose.svg";

const CardPin = ({ handleClose, next }) => {
  const [prevPin, setPrevPin] = useState(new Array(4).fill(""));

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return;

    let newPin = [...prevPin];
    newPin[index] = element.value;
    setPrevPin(newPin);

    //Focus next input if the current value is not empty
    if (element.value !== "" && element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.keyCode === 8) {
      // backspace key
      e.preventDefault();
      let newPin = [...prevPin];
      newPin[index] = "";
      setPrevPin(newPin);
      if (index > 0) {
        e.target.previousSibling.focus();
      }
    } else if (e.key === "ArrowLeft") {
      if (e.target.previousSibling) {
        e.target.previousSibling.focus();
      }
    } else if (e.key === "ArrowRight") {
      if (e.target.nextSibling) {
        e.target.nextSibling.focus();
      }
    }
  };

  return (
    <div>
      <div className="manageCardOptionsHeader">
        <p>Change Card PIN</p>
        <img src={Close} alt="close" onClick={handleClose} />
      </div>
      <div className="manageCardPinChangeContainer">
        <label htmlFor="prevPin" className="cardPinLabel">
          Enter Previous Card PIN
          <div className="otp-box mb-4">
            {prevPin.map((data, index) => {
              return (
                <input
                  className={`pinCard-field ${data ? "cardPinFilled" : ""}`}
                  type="text"
                  name="otp"
                  maxLength="1"
                  key={index}
                  value={data}
                  onChange={(e) => {
                    handleChange(e.target, index);
                  }}
                  onFocus={(e) => e.target.select()}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                />
              );
            })}
          </div>
        </label>

        <label htmlFor="prevPin" className="cardPinLabel">
          New Card PIN
          <div className="otp-box mb-4">
            {prevPin.map((data, index) => {
              return (
                <input
                  className={`pinCard-field ${data ? "cardPinFilled" : ""}`}
                  type="text"
                  name="otp"
                  maxLength="1"
                  key={index}
                  value={data}
                  onChange={(e) => {
                    handleChange(e.target, index);
                  }}
                  onFocus={(e) => e.target.select()}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                />
              );
            })}
          </div>
        </label>

        <label htmlFor="prevPin" className="cardPinLabel">
          Confirm Card PIN
          <div className="otp-box mb-4">
            {prevPin.map((data, index) => {
              return (
                <input
                  className={`pinCard-field ${data ? "cardPinFilled" : ""}`}
                  type="text"
                  name="otp"
                  maxLength="1"
                  key={index}
                  value={data}
                  onChange={(e) => {
                    handleChange(e.target, index);
                  }}
                  onFocus={(e) => e.target.select()}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                />
              );
            })}
          </div>
        </label>

        <button className="defaultBtn" onClick={next}>
          Save
        </button>
      </div>
    </div>
  );
};

export default CardPin;
