import React from "react";
import "../../../styles/manage_cards.scss";
import CardRight from "./CardRight";

const CardSelect = ({ handleOpen }) => {
  return (
    <div className="card-container">
      <div className="manageCard-container">
        <div className="manageCard-leftContainer">
          <div className="manageCard-cardTypeHeader">
            <p>My Card</p>
            <p>No Virtual cards, no physical card</p>
          </div>
          <div className="manageCard-cardTypeContainer">
            <div className="manageCard-cardType">
              <div>
                <input type="radio" name="" id="" />
              </div>
              <div>
                <p>Naira Card</p>
                <p>You’re to provide the phone number linked to this account</p>
              </div>
            </div>

            <div className="manageCard-cardType">
              <div>
                <input type="radio" name="" id="" />
              </div>
              <div>
                <p>Dollar Card</p>
                <p>You’re to provide the email linked to this account</p>
              </div>
            </div>

            <button onClick={handleOpen}>Request card</button>
          </div>
        </div>
        <CardRight />
      </div>
    </div>
  );
};

export default CardSelect;
