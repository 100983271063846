import "../../../styles/settings.scss";
import { useContext, useState, useEffect } from "react";
import { variableManager } from "../../../context-api/VariableContex";
import { useNavigate } from "react-router-dom";
import { BsDot, BsThreeDotsVertical } from "react-icons/bs";
import { formatAmount } from "../../../utils/formatAmount";
import MarkInvoicePaid from "./MarkInvoicePaid";

export default function Invoices() {
  const navigate = useNavigate();
  const {
    nameCase,
    getInvoices,
    invoices,
    showResolveInvoice,
    setShowResolveInvoice,
  } = useContext(variableManager);
  const [showOptions, setShowOptions] = useState({
    action: false,
    actionId: "",
    selected: {},
  });

  useEffect(() => {
    getInvoices();
  }, []);

  // console.log(invoices);

  return (
    <>
      <div
        className="settings-container"
        onClick={() =>
          setShowOptions({
            ...showOptions,
            action: false,
            actionId: "",
          })
        }
      >
        <h5>
          <b>Invoices</b>
        </h5>
        <div className="mpos-section d-flex justify-content-end ">
          <button
            className="mo-active-btn w-1 mt-2"
            style={{
              width: "15rem",
            }}
            onClick={() => navigate("/user/dashboard/invoices/create")}
          >
            New Invoice
          </button>
        </div>

        <div className="transaction-invoices-container mt-3">
          <div className="table-section">
            <table>
              <thead>
                <tr>
                  <td>{/* <input type="checkbox" /> */}</td>
                  <td>I.D</td>
                  <td>Date & Time</td>
                  <td>Bill to</td>
                  <td>Description</td>
                  <td>Amount</td>
                  <td>Status</td>
                </tr>
              </thead>

              {invoices.length ? (
                invoices.map((e, i) => {
                  return (
                    <tbody key={i}>
                      <tr>
                        <td>{/* <input type="checkbox" /> */}</td>
                        <td>{e.invoiceID}</td>
                        <td>{new Date(e.createdAt).toLocaleString()}</td>
                        <td>
                          <p>{nameCase(e.billTo)}</p>
                        </td>
                        <td>{e.description}</td>
                        <td>₦ {formatAmount(e.total)}</td>
                        <td className="d-flex align-items-center position position-relative">
                          <b
                            className={
                              e.status === "Failed" || e.status === "failed"
                                ? " status text-danger bg-transparent"
                                : e.status.toLowerCase() === "pending"
                                ? "text-dark bg-transparent"
                                : "status bg-transparent"
                            }
                          >
                            <BsDot size={30} />{" "}
                            {e.status === "Success" ? "Paid" : "Unpaid"}
                          </b>{" "}
                          {e.status.toLowerCase() !== "success" ? (
                            <BsThreeDotsVertical
                              className="ms-3 pointer"
                              onClick={(event) => {
                                event.stopPropagation();
                                setShowOptions({
                                  ...showOptions,
                                  action: !showOptions.action,
                                  actionId: e.iid,
                                  selected: e,
                                });
                              }}
                            />
                          ) : null}
                          {showOptions.action &&
                            showOptions.actionId === e.iid && (
                              <div className="action-center position position-absolute">
                                <button
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    setShowResolveInvoice(true);
                                  }}
                                >
                                  Mark as paid
                                </button>
                              </div>
                            )}
                        </td>
                      </tr>
                    </tbody>
                  );
                })
              ) : (
                <tbody>
                  <tr>
                    <td>You have no invoices</td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>

          <div className="add-container ">
            <div className="add-new">
              {/* <img src={add} alt="add new" /> */}
            </div>
          </div>

          {invoices.length ? (
            <p className="showing">
              {" "}
              Showing{" "}
              <span className="shown">
                {/* {handleTruncateHistory(invoices)} */}
                {invoices.length}
              </span>{" "}
              of <span className="total">{invoices.length}</span> enteries
            </p>
          ) : null}
        </div>

        <div className="modals">
          <MarkInvoicePaid
            invoiceDetails={showOptions.selected}
            show={showResolveInvoice}
            onHide={() => setShowResolveInvoice(false)}
          />
        </div>
      </div>
    </>
  );
}
