import { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import tag1 from "../../../icons/tag1.svg";
import tag2 from "../../../icons/tag2.svg";
import tag3 from "../../../icons/tag3.svg";
import close from "../../../icons/close.svg";
import "../../../styles/modals.scss";
import { KycManager } from "../../../context-api/KycContext";
import { variableManager } from "../../../context-api/VariableContex";
export default function ChooseMethod(props) {
  const { payModalShow, setPayModalShow, initPayModal } =
    useContext(KycManager);

  const { bankList, handleBankList } = useContext(variableManager);
  return (
    <div className="sendpayment">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div
            className="modal-header pointer"
            onClick={() =>
              setPayModalShow({ ...payModalShow, chooseMethod: false })
            }
          >
            <img draggable="false" src={close} alt="close" />
          </div>
        </Modal.Header>
        <Modal.Body className="modalContent">
          <h2>Send Payment to</h2>

          <div className="input-area">
            <div
              className="tag pointer mogoro-user"
              onClick={() =>
                setPayModalShow({ ...initPayModal, toUsername: true })
              }
            >
              <img src={tag1} draggable="false" alt="send to mongoro user" />
            </div>
            <div
              className="tag pointer bank"
              onClick={() => {
                if (!bankList.length) {
                  handleBankList();
                }
                setPayModalShow({ ...initPayModal, toBank: true });
              }}
            >
              <img src={tag2} draggable="false" alt="send to bank account" />
            </div>{" "}


            
            <div
              className="tag pointer bank"
              onClick={() => {
                if (!bankList.length) {
                  handleBankList();
                }
                setPayModalShow({ ...initPayModal, payInvoice: true });
              }}
            >
              <img src={tag3} draggable="false" alt="pay for an invoice" />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <div className="other-modals">
        {/* <SendToUsername
                    show={payModalShow.toUsername}
                    onHide={()=>setPayModalShow({...payModalShow, toUsername:false})}
                    backdrop="static"
                /> */}

        {/* <SendToBank
                    show={payModalShow.toBank}
                    onHide={()=>setPayModalShow({...payModalShow, toBank:false})}
                    backdrop="static"
                /> */}
      </div>
    </div>
  );
}
