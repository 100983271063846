// import mpos_wallet from "../../icons/mpos_wallet.png";
import toggle from "../../icons/toggle2.svg";
import { GoPrimitiveDot } from "react-icons/go";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import "../../styles/dashboard.scss";
import { useContext, useEffect, useState } from "react";
import { KycManager } from "../../context-api/KycContext";
import { variableManager } from "../../context-api/VariableContex";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../utils/axios_instance";

export default function AccountBalance({
  setShowTransfer,
  // user,
  showTransfer,
}) {
  const {
    setModalShowPrompt,
    setModalShowAccountType,
    setModalShowAccount,
    setModalShowBvn,
    setModalShowDocumentUpload,
    modalShowDocumentUpload,

  } = useContext(KycManager);

  const { nameCase, insights, user } = useContext(variableManager);

  const [showBalance, setShowBalance] = useState(false);

  useEffect(() => {
    setShowBalance(user.showBalance);
  }, [user]);

  const navigate = useNavigate();
  async function handleToggleShowBalance() {
    setShowBalance(!showBalance);

    try {
      await axiosInstance.post(`/user/balance`, {
        balance: !showBalance,
      });
    } catch (error) {
      setShowBalance(showBalance);
    }
  }
  return (
    <div className="account-balance-container">
      <div className="cards-section">
        <h5>Your Balance</h5>
        <div className="account">
          <p>Account Status: REGULAR NGN</p>
          {user && user.Accounts && user.Accounts[0] ? (
            <p className="w-75 text-center accountNumber">
              {user.Accounts[0].accountNumber} |{" "}
              {nameCase(user && user.Accounts && user.Accounts[0].bankName)}
            </p>
          ) : (
            "**********"
          )}
          {(user && user.Accounts && user.Accounts[0]) ? (
            <div className="h-100">
              <div className="d-flex align-items-center gap-2">
                {showBalance ? (
                  <MdOutlineVisibility
                    className="pointer"
                    onClick={() => handleToggleShowBalance()}
                  />
                ) : (
                  <MdOutlineVisibilityOff
                    className="pointer"
                    onClick={() => handleToggleShowBalance()}
                  />
                )}
                {showBalance ? (
                  <h4>₦{parseInt(user.walletBalance).toLocaleString()}</h4>
                ) : (
                  <h4>*****</h4>
                )}
              </div>
              <p className="last">
                Last Transaction :{" "}
                {new Date(user.walletUpdatedAt).toDateString()}{" "}
              </p>
            </div>
          ) : (
            <div className="h-100">
              <h4>₦ ***, ***, ***</h4>
              <p className="last">Last Transaction */**/**</p>
            </div>
          )}
        </div>
        <div className="toggle">
          <img src={toggle} alt="toggle" />
        </div>

        {/* {
        user && user.escrowBalance  ?
            user && user.Accounts && user.Accounts[0] ? (
              <button
                className="mo-success-btn w-50 mt-8"
                style={{
                  margin: '2rem 0 0 15%'
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setModalShowAccount(true);
                }}
              >
                View Account
              </button>
            ) : (
              null
          )
        : null
      } */}
      </div>

      <div className="mpos-section">
        {/* MPOS CARD */}
        {/* <div className="mpos-card-section">
                    <div className="texts">
                        <p>mPOS Wallet</p>
                        <h5>N 6.5K</h5>
                        <p>this month | N 0 Debit | N 0 Credit</p>

                    </div>
                    <div className="image">
                        <img src={mpos_wallet} alt="wallet" />
                    </div>
                </div> */}

        {user && user.Accounts[0] ? (
          <button
            className="mo-success-btn w-50 mt-2"
            onClick={(e) => {
              e.preventDefault();
              setModalShowAccount(true);
            }}
          >
            View Account
          </button>
        ) : (
          <button
            className="mo-active-btn w-50 mt-2"
            onClick={(e) => {
              e.preventDefault();
              if (!user.setupComplete) {
                setModalShowPrompt(true);
              }

              else if(user.document === 0){
                setModalShowDocumentUpload({...modalShowDocumentUpload, upload:true})
              }


              else if (!user.verificationBvn) {
                setModalShowBvn(true);
              }
              else {
                setModalShowAccountType(true);
              }
            }}
          >
            Create Account
          </button>
        )}

        <div className="request-pos">
          <h5>Request POS</h5>
          <p>
            With the Mongoro POS you ease the burden of collecting payments for
            your business. Our POS devices are pocket friendly, durable, and
            simple to use. Quickly request yours, activate it on your account,
            and start collecting payments.
          </p>
        </div>

        <div className="button">
          <button onClick={() => navigate("/user/dashboard/pos")}>
            Request
          </button>
        </div>
      </div>

      <div className="insights-section">
        <div className="content">
          <div className="d-flex align-items-top gap-1">
            <h5 className="fw-bold">Insights</h5>{" "}
          </div>
          <div className="insight">
            <span className="indicator text-warning">
              <GoPrimitiveDot />
            </span>
            <h5>
              <b>
                {insights.lastDeposit
                  ? `₦${insights.lastDeposit.amount}`
                  : "Nil"}
              </b>
            </h5>
            {insights.lastDeposit ? (
              <p className="latest">
                Latest Money in |{" "}
                {new Date(insights.lastDeposit.Date).toLocaleTimeString()}
              </p>
            ) : (
              <p className="latest">Latest Money in | time</p>
            )}
          </div>
          <div className="insight">
            <span className="indicator text-primary">
              <GoPrimitiveDot />
            </span>
            <h5>
              <b>
                {insights.lastWithdrawal
                  ? `₦${insights.lastWithdrawal.amount}`
                  : "Nil"}
              </b>
            </h5>

            {insights.lastWithdrawal ? (
              <p className="latest">
                Latest Money out |{" "}
                {new Date(insights.lastWithdrawal.Date).toLocaleTimeString()}
              </p>
            ) : (
              <p className="latest">Latest Money out | time</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
