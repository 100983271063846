import React, { useState, useEffect } from "react";
import {
  ModalWrap,
  ModalChild,
  ModalHeader,
  InputField,
  UnderlayText,
  UserProfileCard,
  IconCard,
  AvatarImage,
} from "../../../styles/all";
import * as FeatherIcon from "react-feather";
import { Button } from "./style";
import Select from "react-select";
import axios from "axios";
import { useNavigate } from "react-router";
import { CustomStyles } from "../../../utils/selectStyle";
import { ClipLoader } from "react-spinners";
import Alert from "../../../reusable/alert";
import axiosInstance from "../../../utils/axios_instance";

const CreateSub = ({ closeFunc, action, type }) => {
  const width = window.innerWidth;
  const navigate = useNavigate();
  const [users, setUsers] = useState("");
  const [usersMutable, setMutableUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [name, setName] = useState("");
  const [onProcess, setOnProcess] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  // Call Function
  const getUsers = () => {
    axiosInstance
      .get(`/business/all?paginate=false&limit=100000000000`)
      .then((res) => {
        setUsers(res.data.data.data);
        setMutableUsers(res.data.data.data);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          localStorage.clear();
          navigate("/login");
        }
      });
  };

  const basicPayload = {
    email: selectedUser.email,
    subAccountName: selectedUser.businessName,
  };

  const createSub = async (e) => {
    e.preventDefault();
    setOnProcess(true);
    console.log("creatinggggggg");

    try {
      await axiosInstance
        .post(`/business/sub/create`, basicPayload)
        .then((res) => {
          console.log(res);
          setSuccess(true);
          setOnProcess(false);
          action();
        });
    } catch (err) {
      setOnProcess(false);
      setError(err.response.data.message);
      setTimeout(() => {
        setError("");
      }, 4000);
    }

    // axiosInstance.post(`/business/sub/create`, basicPayload).then((res) => {
    //     setSuccess(true);
    //     setOnProcess(false);
    //     action();
    // }).catch((err) => {
    //     setOnProcess(false);
    //     setError(err.response.data.message)
    //     setTimeout(() => {
    //         setError('')
    //     }, 4000)
    // })
  };

  // Get Users
  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <ModalWrap style={success ? { visibility: "hidden" } : {}}>
        <ModalChild
          style={{
            height: width > 728 ? "80vh" : "100%",
          }}
        >
          <ModalHeader>
            <h3>Create Sub-Account</h3>
            <i>
              <FeatherIcon.X onClick={() => closeFunc()} />
            </i>
          </ModalHeader>
          <form onSubmit={(e) => createSub(e)}>
            <UnderlayText>
              Please, enter a new email address to Invite New Users
            </UnderlayText>
            <div className="input-area">
              <InputField
                style={{
                  padding: "0",
                  border: "none",
                }}
              >
                <Select
                  options={users}
                  styles={CustomStyles}
                  isSearchable
                  getOptionLabel={(p) => `${p.firstName} ${p.lastName}`}
                  onChange={(e) => setSelectedUser(e)}
                  formatOptionLabel={(user) => (
                    <UserProfileCard
                      style={{
                        padding: "0",
                        margin: 0,
                      }}
                    >
                      {user.image ? (
                        <AvatarImage
                          src={user.image}
                          alt="Profile"
                          style={{
                            width: "2rem",
                            height: "2rem",
                          }}
                        />
                      ) : (
                        <IconCard
                          background="#FEF9E5"
                          color="#1A1A1A"
                          style={{
                            fontWeight: 800,
                            width: "2rem",
                            height: "2rem",
                          }}
                        >
                          {user.businessName &&
                            user.businessName[0].toUpperCase()}
                        </IconCard>
                      )}
                      <div>
                        <h3>
                          {`${user.businessName ? user.businessName : ""}
                                                    `}
                          {!user.businessName ? "No Name." : null}
                        </h3>
                      </div>
                    </UserProfileCard>
                  )}
                />
              </InputField>
              <div>
                <label htmlFor>Sub-account name</label>
                <input
                  required
                  value={selectedUser.businessName}
                  placeholder={""}
                  type="text"
                  disabled
                />
              </div>
            </div>
            <Button
              width="100%"
              bg="#333"
              color="#fff"
              type="submit"
              disabled={onProcess}
              style={
                onProcess
                  ? {
                      cursor: "not-allowed",
                      margin: "2rem 0 0 0",
                    }
                  : {
                      margin: "2rem 0 0 0",
                    }
              }
            >
              {onProcess ? <ClipLoader color="#fff" size={18} /> : "Create"}
            </Button>
          </form>
        </ModalChild>
      </ModalWrap>
      {success ? (
        <Alert
          closeFunc={() => {
            setSuccess(false);
            closeFunc();
          }}
          img="/success.png"
          message="Created Successfully"
          miniMessage="You have successfully added this business as a sub-account, please wait for them to accept your invitation."
        />
      ) : null}
      {error ? (
        <Alert
          closeFunc={() => setError("")}
          img="/warning.png"
          message="An error occured!"
          miniMessage={error}
        />
      ) : null}
    </>
  );
};

export default CreateSub;
