import { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { KycManager } from "../../../../context-api/KycContext";
import "../../../../styles/modals.scss";
import "../../../../styles/bills.scss";
import { variableManager } from "../../../../context-api/VariableContex";
import airtel from "../../../../icons/airtel.svg";
import nine_mobile from "../../../../icons/9mobile.svg";
import mtn from "../../../../icons/mtn.svg";
import glo from "../../../../icons/glo.svg";
import select_contact from "../../../../icons/select_contact.svg";
import { RenameFile } from "../../../../utils/RenameFiles";
import { HandleSelected } from "../../../../utils/HandleSelected";
import { getBundlesFor } from "../../../../features/bills_payment/DataBundles";
import { formatAmount } from "../../../../utils/formatAmount";
export default function RechargeDetails(props) {
  const {
    toverify,
    ticketDetails,
    handleBillStatusChange,
    selectedBillProvider,
    setSelectedBillProvider,
    billPayload,
    setBillPayload,
  } = useContext(KycManager);
  const { user, msg, handleCreateTicket } = useContext(variableManager);
  const [bundles, setBundles] = useState({ all: [], selected: { amount: "" } });
  const [clicked, setClicked] = useState("")
  const [empty, setEmpty] = useState(false);

  const [saveBeneficiary, setSaveBeneficiary] = useState(false);

  useEffect(() => {
    handleGetBudles();
  }, [JSON.stringify(selectedBillProvider.airtime)]);

 

  function handleFieldValidation() {
    if (
      ticketDetails.title &&
      ticketDetails.category &&
      ticketDetails.description
    ) {
      handleCreateTicket(ticketDetails);
    } else {
      setEmpty(true);
    }
  }

  function handleChange(e) {
    const value =
      e.target.type === "file"
        ? RenameFile(
            e.target.files[0],
            `${user.firstName.toLowerCase()}${user.surname.toLowerCase()}`
          )
        : e.target.value;
    setBillPayload({
      ...billPayload,
      airtimeData: { ...billPayload.airtimeData, [e.target.name]: value },
    });
  }

  async function handleGetBudles(billerCode) {
    setBundles({ ...bundles, all: [], selected: { } });
    if (selectedBillProvider.airtime.airtel) {
      billerCode = "BIL110";
    } else if (selectedBillProvider.airtime.glo) {
      billerCode = "BIL109";
    } else if (selectedBillProvider.airtime.mtn) {
      billerCode = "BIL108";
    } else if (selectedBillProvider.airtime.nineMobile) {
      billerCode = "BIL111";
    }

    const { data, error } = await getBundlesFor(billerCode);
    if (data) {
      setBundles({ ...bundles, all: data });
   
    } else {
      console.log(error);
    }
  }

  // console.log(formatAmount((343434).toString()))

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="modalContent">
        <h2>Airtime</h2>

        <div className="request-pos-container airtime-container">
          <div className="network-providers-section">
            <p className="select-network">Select Network Provider</p>

            <div className="network-providers">
              <div
                className={
                  selectedBillProvider.airtime.airtel
                    ? "active-provider"
                    : "provider"
                }
                onClick={() => {
                  HandleSelected(selectedBillProvider, "airtime", "airtel");
                }}
              >
                <img src={airtel} draggable="false" alt="airtel" />
              </div>
              <div
                className={
                  selectedBillProvider.airtime.nineMobile
                    ? "active-provider"
                    : "provider"
                }
                onClick={() => {
                  HandleSelected(selectedBillProvider, "airtime", "nineMobile");
                }}
              >
                <img src={nine_mobile} draggable="false" alt="9mobile" />
              </div>
              <div
                className={
                  selectedBillProvider.airtime.mtn
                    ? "active-provider"
                    : "provider"
                }
                onClick={() => {
                  HandleSelected(selectedBillProvider, "airtime", "mtn");
                }}
              >
                <img src={mtn} draggable="false" alt="mtn" />
              </div>
              <div
                className={
                  selectedBillProvider.airtime.glo
                    ? "active-provider"
                    : "provider"
                }
                onClick={() => {
                  HandleSelected(selectedBillProvider, "airtime", "glo");
                }}
              >
                <img src={glo} draggable="false" alt="glo" />
              </div>
            </div>
          </div>

          <div className="input-area">
            <div className="label">
              <label htmlFor="option">Airtime/Data</label>
            </div>
            <div className="input">
              <select
                name="type"
                id="option"
                onChange={(e) => {
                  handleChange(e);
                  setClicked(e.target.value.toLowerCase())
                }}
              >
                <option value="">Select Option</option>
                <option value="Airtime">Airtime</option>
                <option value="data">Data</option>
              </select>
            </div>
          </div>

          {clicked==="data" && (
            <div className="input-area">
              <div className="label">
                <label htmlFor="option">Data Plan</label>
              </div>
              <div className="input">
                <select
                  name="type"
                  id="option"
                  onChange={(e) => {
                    const selectedBundle = bundles.all.find(
                      (bundle) => bundle.name === e.target.value
                    );
                    setBundles({ ...bundles, selected: selectedBundle });
                    setBillPayload({
                      ...billPayload,
                      airtimeData: {
                        ...billPayload.airtimeData,
                        // code: selectedBundle.biller_code,
                        // itemCode: selectedBundle.item_code,
                        amount: selectedBundle.amount,
                        type:selectedBundle.biller_name,
                      },
                    });
                  }}
                > 
                  <option value="">Select Option</option>

                  {bundles.all.length ? (
                    bundles.all.map((bundle, i) => { 
                      return (
                        <option key={i} value={bundle.name}>
                          {bundle.name}
                        </option>
                      );
                    })
                  ) : (
                    <option value="">loading...</option>
                  )}
                </select>
              </div>
            </div>
          )}

          <div className="input-area">
            <div className="label">
              <label htmlFor="businessName">Mobile Number</label>
            </div>
            <div className="input d-flex gap-2">
              <input
                type="text"
                id="phone"
                name="customer"
                placeholder="Enter Phone Number"
                onChange={(e) => handleChange(e)}
              />
              <div
                className="select-contact"
                onClick={() =>
                  handleBillStatusChange("global", "chooseBeneficiary")
                }
              >
                <img
                  src={select_contact}
                  draggable="false"
                  className="pointer"
                  alt="select contact"
                />
              </div>
            </div>
          </div>

          {billPayload.airtimeData.type === "Airtime" ? (
            <div className="input-area">
              <div className="label">
                <label htmlFor="quantity">Amount</label>
              </div>
              <div className="input">
                <input
                  type="text"
                  id="quantity"
                  name="amount"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="500"
                />
              </div>
            </div>
          ) : billPayload.airtimeData.type !== "Airtime" &&
            bundles.selected.amount ? (
            <div className="input-area">
              <div className="label">
                <label htmlFor="quantity">Amount</label>
              </div>
              <div className="input">
                <input
                  type="text"
                  id="quantity"
                  name="amount"
                  // value={ formatAmount(`${bundles.selected.amount}`)    }
                  // onChange={(e) => {
                  //   return e;
                  // }}
                  disabled
                  placeholder={formatAmount(`${bundles.selected.amount}`)}
                />
              </div>
            </div>
          ) : null}

          <div className="checkbox-area">
            <label htmlFor="saveBeneficiary">
              <input
                type="checkbox"
                name="saveBeneficiary"
                id="saveBeneficiary"
                onChange={(e) => {
                  handleChange(e);
                  setSaveBeneficiary(!saveBeneficiary);
                }}
              />{" "}
              Save Beneficiary
            </label>
          </div>
        </div>

        {saveBeneficiary && (
          <div className="input-area">
            <div className="label">
              <label htmlFor="beneficiary_name">Beneficiary Name</label>
            </div>
            <div className="input">
              <input
                type="text"
                id="beneficiary_name"
                name="beneficiaryName"
                onChange={(e) => {
                  handleChange(e);
                }}
                placeholder="my mtn number"
              />
            </div>
          </div>
        )}

        <div className="buttons">
          <button
            className={toverify.bvn ? "btn-active" : "btn-active"}
            onClick={() => {
              // handleFieldValidation();
              handleBillStatusChange("airtime", "paymentMethod"); 
            }}
            disabled={msg.spinner ? "{true}" : ""}
          >
            {msg.spinner ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Recharge"
            )}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
