import React from "react";
import EmptyCard from "../../../asset/icon/CardEmptyState.svg";

const CardEmptyState = () => {
  return (
    <div className="manageCard-stateContainer">
      <img src={EmptyCard} alt="empty state" />
      <p>No card here</p>
      <p>
        Request for a card and we’ll have it delivered to you, where ever you
        are.
      </p>
    </div>
  );
};

export default CardEmptyState;
