import React from 'react';
import { EmptyImage, EmptyMainWrap } from '../../styles/emptyState';

const EmptyState = () => {
    return(
        <>
            <EmptyMainWrap>
                <div>
                    <EmptyImage 
                        src='/box.png'
                        alt='Empty Icon'
                    />
                    <p>Sorry, no data found.</p>
                </div>
            </EmptyMainWrap>
        </>
    )
}

export default EmptyState;