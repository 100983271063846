import { Modal } from "@mui/material";
import React, { useState } from "react";
import CreditCard from "../../../asset/icon/CreditCard.svg";
import CashIcon from "../../../asset/icon/CashIcon.svg";
import PinCode from "../../../asset/icon/PinCode.svg";
import Block from "../../../asset/icon/Block.svg";
import View from "../../../asset/icon/View.svg";
import Close from "../../../asset/icon/ModalClose.svg";
import CardPinModal from "./CardPinModal";

const ManageCardModal = ({ open, handleClose }) => {
  const [moreOption, setMoreOption] = useState({
    generalMenu: true,
    changePin: false,
    alertSubscription: false,
    blockCard: false,
    transactionPin: false,
  });
  const manageCardOptions = [
    {
      imgSrc: CreditCard,
      title: "Freeze and Unfreeze",
      check: true,
    },
    {
      imgSrc: CashIcon,
      title: "Manage Disputes",
      check: false,
      fn: () => {
        console.log("Hello");
      },
    },
    {
      imgSrc: PinCode,
      title: "Change Pin",
      check: false,
      fn: () => {
        setMoreOption({ ...moreOption, generalMenu: false, changePin: true });
      },
    },
    {
      imgSrc: PinCode,
      title: "Set Limit",
      check: false,
      fn: () => {
        console.log("Hello");
      },
    },
    {
      imgSrc: PinCode,
      title: "SMS Alert Subcription",
      check: false,
      fn: () => {
        console.log("Hello");
      },
    },
    {
      imgSrc: Block,
      title: "Block Card",
      check: false,
      fn: () => {
        console.log("Hello");
      },
    },
  ];

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <div className="manageCard-modalContainer">
          {moreOption.generalMenu && (
            <>
              <div className="manageCardOptionsHeader">
                <p>Manage Card</p>
                <img src={Close} alt="close" onClick={handleClose} />
              </div>
              <div className="manageCardOptions">
                {manageCardOptions.map((opt, i) => (
                  <div
                    className="manageCardSingleOption"
                    key={i}
                    onClick={opt.fn}
                  >
                    <div>
                      <img src={opt.imgSrc} alt="more" />
                      <p>{opt.title}</p>
                    </div>
                    {opt.check ? (
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round"></span>
                      </label>
                    ) : (
                      <img src={View} alt="view more" />
                    )}
                  </div>
                ))}
              </div>
            </>
          )}
          <div>
            {moreOption.changePin && <CardPinModal handleClose={handleClose} />}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ManageCardModal;
