import "../../styles/createAccount.scss";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { variableManager } from "../../context-api/VariableContex";
import { KycManager } from "../../context-api/KycContext";
import CreatePin from "../modals/security/CreatePin";
import { Spinner } from "react-bootstrap";
import { allstates } from "../../utils/states";

export default function FinishAccountSetupForm() {
  const navigate = useNavigate();
  const {
    handleCompleteSetup,
    success,
    moreDetails,
    setMoreDetails,
    user,
    msg,
  } = useContext(variableManager);
  const {
    showCreate,
    setShowCreate,
    states,
    modalShowCompletesetup,
    setModalShowCompletesetup,
    profile,
    setProfile,
  } = useContext(KycManager);
  const [empty, setEmpty] = useState(false);
  const [filled, setFilled] = useState(false);

  useEffect(() => {
    checkFilledStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    moreDetails.businessAddress,
    moreDetails.state,
    moreDetails.city,
    moreDetails.gender,
    moreDetails.dateOfBirth,
    moreDetails.monthlyRevenue,
    moreDetails.businessCategory,
  ]);

  function handleChange(event) {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    setMoreDetails({
      ...moreDetails,
      [event.target.name]: value,
    });

    // if (
    //   moreDetails.businessAddress !== "" &&
    //   moreDetails.state !== "" &&
    //   moreDetails.gender !== "" &&
    //   moreDetails.city !== "" &&
    //   moreDetails.dateOfBirth !== "" &&
    //   moreDetails.monthlyRevenue !== "" &&
    //   moreDetails.businessCategory !== ""
    // ) {
    //   setFilled(true);
    // }
  }

  function checkFilledStatus() {
    if (
      moreDetails.businessAddress &&
      moreDetails.state &&
      moreDetails.city &&
      moreDetails.gender &&
      moreDetails.dateOfBirth &&
      moreDetails.monthlyRevenue &&
      moreDetails.businessCategory
    ) {
      setFilled(true);
    } else {
      setFilled(false);
    }
  }

  function handleValidation() {
    if (
      moreDetails.businessAddress &&
      moreDetails.state &&
      moreDetails.city &&
      moreDetails.gender &&
      moreDetails.dateOfBirth &&
      moreDetails.monthlyRevenue &&
      moreDetails.businessCategory
    ) {
      handleCompleteSetup();
    } else {
      setEmpty(true);
    }
  }

  return (
    <div className="finish-setup-form-container create-account-container">
      <div className="form-area">
        <div className="content">
          <h2>Finish your Account setup</h2>
          <p className="mantra">
            We make it easy for everyone to onboard and also know that your
            information is safe with us
          </p>
          <div className="input-area">
            <div className="label">
              <label htmlFor="businessCategory">Business category</label>
            </div>
            <div className="input phone">
              <select
                name="businessCategory"
                id="businessCategory"
                onChange={(e) => handleChange(e)}
              >
                {businessCategories.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            {empty && moreDetails.businessCategory === "" && (
              <div className="mo-danger">Business Category is required</div>
            )}
          </div>
          <div className="input-area">
            <div className="label">
              <label htmlFor="monthlyRevenue">Estimated Monthly Revenue</label>
            </div>
            <div className="input phone">
              <select
                name="monthlyRevenue"
                id="monthlyRevenue"
                onChange={(e) => handleChange(e)}
              >
                {revenues.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            {empty && moreDetails.monthlyRevenue === "" && (
              <div className="mo-danger">Monthly Revenue is required</div>
            )}
          </div>
          <div className="input-area">
            <div className="label">
              <label htmlFor="name">Business Address</label>
            </div>
            <div className="input">
              <input
                type="text"
                id="name"
                placeholder="No 5, yoruba street"
                name="businessAddress"
                onChange={(e) => handleChange(e)}
              />
            </div>
            {empty && moreDetails.businessAddress === "" && (
              <div className="mo-danger">Business Address is required</div>
            )}
          </div>

          <div className="input-area">
            <div className="label">
              <label htmlFor="username">City</label>
            </div>
            <div className="input">
              <input
                type="text"
                id="username"
                name="city"
                placeholder="Enter your city"
                onChange={(e) => handleChange(e)}
              />
            </div>
            {empty && moreDetails.city === "" && (
              <div className="mo-danger">City is required</div>
            )}
          </div>

          <div className="input-area">
            <div className="label">
              <label htmlFor="state">State</label>
            </div>
            <div className="input phone">
              <select name="state" id="" onChange={(e) => handleChange(e)}>
                <option value="">Select state</option>
                {allstates.length
                  ? allstates.map((e, i) => {
                      return (
                        <option value={e.name.toLowerCase()} key={i}>
                          {e.name}
                        </option>
                      );
                    })
                  : null}
              </select>
            </div>
            {empty && moreDetails.state === "" && (
              <div className="mo-danger">State is required</div>
            )}
          </div>

          <div className="input-area">
            <div className="label">
              <label htmlFor="phone">Country</label>
            </div>
            <div className="input phone">
              <select
                name="country"
                id="country-code"
                onChange={(e) => handleChange(e)}
              >
                <option value="nigeria"> Nigeria</option>
                <option value="nigeria">United States</option>
              </select>
            </div>
            {empty && moreDetails.country === "" && (
              <div className="mo-danger">Country is required</div>
            )}
          </div>

          <div className="input-area">
            <div className="label">
              <label htmlFor="phone">Director's Gender</label>
            </div>
            <div className="input phone">
              <select
                name="gender"
                id="gender"
                onChange={(e) => handleChange(e)}
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
            {empty && moreDetails.gender === "" && (
              <div className="mo-danger">Gender is required</div>
            )}
          </div>

          {/* <div className="input-area">
            <div className="label">
              <label htmlFor="gender">Director's  Gender</label>
            </div>
            <div className="input">
              <select name="gender" id="gender" onChange={(e) => handleChange(e)}>
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
            {empty && moreDetails.gender === "" && (
              <div className="mo-danger">Gender is required</div>
            )}
          </div> */}

          <div className="input-area">
            <div className="label">
              <label htmlFor="dob">Director's Date of Birth</label>
            </div>
            <div className="input">
              <input
                type="date"
                max={
                  new Date(Date.now() - 16 * 365 * 24 * 60 * 60 * 1000)
                    .toISOString()
                    .split("T")[0]
                }
                // max={new Date(Date.now() - 65 * 365 * 24 * 60 * 60 * 1000).toISOString().split("T")[0]}
                id="dob"
                name="dateOfBirth"
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </div>
            {empty && moreDetails.dateOfBirth === "" && (
              <div className="mo-danger">Date of Birth is required</div>
            )}
          </div>

          <div className="buttons">
            <button
              disabled={msg.spinner}
              className={filled ? "pointer" : "next-btn cursor-disabled"}
              // className={!empty && filled ? "  pointer" : "next-btnr "}
              onClick={() => {
                handleValidation();
              }}
            >
              {msg.spinner ? <Spinner size="sm" /> : "Next"}
            </button>

            <button
              className="skip-btn pointer"
              onClick={() => {
                if (!modalShowCompletesetup) {
                  navigate("/signup/success");
                } else {
                  setModalShowCompletesetup(false);
                }
              }}
            >
              Skip
            </button>
          </div>
        </div>
      </div>

      <CreatePin
        show={showCreate.pin}
        onHide={() => setShowCreate({ ...showCreate, pin: false })}
        backdrop={"static"}
      />
    </div>
  );
}

const businessCategories = [
  {
    name: "Select Business category",
    value: "",
  },
  {
    name: "Business (Goods)",
    value: "Business (Goods)",
  },
  {
    name: "Business (Services)",
    value: "Business (Services)",
  },
];

const revenues = [
  {
    name: "Select estimated revenue",
    value: "",
  },
  {
    name: "1,000-1,000,000",
    value: "1,000-1,000,000",
  },
  {
    name: "1,100,000-5,000,000",
    value: "1,100,000-5,000,000",
  },
  {
    name: "5,100,000-10,000,00",
    value: "5,100,000-10,000,00",
  },
  {
    name: "10,100,000-20,000,00",
    value: "10,100,000-20,000,00",
  },
  {
    name: "20,100,000-50,000,000",
    value: "20,100,000-50,000,000",
  },
  {
    name: "50,100,000-100,000,000",
    value: "50,100,000-100,000,000",
  },
  {
    name: "100,100,000-250,000,000",
    value: "100,100,000-250,000,000",
  },
  {
    name: "250,100,000 & above",
    value: "250,100,000 & above",
  },
];
