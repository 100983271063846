import Modal from "react-bootstrap/Modal";
import kyc from "../../icons/kyc.png";
import "../../styles/modals.scss";
import { useContext } from "react";
import { KycManager } from "../../context-api/KycContext";
import { useNavigate } from "react-router-dom";
import userEvent from "@testing-library/user-event";
import { variableManager } from "../../context-api/VariableContex";
export default function KycPrompt(props) {
  const {
    setModalShowPrompt,
    setModalShowBvn,
    setModalShowCompletesetup,
    setModalShowDocumentUpload,
    modalShowDocumentUpload,
  } = useContext(KycManager);
  const { user } = useContext(variableManager);
  const navigate = useNavigate();

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="modalContent">
        <div className="image">
          <img src={kyc} alt="kyc" />
        </div>
        <h2>Complete your Account Setup</h2>
        <p className="mantra">
          Your Mongoro account is almost ready to go. Kindly complete your KYC
          Verification
        </p>
        <div className="buttons">
          <button
            className="btn-active"
            onClick={(e) => {
              e.preventDefault();
              setModalShowPrompt(false);
              // setModalShowBvn(true);
              if (!user.setupComplete) {
                // navigate("/signup/finish-setup");
                setModalShowCompletesetup(true);
                setModalShowPrompt(false);
              } else if (user.setupComplete && user.document === 0) {
                setModalShowPrompt(false);
                setModalShowDocumentUpload({
                  ...modalShowDocumentUpload,
                  upload: true,
                }); 
              } else if (
                user.setupComplete &&
                user.document &&
                !user.verificationBvn
              ) {
                setModalShowPrompt(false);
                setModalShowBvn(true);
              }
            }}
          >
            Get Started
          </button>
          <button
            className="disabled-button"
            onClick={() => setModalShowPrompt(false)}
          >
            Skip
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

// function App() {
//   const [modalShow, setModalShow] = React.useState(false);

//   return (
//     <>
//       <Button variant="primary" onClick={() => setModalShow(true)}>
//         Launch vertically centered modal
//       </Button>

//       <MyVerticallyCenteredModal
//         show={modalShow}
//         onHide={() => setModalShow(false)}
//       />
//     </>
//   );
// }

// render(<App />);
