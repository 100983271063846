export default function FilterBy() {
  return (
    <div
      className="filter-container"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <header>FILTER BY</header>

      <div className="by-time">
        <div className="active-time">3 hours</div>
        <div className="time">6 hours</div>
        <div className="time">12 hours</div>
        <div className="time">24 hours</div>
        <div className="time">7days</div>
        <div className="time">30 days</div>
      </div>

      <div className="by-type">
        <div className="item">
          <input type="checkbox" id="allDeposit" />
          <label htmlFor="allDeposit">All Deposit</label>
        </div>{" "}
        <div className="item">
          <input type="checkbox" id="allTransfer" />
          <label htmlFor="allTransfer">All Transfer</label>
        </div>{" "}
        <div className="item">
          <input type="checkbox" id="allWithdrawal" />
          <label htmlFor="allWithdrawal">All Withdrawal</label>
        </div>
      </div>

      <div className="button w-75 my-4">
        <button className="mo-active-btn"> Filter</button>
      </div>
    </div>
  );
}
