/* eslint-disable no-use-before-define */
import axios from "axios";
import axiosRetry from "axios-retry";
import { createContext, useContext, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { KycManager } from "./KycContext";
import {
  osName,
  osVersion,
  BrowserView,
  browserName,
  browserVersion,
} from "react-device-detect";
import axiosInstance from "../utils/axios_instance";
import { karmaLoba } from "../service_workers/KickTrx";

export const variableManager = createContext();

export default function VariableContext({ children }) {
  const navigate = useNavigate();
  const [success, setSuccess] = useState({ register: false, message: "" });
  const [btnPay, setBtnPay] = useState(false);
  const [user, setUser] = useState("");
  const url = process.env.REACT_APP_BASE_URL;
  const [userDetails, setUserDetails] = useState({
    businessName: "",
    directorFirstName: "",
    walletTag: "",
    directorMiddleName: "",
    directorLastName: "",
    phone: 0,
    password: "",
    email: "",
    passwordRepeat: "",
    walletTag: "",
    agreement: false,
    countryCode: "+234",
  });

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  //SHOW/HIDE PASSWORD
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirm: false,
  });

  //UNKNOWN USER
  const [unknownUser, setUnknownUser] = useState({ status: false, email: "" });

  //CHECKING USERNAME STATUS
  const [usernameStatus, setUsernameStatus] = useState({
    checking: false,
    status: false,
    success: "",
    error: "",
  });

  const [moreDetails, setMoreDetails] = useState({
    businessAddress: "",
    country: "nigeria",
    state: "",
    city: "",
    gender: "",
    dateOfBirth: "",
    monthlyRevenue: "",
    businessCategory: "",
  });

  // SENDING TO USERNAME DETAILS
  const [walletTag, setwalletTag] = useState("");

  const [mongoroReceiver, setMongoroReceiver] = useState({
    amount: "",
    walletTag: "",
    serviceType: "Payment",
    narration: "",
    pin: "",
    saveBeneficiary: "",
  });

  //   {
  //     "amount": "1000",
  //     "walletTag": "sage",
  //     "serviceType": "Payment",
  //     "narration": "wjekmneerjidslk,menwjsk",
  //     "pin":"123456"
  // }

  const [code, setCode] = useState({ email: "", sms: "" });

  // TO HANDLE LOGIN
  const [loginBody, setLoginBody] = useState({
    email: "",
    password: "",
    location: "",
    ip: "",
    device: `${browserName} ${browserVersion} on ${osName} ${osVersion}`,
  });
  const [msg, setMsg] = useState({
    spinner: false,
    toast: false,
    toastBody: "",
    error: "",
    success: "",
  });
  const [name, setName] = useState({ first: "", last: "", token: "" }); // MODIFY NAME CASES
  // LOGIN ACTIVITIES
  const [activities, setActivities] = useState([]);

  //PAYMENT
  const [bankList, setBankList] = useState([]);
  const [accountDetails, setAccountDetails] = useState({
    accountNumber: "",
    accountBank: "",
    bankName: "",
    amount: 0,
    saveBeneficiary: false,
    accountName: "",
    narration: "",
    currency: "NGN",
    callbackUrl: "http://localhost:3000/payment/success",
    debitCurrency: "NGN",
    pin: "",
  });

  //USER'S NOTIFICATIONS
  const [notifications, setNotifications] = useState([]);
  const [notificationStatus, setNotificationStatus] = useState({
    seen: "",
    unseen: "",
  });
  //INSIGHTS
  const [insights, setInsights] = useState({});

  const [singleNotification, setSingleNotification] = useState({});
  const [readNotification, setReadNotification] = useState(false);

  //FROM KYC CONTEXT
  //FROM KYC CONTEXT
  //FROM KYC CONTEXT

  const {
    payModalShow,
    setPayModalShow,
    setShowVeriSuccess,
    showVeriSuccess,
    setModalShowBvn,
    toverify,
    modalShowCompletesetup,
    setModalShowCompletesetup,
    setModalShowAccount,
    setModalShowAccountType,

    setModalShowPassport,
    setModalShowNin,
    setModalShowDriversLicense,
    setModalShowPvc,
    setShowIndemnity,

    showPos,
    setshowPos,

    rerender,
    setRerender,
    profile,
    setProfile,
    setShowCreate,
    showCreate,
    setModalShowPrompt,

    exportStatement,
    setExportStatement,

    //SUPPORT
    showSupport,
    setShowSupport,
  } = useContext(KycManager);

  //DASHBOARD COMPONENTS DATA
  const [dashboard, setDashboard] = useState({
    totalTransactions: "",
    history: [],
  });

  const [accounts, setAccounts] = useState({
    history: "",
  });

  //TICKETS
  const [tickets, setTickets] = useState({ all: [], single: {}, refreshed: 0 });

  //USER'S ADDED BANKS
  const [userBank, setUserbank] = useState([]);

  //IMAGE UPLOADS CONTROLLERS
  const [image, setImage] = useState({ toDisplay: "", image: "" });

  //POS ORDER DETAILS
  const [posRequestDetails, setPosRequestDetails] = useState({
    businessName: "",
    type: "amal",
    phone: "",
    quantity: "",
    address: "",
    state: "",
    city: "",
    country: "nigeria",
    termsAndConditions: true,
    countryCode: "+234",
  });

  ///KICKS
  const [kick, setKick] = useState(null);
  const [kickError, setKickError] = useState(null);

  ///INVOICES
  const [invoices, setInvoices] = useState([]);
  const [showResolveInvoice, setShowResolveInvoice] = useState(false);

  //EXPORTED DATA
  const exportData = {
    isLoggedIn,
    //SHOW/HIDE PASSWORD
    showPassword,
    setShowPassword,
    //UNKNOWN USER
    unknownUser,
    setUnknownUser,

    //CHECKING USERNAME AVAILABILITY
    usernameStatus,
    setUsernameStatus,
    walletTag,
    setwalletTag,

    btnPay,
    setBtnPay,
    user,
    setUser,
    url,
    userDetails,
    setUserDetails,
    code,
    setCode,
    handleRegister,
    success,
    setSuccess,
    handleCompleteSetup,
    moreDetails,
    setMoreDetails,
    handleLogin,
    handleLogout,
    loginBody,
    setLoginBody,
    handleGetUser,
    msg,
    setMsg,
    handleModifyNames,
    name,

    bankList,
    handleBankList,
    accountDetails,
    setAccountDetails,
    handleValidateBankAccount,
    handleSendtoBank,

    mongoroReceiver,
    setMongoroReceiver,
    handleSendtoUser,
    handleTransactionHistory,

    //DASHBOARD COMPONENTS
    dashboard, //object of history,
    accounts,
    nameCase,
    notifications,
    handleReadNotification,
    handleDeleteNotification,
    handleGetUnreadNotifications,
    handleGetReadNotifications,
    handleNotifications, //GET ALL NOTIFICATIONS
    singleNotification,
    readNotification,
    setReadNotification,
    notificationStatus,
    handleSettings,

    //IMAGE UPLOADS CONTROLLERS
    handleImageChange,
    image,
    setImage,

    //SENDING EMAIL AND SMS CODES
    handleVerification,

    //GETTING AND DELETING ACTIVITY
    handleLoginActivity,
    activities,
    setActivities,

    //USER'S ADDED BANKS
    userBank,
    setUserbank,
    handleGetUserBanks,

    //BVN VERIFICATION
    handleVerifyBvn,

    //ID VERIFICATION
    handleIdentityUpload,

    //BENEFICIARIES
    handleDeleteBeneficiary,

    //FORMAT AND/OR VALIDATE AMOUNT
    handleFormatAmount,
    handleValidateBalance,
    handleMutateUsername, //Remove @ from username

    //ORDER POS
    posRequestDetails,
    setPosRequestDetails,
    handleOrderPOS,

    //BANK STATEMENT
    handleGenerateStatement,

    //INSIGHTS
    insights,
    handleInsights,

    //SUPPORT
    handleCreateTicket,
    handleGetTickets,
    handleCloseTicket,
    tickets,
    setTickets,

    ///KICKS AND TRANSACTIONS
    kickError,
    setKickError,
    kick,
    setKick,

    //INvoices
    invoices,
    getInvoices,
    showResolveInvoice,
    setShowResolveInvoice,
  };

  // axiosInstance.interceptors.request.use(
  //   function (config) {
  //     setMsg({ ...msg, spinner: true });
  //     return config;
  //   },
  //   function (error) {
  //     return Promise.reject(error);
  //   }
  // );

  axiosInstance.interceptors.response.use(
    (response) => {
      setMsg({ ...msg, spinner: false });
      return response;
    },
    (error) => {
      setMsg({ ...msg, spinner: false });
      return Promise.reject(error);
    }
  );

  // const { kick, kickError } = useContext(variableManager);

  useEffect(() => {
    // const localUser = localStorage.getItem("mongoro_user");
    const localUser = localStorage.getItem("mg-u-tkn");
    // const localToken = localStorage.getItem("mg-u-tkn");
    if (localUser) {
      setIsLoggedIn(true);
      handleGetUser();
      // setMoreDetails({ ...moreDetails, id: localUser });
      // handleGetUserBanks(localUser);

      handleNotifications();
      handleLoginActivity("get");
      handleTransactionHistory();
      handleBankList();
    }
  }, []);

  if (rerender === true) {
    handleGetUser();
  }
  function handleImageChange(e) {
    setMsg({ ...msg, spinner: false, error: "", success: "" });
    setImage({
      ...image,
      toDisplay: URL.createObjectURL(e.target.files[0]),
      image: e.target.files[0],
    });
  }

  async function handleRegister() {
    setSuccess({ ...success, register: false, message: "" });
    setMsg({ ...msg, spinner: true, error: "", success: "" });

    try {
      await axiosInstance
        .post(`/auth/register`, userDetails)
        .then(async (resp) => {
          const data = resp.data;
          setMsg({ ...msg, spinner: false, error: "", success: "" });

          // if (resp.status === 200) {
          console.log("success block", resp);
          localStorage.removeItem("mg-u-tkn");
          localStorage.removeItem("mongoro_user");

          localStorage.setItem("mg-u-tkn", data.data.token);
          axiosInstance.defaults.headers.common["x-token"] = data.data.token;

          setSuccess({ ...success, register: true, message: resp.data.msg });
          setMoreDetails({ ...moreDetails, id: resp.data.data.uid }); //Get Id from created user to use in updating further setup
          navigate("/signup/finish-setup");
          await axiosInstance
            .post(`/auth/verified`, { email: userDetails.email })
            .then((resp) => {});
          // } else {
          //   setSuccess({
          //     ...success,
          //     register: false,
          //     message: resp.response.data.message,
          //   });
          // }
        });
    } catch (error) {
      setMsg({ ...msg, spinner: false, error: "", success: "" });
      setSuccess({
        ...success,
        register: false,
        message: error?.response?.data?.message,
      });

      // const msg = error.response.data.message;
      setSuccess({
        ...success,
        register: false,
        message: error.response.data.message,
      });
      if (error.response.status === 404) {
        setMsg({ ...msg, error: error.response.data.message });
        setUsernameStatus({ ...usernameStatus, status: true });
        navigate("/signup");
      } else if (error.response.status === 400) {
        setMsg({ ...msg, error: error.response.data.message });
        setUsernameStatus({ ...usernameStatus, status: false });
        navigate("/signup");
      }
    }
  }

  async function handleCompleteSetup() {
    setSuccess({ ...success, register: false, message: "" });
    setMsg({ ...msg, spinner: true, error: "", success: "" });

    if (user) {
      // setMoreDetails({ ...moreDetails, id: user._id });
      try {
        setMsg({ ...msg, spinner: true, error: "", success: "" });

        await axiosInstance.put(`/business/setup`, moreDetails).then((resp) => {
          if (resp.status === 200) {
            setSuccess({
              ...success,
              register: true,
              message: resp.data.message,
            });
            if (modalShowCompletesetup) {
              setProfile({ ...profile, imageUpload: true });
              setModalShowCompletesetup(false);
            } else {
              navigate("/signup/uploads");
            }
          } else {
            setSuccess({
              ...success,
              register: false,
              message: resp.response.data.message,
            });
          }
        });
      } catch (error) {
        console.log(error);
        setMsg({ ...msg, spinner: false, error: "", success: "" });

        const message = error.response.data.message;
        setSuccess({ ...success, register: false, message: message });
        console.log(message);
      }
    } else {
      console.log("completing setup if no user");
      try {
        setMsg({ ...msg, spinner: true, error: "", success: "" });

        await axiosInstance.put(`/business/setup`, moreDetails).then((resp) => {
          if (resp.status === 200) {
            setSuccess({
              ...success,
              register: true,
              message: resp.data.message,
            });
            setMsg({ ...msg, spinner: false, error: "", success: "" });

            if (modalShowCompletesetup) {
              console.log("user and profile");
              console.log(profile);
              setProfile({ ...profile, imageUpload: true });
              setModalShowCompletesetup(false);
            } else {
              navigate("/signup/uploads");
            }
          } else {
            setSuccess({
              ...success,
              register: false,
              message: resp.response.data.message,
            });
          }
        });
      } catch (error) {
        setMsg({ ...msg, spinner: false, error: "", success: "" });

        const message = error.response.data.message;
        setSuccess({ ...success, register: false, message });
        console.log(message);
      }
    }
  }
  async function handleSettings(payload) {
    setMsg({ ...msg, spinner: true });
    try {
      await axiosInstance.put(`business/edit`, payload).then((resp) => {
        handleGetUser();
        setMsg({ ...msg, spinner: false });
        console.log(resp.data);
      });
    } catch (error) {
      console.log(error);
      setMsg({ ...msg, spinner: false });
    }
  }
  async function handleLogin() {
    setMsg({ ...msg, spinner: true, error: "", success: "" });
    // const userHistory = useHistory()

    try {
      await axiosInstance.post(`/auth/login`, loginBody).then((resp) => {
        const data = resp.data.data;
        if (data) {
          setIsLoggedIn(true);
          setMsg({
            ...msg,
            success: "Login Successful",
            spinner: false,
            error: "",
          });
          setUser(data);
          localStorage.clear();

          localStorage.setItem("mg-u-tkn", data.token);
          // handleLoginActivity("add");

          setTimeout(() => {
            setMsg({ ...msg, success: "", spinner: false, error: "" });
            navigate("/user/dashboard");
          }, 2000);

          if (!data.pin) {
            setShowCreate({ ...showCreate, pin: true });
          } else if (
            data.pin &&
            (!data.setupComplete || !data.verificationBvn)
          ) {
            setModalShowPrompt(true);
          }
        }
      });
    } catch (error) {
      console.log(error);
      setMsg({
        ...msg,
        error: error.response ? error.response.data.message : "",
        spinner: false,
      });
      setTimeout(() => {
        setMsg({ ...msg, success: "", spinner: false, error: "" });
      }, 5000);
    }
  }

  async function handleGetUser() {
    try {
      await axiosInstance.get("/business/single").then((resp) => {
        const data = resp.data.data;
        setIsLoggedIn(true);
        setUser(data);
        // handleModifyNames(resp.data[0]);
        // setName((prev) => {
        //   return { ...prev, token: token };
        // });

        if (data && !data.pin) {
          setShowCreate({ ...showCreate, pin: true });
        } else if (data.pin && (!data.setupComplete || !data.verificationBvn)) {
          // setModalShowPrompt(true);
        }

        setRerender(false);
      });
    } catch (error) {
      console.log(error);
      setRerender(false);
      if (error.response.status === 401) {
        setIsLoggedIn(false);
        handleLogout();
      }

      if (error.code === "ERR_NETWORK") {
        //INFORM USER THEY HAVE A POOR NETWORK
      }
    }
  }

  async function handleBankList() {
    try {
      await axiosInstance.get(`business/all/banks`).then((resp) => {
        setBankList(resp.data.data);
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function handleValidateBankAccount(accountNumber) {
    setMsg({ ...msg, spinner: true });
    try {
      await axiosInstance
        .post(`business/verify/bank`, {
          accountNumber: accountNumber,
          accountBank: accountDetails.accountBank,
        })
        .then((resp) => {
          setMsg({ ...msg, spinner: false });
          // console.log(resp.data)
          setAccountDetails({
            ...accountDetails,
            accountNumber: accountNumber,
            accountName: nameCase(resp.data.data.data.account_name),
          });
        });
    } catch (error) {
      setMsg({
        ...msg,
        spinner: false,
        error: "Name cannot be verified at the moment",
      });
      console.log(error);
    }
  }

  // const resp =  getKick().then((key)=>{
  //   return key
  // }).catch((error)=>{
  //   return error
  // })
  // console.log(resp)

  async function handleSendtoBank() {
    setMsg({ ...msg, error: "", success: "" });
    try {
      await axiosInstance.get("/business/transaction/token").then((resp) => {
        const innerStarter = resp.data.data.trxToken;
        transactNow(innerStarter);
      });
    } catch (error) {
      setPayModalShow({
        ...payModalShow,
        toBank: false,
        paySuccess: false,
        payFail: true,
        transaction: {
          msg: "Unknown server error, please contact support",
        },
      });
    }

    async function transactNow(karmaKick) {
      try {
        setMsg({ ...msg, spinner: true });
        await axiosInstance
          .post(`business/transaction/bank`, accountDetails, {
            headers: {
              ...axiosInstance.defaults.headers.common,
              ...karmaLoba(karmaKick),
            },
          })
          .then((resp) => {
            setMsg({ ...msg, spinner: false, success: resp.data.msg });

            if (accountDetails.saveBeneficiary) {
              handleSaveBeneficiary("bank");
            }

            if (resp.data.status) {
              const response = resp.data.data;
              setPayModalShow({
                ...payModalShow,
                toBank: false,
                paySuccess: true,
                transaction: {
                  id: response.tid,
                  ref: response.reference,
                },
              });
              // navigate("/payment/success")
              handleGetUser();
              handleTransactionHistory();
              handleInsights();
              handleNotifications();
            }
          });
      } catch (error) {
        setMsg({ ...msg, spinner: false, error: error.response.data.message });

        if (error.response.data.status === 404) {
          setPayModalShow({
            ...payModalShow,
            toBank: false,
            paySuccess: false,
            payFail: false,
            insufficient: true,
            transaction: {
              id: error.response.data.id,
              ref: error.response.data.reference,
            },
          });
        } else if (
          error.response.data.status === 400 ||
          error.response.data.status === 401
        ) {
          setPayModalShow({
            ...payModalShow,
            toBank: false,
            paySuccess: false,
            payFail: true,
            transaction: {
              id: error.response.data.id,
              ref: error.response.data.reference,
              msg: error.response.data.message,
            },
          });
        }

        setTimeout(() => {
          setMsg({ ...msg, spinner: false, error: "" });
        }, 3000);
      }
    }
  }

  async function handleSendtoUser() {
    setMsg({ ...msg, error: "", success: "" });
    try {
      await axiosInstance.get("/business/transaction/token").then((resp) => {
        const innerStarter = resp.data.data.trxToken;
        transactNow(innerStarter);
      });
    } catch (error) {
      setPayModalShow({
        ...payModalShow,
        toBank: false,
        payNow: false,
        paySuccess: false,
        payFail: true,
        transaction: {
          msg: "Unknown server error, please contact support",
        },
      });
    }

    async function transactNow(karmaKick) {
      const payLoad = {
        ...mongoroReceiver,
        walletTag: handleMutateUsername(mongoroReceiver.walletTag),
      };

      setMsg({ ...msg, spinner: true });

      try {
        await axiosInstance
          .post(`business/transaction/mongoro`, payLoad, {
            headers: {
              ...axiosInstance.defaults.headers.common,
              ...karmaLoba(karmaKick),
            },
          })
          .then((resp) => {
            setMsg({ ...msg, spinner: false, success: resp.data.message });

            if (resp.data.status) {
              const response = resp.data.data;
              setPayModalShow({
                ...payModalShow,
                toBank: false,
                payNow: false,
                paySuccess: true,
                transaction: {
                  id: response.transactionID,
                  ref: response.reference,
                },
              });
              // navigate("/payment/success")
            } else if (!resp.data.status) {
              setMsg({ ...msg, error: resp.data.message });
            }

            if (payLoad.saveBeneficiary) {
              handleSaveBeneficiary("mongoro", payLoad);
            }

            handleGetUser();
            handleTransactionHistory();
            handleInsights();
            handleNotifications();
          });
      } catch (error) {
        console.log(error);
        if (error.code === "ERR_NETWORK") {
          return setPayModalShow({
            ...payModalShow,
            payNow: false,
            payFail: true,
            transaction: {
              network_err:
                "Network Error. Kindly check your network and try again.",
              ref: "0000xxx",
            },
          });
        }
        setMsg({ ...msg, spinner: false });
        const response = error.response.data;
        if (response) {
          if (response.msg === "Insufficient funds") {
            setPayModalShow({
              ...payModalShow,
              toUsername: false,
              payNow: false,
              insufficient: true,
              transaction: {
                id: response.transactionID,
                ref: response.reference,
              },
            });
          } else {
            setPayModalShow({
              ...payModalShow,
              payNow: false,
              payFail: true,
              transaction: {
                id: response.transactionID,
                ref: response.reference,
                msg: response.message || response.msg,
              },
            });
          }
        } else {
          setPayModalShow({
            ...payModalShow,
            payNow: false,
            payFail: true,
            transaction: {
              id: response.transactionID,
              ref: response.reference,
              msg: response.message || response.msg,
            },
          });
        }
      }
    }
  }
  async function handleSaveBeneficiary(type, payload) {
    if (type === "mongoro") {
      console.log(payload);
      await axiosInstance
        .post(`business/beneficiary/mongoro`, {
          walletTag: handleMutateUsername(payload.walletTag),
          accountBank: "Mongoro",
        })
        .then(() => {
          handleGetUser();
        });
    } else if (type === "bank") {
      await axiosInstance
        .post(`business/beneficiary/bank`, {
          accountNumber: accountDetails.accountNumber,
          accountBank: accountDetails.accountBank,
          beneficiaryName: accountDetails.accountName,
          bankName: accountDetails.bankName,
        })
        .then(() => {
          handleGetUser();
        });
    }
  }
  function handleMutateUsername(username) {
    if (username.charAt(0) === "@") {
      return username.slice(1);
    } else {
      return username;
    }
  }
  async function handleDeleteBeneficiary(id) {
    setMsg({ ...msg, spinner: true });

    await axiosInstance
      .delete(`business/beneficiary/delete`, { data: { beid: id } })
      .then(() => {
        handleGetUser();
        setMsg({ ...msg, spinner: false });
      });
  }
  function handleLogout() {
    setUser("");
    setInsights({});
    localStorage.clear();
    navigate("/login", { undefined, replace: true });
    setIsLoggedIn(false);
  }

  function handleModifyNames(user) {
    if (user.firstName) {
      const fnameStart = user.firstName.charAt(0).toUpperCase();
      const fnameRest = user.firstName.substring(1).toLowerCase();

      const mnameStart = user.middleName.charAt(0).toUpperCase();
      const mnameRest = user.middleName.substring(1).toLowerCase();

      const lnameStart = user.surname.charAt(0).toUpperCase();
      const lnameRest = user.surname.substring(1).toLowerCase();

      const firstName = fnameStart + fnameRest;
      const lastName = lnameStart + lnameRest;
      const midName = mnameStart + mnameRest;

      setName({
        ...name,
        first: firstName,
        last: lastName,
        mid: midName,
        // token: token,
      });
    }
  }

  // async function handleTransactionHistory() {
  //   console.log("getting history")
  //   try{
  //     const transactions = await axiosInstance.get(`business/transaction/user`);
  //     console.log(transactions)
  //     setDashboard({ ...dashboard, history: transactions ? transactions.data.data.data : []});
  //   }
  //   catch{}
  // }

  async function handleVerification(details) {
    setCode({ ...code, email: "", sms: "" });

    try {
      await axiosInstance
        .post(
          `/auth/verify`,
          details || { email: user.email, phone: user.phone }
        )
        .then((resp) => {
          if (resp.status === 200) {
            setCode({
              ...code,
              email: resp.data.data.code,
              sms: resp.data.data.code,
            });
          } else {
            setMsg({ ...msg, error: "network error. reload and try again" });
          }
        });
    } catch (error) {
      setMsg({ ...msg, error: error.response.data.message });
    }
  }

  async function handleTransactionHistory(pageNumber) {
    try {
      const transactions = await axiosInstance.get(
        `business/transaction/business?page=${pageNumber}`
      );
      setDashboard({
        ...dashboard,
        totalTransactions: transactions ? transactions.data.data.total : "",
        history: transactions ? transactions.data.data.data : [],
      });
    } catch (error) {
      console.error("Error fetching transaction history:", error);
    }
  }

  async function handleVerification(details) {
    setCode({ ...code, email: "", sms: "" });

    try {
      await axiosInstance
        .post(
          `/auth/verify`,
          details || { email: user.email, phone: user.phone }
        )
        .then((resp) => {
          if (resp.status === 200) {
            setCode({
              ...code,
              email: resp.data.data.code,
              sms: resp.data.data.code,
            });
          } else {
            setMsg({ ...msg, error: "network error. reload and try again" });
          }
        });
    } catch (error) {
      setMsg({ ...msg, error: error.response.data.message });
    }
  }

  async function handleLoginActivity(type, id) {
    if (type === "get") {
      try {
        await axiosInstance
          .get(`business/audit/login/activity`)
          .then((resp) => {
            const data = resp.data.data;
            setActivities(data);
          });
      } catch (error) {
        console.log(error.response.data);
      }
    } else if (type === "delete") {
      //!done
      setMsg({ ...msg, spinner: true, error: "", success: "" });
      try {
        axiosInstance.delete(`business/audit/${id}`).then((resp) => {
          handleLoginActivity("get");
          setMsg({ ...msg, spinner: false, success: resp.data.msg });

          setTimeout(() => {
            setMsg({ ...msg, spinner: false, success: "" });
          }, 1000);

          setProfile({
            ...profile,
            deletePrompt: {
              ...profile.deletePrompt,
              device: false,
              bank: false,
            },
          });
        });
      } catch (error) {
        console.log(error.response.data);
        setMsg({ ...msg, error: error.response.data.message, success: "" });
      }
    } else if (type === "add") {
      console.log("adding activity");
      try {
        await axiosInstance
          .post(`user/audit/login`, {
            device: `${browserName} ${browserVersion} on ${osName} ${osVersion}`,
            message: "login",
          })
          .then((resp) => {
            console.log(resp);
          });
      } catch (error) {
        console.log(error);
      }
    }
  }

  function handleGetUserBanks(id) {
    try {
      axiosInstance.get(`${url}/bank/user/${id}`).then((resp) => {
        setUserbank(resp.data.bank);
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function handleGenerateAccount() {
    setMsg({ ...msg, spinner: true });
    try {
      await axiosInstance.post(`business/account`).then((response) => {
        console.log(response.data);
        setMsg({ ...msg, spinner: false });

        setModalShowAccount(true);

        // if (response.data.data.success) {
        //   setModalShowBvn(false);
        //   setShowVeriSuccess({ ...showVeriSuccess, bvnSuccess: true });
        // } else {
        //   setShowVeriSuccess({
        //     ...showVeriSuccess,
        //     error: response.data.message,
        //   });
        // }
      });
    } catch (error) {
      console.log(error.response.data.message);
      setMsg({ ...msg, spinner: false });
      // setShowVeriSuccess({
      //   ...showVeriSuccess,
      //   error:
      //     error.response.data.message ||
      //     "Invalid credentials. please contact support",
      // });
    }
    // }
  }

  async function handleVerifyBvn() {
    setMsg({ ...msg, spinner: true });
    setShowVeriSuccess({ ...showVeriSuccess, error: "" });

    const userData = {
      b_id: toverify.bvn,
    };

    try {
      await axiosInstance.post(`business/verify`, userData).then((response) => {
        if (response.data.status) {
          // handleGenerateAccount();
          setModalShowAccountType(true);
          setMsg({ ...msg, spinner: false });
          setModalShowBvn(false);
          setShowVeriSuccess({ ...showVeriSuccess, bvnSuccess: true });

          //get user again
          // setRerender(true)
          handleGetUser();
        } else {
          setShowVeriSuccess({
            ...showVeriSuccess,
            error: response.data.message,
          });
        }
      });
    } catch (error) {
      console.log(error);
      // console.log(error.response.data.message);
      setMsg({ ...msg, spinner: false });

      setShowVeriSuccess({
        ...showVeriSuccess,
        error:
          error.response.data.message ||
          "Invalid credentials. please contact support",
      });
    }
  }
  function nameCase(names) {
    if (!names) return;
    names = names.toLowerCase().split(" ");

    for (let i = 0; i < names.length; i++) {
      names[i] = names[i].charAt(0).toUpperCase() + names[i].slice(1);
    }
    return names.join(" ");
  }
  async function handleIdentityUpload(payload, type) {
    setMsg({ ...msg, spinner: true });
    const formData = new FormData();
    formData.append("file", image.image);
    formData.append("upload_preset", "identity-documents");

    if (type !== "indemnity") {
      try {
        await axios
          .post(
            `${process.env.REACT_APP_CLOUDINARY_URL}/${process.env.REACT_APP_CLOUDINARY_USERNAME}/image/upload`,
            formData
          )
          .then(async (resp) => {
            try {
              await axiosInstance
                .post(`business/kyc/submit`, {
                  ...payload,
                  image: resp.data.secure_url,
                  type: type,
                })
                .then((resp) => {
                  setModalShowPassport(false);
                  setModalShowNin(false);
                  setModalShowDriversLicense(false);
                  setModalShowPvc(false);
                  setShowVeriSuccess({ ...showVeriSuccess, idDoc: true });
                  setMsg({ ...msg, spinner: false });
                  handleGetUser();
                });
            } catch (error) {
              setMsg({ ...msg, spinner: false });
              setModalShowPassport(false);
              setModalShowNin(false);
              setModalShowDriversLicense(false);
              setModalShowPvc(false);
              setShowVeriSuccess({
                ...showVeriSuccess,
                idDoc: true,
                error: true,
              });
            }
          });
      } catch (error) {}
    } else {
      await axiosInstance.post(`business/tier`).then((data) => {
        setMsg({ ...msg, spinner: false });
        setShowIndemnity(false);
        setShowVeriSuccess({
          ...showVeriSuccess,
          idDoc: true,
          indemnity: true,
        });
        handleGetUser();
      });
    }
  }
  async function handleNotifications() {
    try {
      await axiosInstance.get(`business/notifications/all`).then((resp) => {
        setNotifications(resp.data.data);
      });
    } catch (error) {
      console.log(error);
    }
  }
  async function handleReadNotification(id) {
    setMsg({ ...msg, spinner: true });
    try {
      await axiosInstance
        .get(`business/notifications/read/${id}`)
        .then((resp) => {
          // setSingleNotification(resp.data.notification[0]);
          handleNotifications();
          handleGetUnreadNotifications();
          handleGetReadNotifications();
          setMsg({ ...msg, spinner: false });
        });
    } catch (error) {
      setMsg({ ...msg, spinner: false });
    }
  }
  async function handleGetUnreadNotifications() {
    setMsg({ ...msg, spinner: true });
    try {
      await axiosInstance.get(`business/notifications/unread`).then((resp) => {
        setNotificationStatus({
          ...notificationStatus,
          unseen: resp.data.data.notifications,
        });
        setMsg({ ...msg, spinner: false });
      });
    } catch (error) {
      setMsg({ ...msg, spinner: false });
    }
  }
  async function handleGetReadNotifications() {
    setMsg({ ...msg, spinner: true });
    try {
      await axiosInstance.get(`business/notifications/read/`).then((resp) => {
        setNotificationStatus({
          ...notificationStatus,
          seen: resp.data.data.notifications,
        });

        setMsg({ ...msg, spinner: false });
      });
    } catch (error) {
      setMsg({ ...msg, spinner: false });
      console.log(error);
    }
  }

  async function handleDeleteNotification(id) {
    const header = { headers: { token: `Bearer ${name.token}` } };
    setMsg({ ...msg, spinner: true });

    try {
      await axiosInstance
        .delete(`${url}/notification/delete/${id}`, header)
        .then((resp) => {
          console.log(resp.data);
          handleNotifications();
          handleGetUnreadNotifications();
          handleGetReadNotifications();
          setMsg({ ...msg, spinner: false });
        });
    } catch (error) {
      console.log(error.response.data);
      setMsg({ ...msg, spinner: false });
    }
  }

  function handleFormatAmount(value) {
    if (!value) return ""; //empty value would break the app so we dont proceed if value is empty

    // Split the value into integer and decimal parts
    const [integerPart, decimalPart] = value.split(".");

    // Format the integer part with commas
    const formattedIntegerPart = integerPart
      .replace(/\D/g, "") //this removes all non-number characters
      .replace(/\B(?=(\d{3})+(?!\d))/g, ","); //this add the commas in appropriate places

    // Combine the integer and decimal parts to create the formatted value
    let formattedValue = formattedIntegerPart;
    if (decimalPart !== undefined) {
      formattedValue += "." + decimalPart.slice(0, 2); //this is checking whether there's a decimal part, it then joins them together and also approximate to 2 decimal places
    }

    return formattedValue; //whenever you call this function and pass in a parameter(the number you want to format),
    //it returns the formatted number. e.g handleFormatAmount(43213.08) would return 43,213.08
  }

  function handleValidateBalance(amount, user) {
    if (!user) return false;
    const currentBalance = parseFloat(user.walletBalance);
    amount = parseFloat(amount);

    if (amount > currentBalance) {
      return false;
    } else {
      return true;
    }
  }

  async function handleOrderPOS() {
    setMsg({ ...msg, spinner: true });
    try {
      await axiosInstance
        .post(`business/mpos/request`, posRequestDetails)
        .then((resp) => {
          setMsg({ ...msg, spinner: false });
          setshowPos({
            ...showPos,
            terms: false,
            request: false,
            request_success: true,
          });
        });
    } catch (error) {
      setMsg({ ...msg, spinner: false });

      console.log(error);
      if (!error.response || error.code === "ERR_NETWORK") {
        handleLogout();
      }
    }
  }
  async function handleGenerateStatement(payload) {
    console.log(payload);
    setMsg({ ...msg, spinner: true });

    try {
      await axiosInstance
        .post(`business/statement/transaction`, payload)
        .then((resp) => {
          setMsg({ ...msg, spinner: false });

          console.log(resp.data);
          setExportStatement({
            ...exportStatement,
            request: false,
            success: true,
            failed: false,
          });
        });
    } catch (error) {
      setMsg({ ...msg, spinner: false });
      console.log(error);
      if (error.code === "ERR_NETWORK") {
        setExportStatement({
          ...exportStatement,
          failed: true,
          request: false,
          error:
            "Unable to generate statement due to network error. Kindly check your internet connection and try again",
        });
      } else if (error.response.code === 401) {
        handleLogout();
      } else {
        setExportStatement({
          ...exportStatement,
          failed: true,
          request: false,
          error: "",
        });
      }
    }
  }

  async function handleInsights() {
    try {
      await axiosInstance.get(`/business/transaction/insight`).then((resp) => {
        setInsights(resp.data.data);
      });
    } catch (error) {
      console.log(error);
    }
  }
  async function handleGetTickets() {
    await axiosInstance.get("/business/ticket/user").then((resp) => {
      // console.log(resp)
      const data = resp.data.data;
      if (data) {
        setTickets({
          ...tickets,
          all: resp.data.data,
          refreshed: tickets.refreshed + 1,
        });
      }
    });
  }
  async function handleCreateTicket(payload) {
    setMsg({ ...msg, spinner: true });
    const formdata = new FormData();
    if (payload.docs) {
      payload.docs.fileName = "user's file";
      formdata.append("file", payload.docs);
      formdata.append("upload_preset", "ticket-docs");
      await axios
        .post(
          `${process.env.REACT_APP_CLOUDINARY_URL}/${process.env.REACT_APP_CLOUDINARY_USERNAME}/image/upload`,
          formdata
        )
        .then(async (resp) => {
          try {
            await axiosInstance
              .post(`business/ticket`, {
                ...payload,
                docs: resp.data.secure_url,
              })
              .then((resp) => {
                setMsg({ ...msg, spinner: false });
                setShowSupport({
                  ...showSupport,
                  ticketSuccess: true,
                  createTicket: false,
                });
              });

            // handleGetUser();
            handleGetTickets();
          } catch (error) {
            setMsg({ ...msg, spinner: false });
            setShowSupport({ ...showSupport, ticketError: true });
          }
        });
    } else {
      try {
        await axiosInstance.post(`business/ticket`, payload).then((resp) => {
          setMsg({ ...msg, spinner: false });
          setShowSupport({
            ...showSupport,
            ticketSuccess: true,
            createTicket: false,
          });
        });

        // handleGetUser();
        handleGetTickets();
      } catch (error) {
        setMsg({ ...msg, spinner: false });
        setShowSupport({ ...showSupport, ticketError: true });
      }
    }
  }
  async function handleCloseTicket(e, tiid) {
    const status = e.status;
    e.status = "Closed";
    try {
      await axiosInstance.put("/business/ticket/close", { tiid }).then(() => {
        handleGetTickets();
      });
    } catch (error) {
      e.status = status;
    }
  }

  //INVOICES

  async function getInvoices() {
    try {
      await axiosInstance
        .get(`/business/invoice/this/business`)
        .then((resp) => {
          setInvoices(resp?.data?.data);
          // if (resp.status === 200) {
          //   if (resp.data.data) {
          //     setInvoices(resp.data.data);
          //   } else {
          //     setInvoices([]);
          //   }
          // } else {
          // }
        });
    } catch (error) {
      // setMsg({ ...msg, error: error.response.data.message });
    }
  }
  return (
    <variableManager.Provider value={exportData}>
      {children}
    </variableManager.Provider>
  );
}
