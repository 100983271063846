import { BsThreeDotsVertical } from "react-icons/bs";
import done from "../../../icons/done.svg";
import filters from "../../../icons/filters.svg";
import exportImg from "../../../icons/export.svg";
import pending from "../../../icons/pending.svg";
import failed from "../../../icons/failed.svg";
import { useContext, useEffect, useState } from "react";
import { variableManager } from "../../../context-api/VariableContex";
import DisputeTransaction from "../support/DisputeTransaction";
import FilterBy from "./FilterBy";
import axiosInstance from "../../../utils/axios_instance";
import Pagination from "@mui/material/Pagination";

export default function POSTransaction({ terminalId }) {
  const { dashboard, nameCase } = useContext(variableManager);
  // const {raiseTicket, setRaiseTicket} = useContext(KycManager)
  const [raiseTicket, setRaiseTicket] = useState({ action: false, id: "" });

  const [showFilter, setShowFilter] = useState(false);
  //STATES
  const [posTransactions, setPosTransactions] = useState([]);
  const [posTransactionsMutate, setPosTransactionsMutate] = useState([]);
  const [posTransactionsTotal, setPosTransactionsTotal] = useState([]);
  const [page, setPage] = useState(1);

  async function handleGetPOSTransactions(terminalId) {
    try {
      const res = await axiosInstance.get(
        `/business/mpos/transactions/${terminalId}`
      );
      setPosTransactions(res.data.data.data);
      setPosTransactionsMutate(res.data.data.data);
      setPosTransactionsTotal(res.data.data);
    } catch (error) {
      console.log(error);
    }
  }

  const handleChange = async (e, value) => {
    setPage(value);
  };

  useEffect(() => {
    handleGetPOSTransactions(terminalId || 2);
  }, [terminalId]);

  function handleFilter(searchQuery) {
    const data = [...posTransactionsMutate];
    if (data && searchQuery) {
      const result = [];
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        if (
          element?.reference
            ?.toLowerCase()
            .includes(searchQuery?.toLowerCase()) ||
          element?.transactionID
            ?.toLowerCase()
            .includes(searchQuery?.toLowerCase())
        ) {
          result.push(element);
        }
      }
      setPosTransactions(result);
      return result;
    } else {
      setPosTransactions(posTransactionsMutate);
      return data;
    }
  }

  return (
    <div
      className="pos-transactions-container activities-container"
      onClick={() => setShowFilter(false)}
    >
      <div className="my-4">
        <b>Transaction History</b>
      </div>

      <div className="search-filter-section position position-relative">
        <div className="item search">
          <input
            type="search"
            onChange={(e) => setPosTransactions(handleFilter(e.target.value))}
            placeholder="Find by Reference or I.D"
          />
        </div>
        <div className="item">
          <span>Date</span>
          <select name="" id="">
            <option value="">Select Date</option>
          </select>
        </div>
        <div className="item">
          <span>POS</span>
          <select name="" id="">
            <option value="">Select POS</option>
          </select>
        </div>
        <div className="item">
          <span>Transaction Type</span>
          <select name="" id="">
            <option value="">Select Type</option>
          </select>
        </div>{" "}
        <div
          className=" filter "
          onClick={(e) => {
            e.stopPropagation();
            setShowFilter(!showFilter);
          }}
        >
          <img src={filters} draggable="false" alt="filters" />
          {showFilter ? <FilterBy /> : null}
        </div>{" "}
        <div className=" export">
          <img src={exportImg} draggable="false" alt="export" />
        </div>
      </div>

      <div className="table-section">
        <table>
          <thead>
            <tr>
              <td>I.D</td>
              <td>Date & Time</td>
              <td>Account Details</td>
              <td>Transaction Type</td>
              <td>Reference NO:</td>
              <td>Amount</td>
              <td>Status</td>
            </tr>
          </thead>

          {posTransactions.length > 0 ? (
            posTransactions?.map((e, i) => {
              return (
                <tbody key={i}>
                  <tr>
                    <td>{e.tid}</td>
                    <td>{new Date(e.Date).toLocaleString()}</td>
                    <td className="user">
                      {/* <div className="image">
                        <img src={avatar} alt="" />
                      </div> */}
                      <div className="others">
                        <p className="b">
                          {e.fullName ? nameCase(e.fullName) : "@user"}
                        </p>
                        <p>{e.bankName ? nameCase(e.bankName) : "Mongoro"}</p>
                        {/* {e.flw_id} */}
                      </div>
                    </td>
                    <td className="">{e.serviceType}</td>
                    <td className="">
                      <div className="others">
                        <p>{e.reference}</p>
                      </div>
                    </td>
                    <td className="amount">₦{e.amount}</td>
                    <td>
                      {" "}
                      <div className="d-flex align-items-center">
                        <b
                          className={
                            e.status === "Pending" || e.status === "pending"
                              ? " status bg-light"
                              : e.status === "Failed" || e.status === "failed"
                              ? " status failed"
                              : "status"
                          }
                        >
                          <div className="done">
                            {e.status === "Failed" || e.status === "failed" ? (
                              <img
                                src={failed}
                                alt="done"
                                className="img-fluid"
                                // style={{ width: "15px" }}
                              />
                            ) : e.status === "pending" ? (
                              <img
                                src={pending}
                                alt="done"
                                className="img-fluid"
                              />
                            ) : (
                              <img
                                src={done}
                                alt="done"
                                className="img-fluid"
                                style={{ width: "15px" }}
                              />
                            )}
                          </div>{" "}
                          <span
                            className={
                              e.status === "Failed" || e.status === "failed"
                                ? "mo-danger "
                                : e.status === "Pending" ||
                                  e.status === "pending"
                                ? "pending"
                                : ""
                            }
                            style={{ width: "70%", fontSize: "11px" }}
                          >
                            {" "}
                            {e.status}
                          </span>
                        </b>
                        <div className="options">
                          {" "}
                          <BsThreeDotsVertical
                            className="ms-3 text-ligh"
                            onClick={() => {
                              setRaiseTicket({
                                ...raiseTicket,
                                action: !raiseTicket.action,
                                id: e.tid,
                              });
                            }}
                          />
                          {raiseTicket.action && raiseTicket.id === e.tid && (
                            <DisputeTransaction
                              amount={e.amount}
                              category={e.serviceType}
                            />
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              );
            })
          ) : (
            <tbody>
              <tr>
                <td>You have no transaction history</td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
      <div className="activities-pagination">
        <p className="showing">
          {" "}
          Showing <span className="shown">
            {posTransactions?.length}
          </span> of{" "}
          <span className="total">{posTransactionsTotal?.total}</span> enteries
        </p>
        <div>
          <Pagination
            className="pagination"
            count={Math.ceil(
              posTransactionsTotal?.total / posTransactions?.length
            )}
            page={page}
            onChange={handleChange}
            boundaryCount={2}
            siblingCount={1}
            variant="outlined"
            shape="rounded"
            sx={{
              "& .MuiPaginationItem-root": {
                bgcolor: "#F1F1F1",
                color: "#666666",
                "&:hover": {
                  bgcolor: "#666666",
                  color: "#ffffff",
                },
              },

              "& .Mui-selected": {
                bgcolor: "#666666 !important",
                color: "#ffffff !important",
                fontSize: "18px",
                fontWeight: "medium",
              },
            }}
          />
        </div>
      </div>
    </div>
  );
}
