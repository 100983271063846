import dara from "../../../icons/pos-dara.svg";
import amal from "../../../icons/pos-amal.svg";
import sade from "../../../icons/pos-sade.svg";
import "../../../styles/pos.scss";
import { useContext, useEffect, useState } from "react";
import POSTerms from "../../modals/pos/POSTerms";
import { KycManager } from "../../../context-api/KycContext";
import ActivatePOS from "../../modals/pos/ActivatePOS";
import RequestSuccess from "../../modals/pos/RequestSuccess";
import ActivationSuccess from "../../modals/pos/ActivationSuccess";
import RequestPOS from "../../modals/pos/RequestPOS";
import MyTerminals from "./MyTerminals";
import POSTransactions from "./POSTransactions";
import SinglePOS from "./SinglePOS";

export default function POSList() {
  const { showPos, setshowPos } = useContext(KycManager);
  const [selectedPOS, setSelectedPOS] = useState("Mongoro Dara");
  const [navselect, setNavselect] = useState({
    terminals: true,
    manage: false,
    transactions: false,
  });
  // useEffect(() => {
  //   return () => {
  //     setNavselect({
  //       ...navselect,
  //       terminals: true,
  //       manage: false,
  //       transactions: false,
  //     });
  //   };
  // }, []);

  const [initPos] = useState({
    dara: false,
    sade: false,
    amal: false,
  });

  const [posSelect, setPosselect] = useState({
    ...initPos,
    dara: true,
  });

  const [posList] = useState([
    {
      name: "Mongoro Dara",
      image: dara,
      description:
        "High-performance Bluetooth Professional Card ReaderCollect Card payments using the Mongoro Lite  POS Terminals.",
      price: "10,000.00",
      onclick: () => {},
      readyToLaunch: false,
    },
    {
      name: "Mongoro Sade",
      image: sade,
      description:
        "High-performance Bluetooth Professional Card ReaderCollect Card payments using the Mongoro Lite  POS Terminals.",
      price: "10,000.00",
      onclick: () => {},
      readyToLaunch: false,
    },
    {
      name: "Mongoro Amal",
      image: amal,
      description:
        "High-performance Bluetooth Professional Card ReaderCollect Card payments using the Mongoro Lite  POS Terminals.",
      price: "150,000.00",
      onclick: () => {},
      readyToLaunch: true,
    },
  ]);

  const handleSelectPOS = (name) => {
    setSelectedPOS(name);
  };

  return (
    <div className="activities-container">
      <h5>POS</h5>

      <div className="list-body">
        <nav>
          <button
            className={navselect.terminals ? " active-nav-btn" : "nav-btn"}
            onClick={(e) => {
              setNavselect({
                ...navselect,
                terminals: true,
                manage: false,
                transactions: false,
              });
            }}
          >
            Buy POS
          </button>
          <button
            className={navselect.manage ? " active-nav-btn" : "nav-btn"}
            onClick={(e) =>
              setNavselect({
                ...navselect,
                manage: true,
                terminals: false,
                transactions: false,
              })
            }
          >
            My POS
          </button>
          <button
            className={navselect.transactions ? " active-nav-btn" : "nav-btn"}
            onClick={(e) =>
              setNavselect({
                ...navselect,
                transactions: true,
                manage: false,
                terminals: false,
              })
            }
          >
            Transactions
          </button>
        </nav>

        {navselect.terminals ? (
          <div className="pos-body">
            <div className="activate-btn d-flex justify-content-end ">
              <button
                className="mo-active-bt"
                onClick={() => {
                  setshowPos({ ...showPos, terminal_id: true });
                }}
              >
                Activate POS Terminal
              </button>
            </div>

            <h5 className="mb-3">Select POS</h5>

            <div className="list-row">
              {posList?.length
                ? posList.map((pos, i) => {
                    return (
                      <div
                        key={i}
                        className={posSelect.dara ? "pos active-pos " : "pos"}
                        onClick={() => {
                          // setPosselect({ ...initPos, dara: true });

                          setshowPos({ ...showPos, showDetails: true });
                          handleSelectPOS(pos.name);
                        }}
                      >
                        <div className="image">
                          <img src={pos.image} alt={pos.name} />
                        </div>
                        <div className="details">
                          <b>{pos.name}</b>
                          <p>{pos.description}</p>
                        </div>

                        <div className="button">
                          {pos.readyToLaunch && (
                            <div className="price">
                              <span className="amount">N{pos.price}</span>{" "}
                              <span className="subtitle">full price</span>
                            </div>
                          )}
                          {pos.readyToLaunch ? (
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                setshowPos({ ...showPos, request: true });
                              }}
                            >
                              Request
                              {/* Coming Soon */}
                            </button>
                          ) : (
                            <div className="readyToLaunch">
                              <button>Coming Soon</button>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        ) : navselect.manage ? (
          <MyTerminals />
        ) : navselect.transactions ? (
          <POSTransactions />
        ) : null}
      </div>

      <div className="modals">
        <RequestPOS
          show={showPos.request}
          onHide={() => setshowPos({ ...showPos, request: false })}
          backdrop={"static"}
        />

        <POSTerms
          show={showPos.terms}
          onHide={() => setshowPos({ ...showPos, terms: false })}
          backdrop={"static"}
          // scrollable=true
        />
        <ActivatePOS
          show={showPos.terminal_id}
          // show
          onHide={() => setshowPos({ ...showPos, terminal_id: false })}
          backdrop={"static"}
          // scrollable=true
        />
        <RequestSuccess
          show={showPos.request_success}
          onHide={() => setshowPos({ ...showPos, request_success: false })}
          // backdrop={"static"}
          // scrollable=true
        />

        <ActivationSuccess
          show={showPos.activate_success}
          onHide={() => setshowPos({ ...showPos, activate_success: false })}
          // backdrop={"static"}
          // scrollable=true
        />
        {/* <SinglePOS
          show={showPos.showDetails}
          onHide={() => setshowPos({ ...showPos, showDetails: false })}
          image={posList.find((pos) => pos.name === selectedPOS)?.image}
          // backdrop={"static"}
          // scrollable=true
        /> */}
      </div>
    </div>
  );
}
