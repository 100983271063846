import React, {useState, useEffect, useContext} from 'react';
import Navigation from "./../Navigation";
import Sidebar from "./../Sidebar";
import "../../../styles/dashboard.scss";
import "../../../styles/sidebar.scss";
import VerificationStatus from "./../VerificationStatus";
import AccountBalance from "./../AccountBalance";
import TransactionHistory from "./../TransactionHistory";
import Footer from "./../Footer";
import PaymentsNavigation from "./../PaymentsNavigation";
import KycPrompt from "../../modals/KycPrompt";
import KycNIN from "../../modals/KycNIN";
import KycBvn from "../../modals/KycBvn";
import AccountNumber from "../../modals/AccountNumber";
 import KycPassport from "../../modals/KycPassport";
import SelectMeans from "../../modals/SelectMeans";
import BvnSuccess from "../../modals/BvnSuccess";
import CompleteSetup from "../../modals/CompleteSetup";
import { variableManager } from "../../../context-api/VariableContex";
import { KycManager } from "../../../context-api/KycContext";
import AccountType from "../../modals/accounts/AccountType";
import UploadImage from "../../modals/settings/UploadImage";
import axiosInstance from "../../../utils/axios_instance";
import EscrowTransfer from "./../escrowTransfer";
import { useParams, useSearchParams } from 'react-router-dom';
import SubAccountBalance from './subAccountBalance';

export default function SingleSubAccountDashboard() {

  const { id } = useParams();
  const [searchQuery] = useSearchParams();
  const BN = searchQuery.get('bn');
  const ID = searchQuery.get('bId');

  const {
    handleTransactionHistory,
    handleInsights,
    handleNotifications,
    handleLoginActivity,
    handleBankList,
  } = useContext(variableManager);
  const {
    modalShowPrompt,
    modalShowCompletesetup,
    setModalShowCompletesetup,
    modalShowBvn,
    modalShowNin,
    modalShowPassport,
    modalShowAccount,
    modalShowAccountType,
    modalShowSelectMethod,
    setModalShowPrompt,
    setModalShowBvn,
    setModalShowNin,
    setModalShowAccount,
    setModalShowAccountType,
    setModalShowPassport,
    setModalShowSelectMethod,
    showVeriSuccess,
    setShowVeriSuccess,
    profile,
    setProfile,
    showSidebar,
    setShowSidebar,
    view,
    setView,
  } = useContext(KycManager);

  useEffect(() => {

    // const localUser = localStorage.getItem("mg-u-tkn");
    // axiosInstance.defaults.headers.common["x-token"] = localUser;
    handleInsights();
    handleNotifications();
    handleLoginActivity("get");
    handleTransactionHistory();
    handleBankList();
  }, []);

  const [user, setUser] = useState({});
  const [transactions, setTransactions] = useState([])

  const getSubAccount = () => {
    try {
      axiosInstance
        .get(
          `/business/all?limit=100000000000`,
        )
        .then((resp) => {
          if (resp.status === 200) {
            if (resp.data.data.data.length > 0){
              for (let i = 0; i < resp.data.data.data.length; i++){
                if((resp.data.data.data[i].bid) === Number(ID)){
                  setUser(resp.data.data.data[i]);
                  break;
                }
              }
            }
          } else {
          }
        });
    } catch (error) {
      // setMsg({ ...msg, error: error.response.data.message });
    }
  }

  const getSubAccountTransactions = () => {
    try {
      axiosInstance
        .get(
          `/business/sub/transactions/${id}?limit=100000000000`,
        )
        .then((resp) => {
          if (resp.status === 200) {
            if (resp.data.data.data.length > 0){
              setTransactions(resp.data.data.data)
            }
          } else {
          }
        });
    } catch (error) {
      // setMsg({ ...msg, error: error.response.data.message });
    }
  }

  useEffect(() => {
    getSubAccount();
    getSubAccountTransactions();
  }, []);



  return (
    <>
      <div
        className="dashboard-container"
        onClick={() => setView({ ...view, notifications: false })}
      >
        <div className="navigation-section">
          <Navigation />
        </div>
        <div className="body-section">
          <div className={showSidebar ? "sidebar" : "  hide-sidebar "}>
            <Sidebar />
          </div>
          <div
            className="dashboard-body"
            onClick={() => {
              setShowSidebar(false);
            }}
          >
            <p>Sub Account</p>
            <br />
            <h4>
              <b>{user && user.businessName}</b>
            </h4>
            {/* <AccountBalance
            setShowTransfer={setShowTransfer}
            user={user}
            showTransfer={true}
          /> */}
            <SubAccountBalance />
            <TransactionHistory history={transactions} />
            <Footer />
          </div>
        </div>

        <div className="modals">
          <CompleteSetup
            show={modalShowCompletesetup}
            onHide={() => setModalShowCompletesetup(false)}
            backdrop={"static"}
          />

          <UploadImage
            show={profile.imageUpload}
            onHide={() => setProfile({ ...profile, imageUpload: false })}
          />

          <KycPrompt
            show={modalShowPrompt}
            onHide={() => setModalShowPrompt(false)}
            backdrop={"static"}
          />

          <KycNIN
            show={modalShowNin}
            onHide={() => setModalShowNin(false)}
            backdrop={"static"}
          />

          <KycBvn
            show={modalShowBvn}
            onHide={() => setModalShowBvn(false)}
            backdrop={"static"}
          />
          <AccountType
            show={modalShowAccountType}
            onHide={() => setModalShowAccountType(false)}
            backdrop={"static"}
          />

          <AccountNumber
            show={modalShowAccount}
            onHide={() => setModalShowAccount(false)}
            backdrop={"static"}
          />

          <KycPassport
            show={modalShowPassport}
            onHide={() => setModalShowPassport(false)}
            backdrop={"static"}
          />

          <SelectMeans
            show={modalShowSelectMethod}
            onHide={() => setModalShowSelectMethod(false)}
            backdrop={"static"}
          />

          {/* // VERIFICATIONS */}

          <BvnSuccess
            show={showVeriSuccess.bvnSuccess}
            onHide={() =>
              setShowVeriSuccess({ ...showVeriSuccess, bvnSuccess: false })
            }
            backdrop={"static"}
          />
        </div>
      </div>
 /
    </>
  );
}
