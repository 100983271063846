import "../../../styles/settings.scss";
import { useContext, useState, useEffect } from "react";
import { TableWrap } from "../subAccounts/style";
import { useNavigate } from "react-router-dom";
import { DescriptionFrame, InvoiceFlex, ItemTable, ItemTableWrap } from "./style";
import * as Icon from "iconsax-react";
import { handleZero } from "../../../utils/handleZero";
import axios from 'axios';
import Alert from "../../../reusable/alert";
import { ClipLoader } from "react-spinners";
import axiosInstance from "../../../utils/axios_instance";

export default function CreateInvoice() {

    const navigate = useNavigate();
    const [billTo, setBillTo] = useState('')
    const [billToEmail, setBillToEmail] = useState('')
    const [invoiceDate, setInvoiceDate] = useState(Date())
    const [dueDate, setDueDate] = useState('')
    const [invoiceNumber, setInvoiceNumber] = useState('')
    const [invoiceID, setInvoiceID] = useState('')
    const [PONumber, setPONumber] = useState('')
    const [currency, setCurrency] = useState("NGN")
    const [description, setDescription] = useState('')
    const [tax, setTax] = useState('')
    const [deliveryNumber, setDeliveryNumber] = useState('');
    const [onProcess, setOnProcess] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState('')
    const [pdf, setPdf] = useState('')

    const loggedUser = localStorage.getItem('mg-u-tkn') || '{}';

    const [itemArray, setItemsArray] = useState([
    { itemType: '', quantity: 1, unitprice: 0, netAmount: 0, invoiceID: '',  },
  ]);

  let [itemsNumber, setItemsNumber] = useState(1);

  const handleItemIncrement = () => {
    if (itemsNumber <= 10) {
      let subArray = [...itemArray];
      setItemsNumber(++itemsNumber);
      subArray.push({ itemType: '', quantity: 1, unitprice: 0 });
      setItemsArray([...subArray]);
    }
  };

  const handleDelete = index => {
    let mockData = [];
    if (itemArray.length > 1) {
      for (let i = 0; i < itemArray.length; i++) {
        if (i !== index) {
          mockData.push(itemArray[i]);
        }
      }
      setItemsArray(mockData);
    }
  };

  // Get Input

  const addName = (e, index) => {
    itemArray[index].itemType = e;
    setItemsArray([...itemArray]);
  };

  const addquantity = (e, index) => {
    itemArray[index].quantity = e;
    itemArray[index].netAmount = e * itemArray[index].unitprice;
    setItemsArray([...itemArray]);
  };

  const addamount = (e, index) => {
    itemArray[index].unitprice = e;
    itemArray[index].netAmount = e * itemArray[index].quantity;
    setItemsArray([...itemArray]);
  };

  function sum(input) {
    if (toString.call(input) !== '[object Array]') return false;

    var total = 0;
    for (var i = 0; i < input.length; i++) {
      if (isNaN(input[i])) {
        continue;
      }
      total += Number(input[i]);
    }
    return total;
  }

  let newItem = [];

  if (itemArray && itemArray.length > 0) {
    for (var i = 0; i < itemArray.length; i++) {
      newItem.push(Number(itemArray[i].netAmount));
    }
  }

  let subTotal = sum(newItem)
  let grandTotal = (subTotal) + ((Number(tax/100)) * subTotal);

  const filterTaxInput = (e) => {
    if (Number(e) > 100){
        setTax(0)
    }
  }

  useEffect(() => {
    if (invoiceDate) {
      setInvoiceDate(`${invoiceDate.split('T')[0]}`);
    }
  }, [invoiceDate]);

    // Request section

    const basicPayload = {
        invoice: {
            date: Date(),
            billToEmail,
            description,
            billTo,
            invoiceNumber,
            dueDate,
            invoiceDate,
            poNumber: PONumber,
            currency,
            deliveryNumber,
            subTotal,
            tax,
            taxAmount: tax ? (tax/100 * subTotal) : null,
            total: grandTotal
        },
        item: itemArray
    }

    const createInvoice = (e) => {
        e.preventDefault();
        setOnProcess(true)
        axiosInstance.post(`/business/invoice/create`, basicPayload).then((res) => {
            setSuccess(true);
            setOnProcess(false);
            axiosInstance.post(`/business/invoice/sender`, basicPayload ).then((res) => {
                setPdf(res.data.data)
            }).catch((err) => {
                setOnProcess(false);
                setError(err.response.data.message)
                setTimeout(() => {
                    setError('')
                }, 4000)
            })
        }).catch((err) => {
            setOnProcess(false);
            setError(err.response.data.message)
            setTimeout(() => {
                setError('')
            }, 4000)
        })
    }

  return (
    <>
    <div className="settings-container">
        <h5><b>Create a new Invoice</b></h5>
        <div
            className="transaction-history-container"
            style={{
                margin: '2rem 0'
            }}
        >
      <div
        className="table-section"
        style={{
          margin: '0rem 0',
          padding: '0',
          height: 'auto'
        }}
      >
        <TableWrap>
            <form onSubmit={(e) => createInvoice(e)} >
            <h6>Detail Information</h6>
            <hr />
            <div>
                <InvoiceFlex>
                    <div>
                        <h6>Biller Information</h6>
                        <div className="input-area">
                            <div>
                                <label htmlFor>Bill To</label>
                                <input
                                    required
                                    placeholder={''}
                                    value={billTo}
                                    onChange={(e) => setBillTo(e.target.value)}
                                />
                            </div>
                            <div>
                                <label htmlFor=''>Bill to Email</label>
                                <input
                                    required
                                    placeholder={''}
                                    type='email'
                                    value={billToEmail}
                                    onChange={(e) => setBillToEmail(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <h6>Invoice Details</h6>
                        <div className="input-area">
                            <div>
                                <label htmlFor>Invoice Date</label>
                                <input
                                    required
                                    placeholder={''}
                                    type='date'
                                    value={invoiceDate}
                                    onChange={(e) => setInvoiceDate(e.target.value)}
                                    disabled
                                />
                            </div>
                            <div>
                                <label htmlFor=''>Due Date</label>
                                <input
                                    required
                                    placeholder={''}
                                    type='date'
                                    value={dueDate}
                                    onChange={(e) => setDueDate(e.target.value)}
                                />
                            </div>
                            <div>
                                <label htmlFor=''>Invoice Number</label>
                                <input
                                    required
                                    placeholder={''}
                                    type='number'
                                    value={invoiceNumber}
                                    onChange={(e) => setInvoiceNumber(e.target.value)}
                                />
                            </div>
                            <div>
                                <label htmlFor>PO Number</label>
                                <input
                                    required
                                    placeholder={''}
                                    type='number'
                                    value={PONumber}
                                    onChange={(e) => setPONumber(e.target.value)}
                                />
                            </div>
                            <div>
                                <label htmlFor=''>Currency</label>
                                <select
                                    value={currency}
                                    onChange={(e) => setCurrency(e.target.value)}
                                    required
                                >
                                    <option>NGN</option>
                                </select>
                            </div>
                            <div>
                                <label htmlFor>Delivery Number</label>
                                <input
                                    required
                                    placeholder={''}
                                    type='number'
                                    value={deliveryNumber}
                                    onChange={(e) => setDeliveryNumber(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </InvoiceFlex>
            </div>
            <h6>Items</h6>
            <hr />
            <ItemTableWrap>
                <ItemTable
                    bg='#f2f4f5'
                >
                    <div>#</div>
                    <div>ITEM TYPE</div>
                    <div>UNIT PRICE(&#8358;)</div>
                    <div>QUANTITY</div>
                    <div>NET AMOUNT(&#8358;)</div>
                    <div></div>
                </ItemTable>
                {
                    itemArray.map((item, index) => (
                        <ItemTable
                            bg='#fff'
                            key={index}
                        >
                            <div>{index + 1}</div>
                            <div>
                                <input
                                    onChange={(e) => addName(e.target.value, index)}
                                    value={item.itemType}
                                    required
                                />
                            </div>
                            <div>
                                <input
                                    onChange={(e) => addamount(e.target.value, index)}
                                    value={item.unitprice}
                                    onKeyDown={(e) => handleZero(e)}
                                    required
                                />
                            </div>
                            <div>
                                <input
                                    onChange={(e) => addquantity(e.target.value, index)}
                                    value={item.quantity}
                                    onKeyDown={(e) => handleZero(e)}
                                    required
                                />
                            </div>
                            <div>
                                {Number(item.quantity) * (Number(item.unitprice))}
                            </div>
                            <div>
                                {
                                    index > 0 ?
                                        <Icon.Trash
                                            color='#c82b32'
                                            style={{
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => handleDelete(index)}
                                        />
                                    : null
                                }
                            </div>
                        </ItemTable>
                    ))
                }
            </ItemTableWrap>
            <p
                style={{
                    color: '#2963E8',
                    cursor: 'pointer'
                }}
                onClick={() => handleItemIncrement()}
            >
                Add another item
            </p>
            <br />
            <br />
            <h6>Description</h6>
            <hr />
            <DescriptionFrame>
                <div>
                    <div className="input-area">
                        <label htmlFor>Description</label>
                        <textarea
                            required
                            placeholder={''}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            style={{
                                height:'9rem',
                                resize: 'none'
                            }}
                        ></textarea>
                    </div>
                </div>
                <div>
                    <div>
                        <p>Sub total </p>
                        <span>&#8358;{subTotal}</span>
                    </div>
                    <br />
                    <div>
                        <p>Tax(%) </p>
                        <span
                            style={{
                                width: '40%'
                            }}
                        >
                            <input
                                value={tax}
                                onChange={(e) => {
                                    setTax(e.target.value);
                                    filterTaxInput(e.target.value)
                                }}
                                typoe='number'
                                onKeyDown={(e) => handleZero(e)}
                            />
                        </span>
                    </div>
                    <hr />
                    <br />
                    <div>
                        <h5>Total </h5>
                        <h6>&#8358;{grandTotal}</h6>
                    </div>
                </div>
            </DescriptionFrame>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end'
                }}
            >
                <button
                    style={{
                        margin: '2rem 0',
                        padding: '15px 30px',
                        color: '#fff',
                        background: '#3E3E0D'
                    }}
                    type="submit"
                    disabled={onProcess}
                >
                    { onProcess ? <ClipLoader color='#fff' /> : 'Create Invoice'}
                </button>
            </div>
            </form>
        </TableWrap>
      </div>
    </div>



    </div>

    {
        success ?
            <Alert
                closeFunc={() => {
                    setSuccess(false);
                    navigate('/user/dashboard/invoices')
                }}
                type='pdf'
                pdfFile={pdf}
                img='/success.png'
                message='Invoice created!'
                miniMessage="You have successfully created this invoice and it has been sent to the client."
            />
        :null
    }
    {
            error ?
            <Alert
                closeFunc={() => setError('')}
                img='/warning.png'
                message='An error occured!'
                miniMessage={error}
            />
            :null
    }

    </>
  );
}
