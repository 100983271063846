import "../styles/dashboard.scss";
import Footer from "../components/dashboard/Footer";
import Sidebar from "../components/dashboard/Sidebar";
import Navigation from "../components/dashboard/Navigation";
import ActivitiesNav from "../components/dashboard/ActivitiesNav";
import Activities from "../components/dashboard/Activities";
import { useContext, useState } from "react";
import { KycManager } from "../context-api/KycContext";
import CardSelect from "../components/dashboard/card/CardSelect";
import CardDetailsInputModal from "../components/dashboard/card/CardDetailsInputModal";

export default function Card() {
  const { showSidebar, setShowSidebar } = useContext(KycManager);
  const [searchValue, setSearchValue] = useState("");
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="dashboard-container">
      <div className="navigation-section">
        <Navigation />
      </div>

      <div className="body-section">
        <div className={showSidebar ? "sidebar" : "hide-sidebar"}>
          <Sidebar />
        </div>
        <div className="dashboard-body" onClick={() => setShowSidebar(false)}>
          <h5>Manage Card</h5>
          <CardDetailsInputModal open={open} handleClose={handleClose} />
          <CardSelect handleOpen={handleOpen} />

          <Footer />
        </div>
      </div>
    </div>
  );
}
