import axios from "axios";
import { useContext, useState } from "react";
import { Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { KycManager } from "../../../context-api/KycContext";
import { variableManager } from "../../../context-api/VariableContex";
import close from "../../../icons/close.svg";
import "../../../styles/modals.scss";
import axiosInstance from "../../../utils/axios_instance";
export default function CreatePin(props) {
  const { user, name, url } = useContext(variableManager);
  const [current, setCurrent] = useState(new Array(6).fill(""));
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [verify, setVerify] = useState(new Array(6).fill(""));
  const [error, setError] = useState("");

  const {
    handleVerification,
    msg,
    setMsg,
    success,
    setSuccess,
    handleGetUser,
  } = useContext(variableManager);

  const { setShowCreate, showCreate, profile, setProfile } =
    useContext(KycManager);

  const navigate = useNavigate();

  const handleChange = (element, index, toMap) => {
    if (isNaN(element.value)) return false;
    setError("");

    if (toMap === "email") {
      setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
    } else if (toMap === "verify") {
      setVerify([
        ...verify.map((d, idx) => (idx === index ? element.value : d)),
      ]);
    } else if (toMap === "current-pin") {
      setCurrent([
        ...current.map((d, idx) => (idx === index ? element.value : d)),
      ]);
    }

    //Focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  

  function checkKey(){
   
  }

  async function handleCreatePin() {
    setMsg({ ...msg, spinner: true });
    try {
      await axiosInstance
        .put(`/business/pin/create`, { pin: otp.join("").toString() })
        .then((resp) => { 
          setMsg({ ...msg, spinner: false });

          setOtp(new Array(6).fill(""));
          setCurrent(new Array(6).fill(""));
          setVerify(new Array(6).fill(""));

          if (resp.data) {
            handleGetUser();

            //REDIRECT BASED ON ACTIVITY. ACTIVITIES COULD BE FIRST TIME PIN CREATION, PIN CHANGE OR PIN RESET FROM ACCOUNT SETTINGS
            if (profile.changePin || profile.resetPin) {
              setProfile({ ...profile, pinSuccess: true, resetPin: false });
              setTimeout(() => {
                setProfile({ ...profile, pinSuccess: false, resetPin: false });
              }, 2000);
            } else {
              setShowCreate({ ...showCreate, pin: false, pinSuccess: true });
              navigate("/user/dashboard");
              setTimeout(() => {
                setShowCreate({ ...showCreate, pin: false, pinSuccess: false });
              }, 2000);
            }
          }
        });
    } catch (error) {
      setMsg({ ...msg, spinner: false });
      setOtp(new Array(6).fill(""));
      setCurrent(new Array(6).fill(""));
      setVerify(new Array(6).fill(""));

      console.log(error);
    }
  }
  async function handleUpdatePin() {
    setMsg({ ...msg, spinner: true });
    try {
      await axiosInstance
        .put(`/business/pin/edit`, {
          pin: current.join("").toString(),
          newPin: otp.join("").toString(),
        })
        .then((resp) => {
          setMsg({ ...msg, spinner: false });  
          setOtp(new Array(6).fill(""));
          setCurrent(new Array(6).fill(""));
          setVerify(new Array(6).fill(""));
 
          if (resp.data) {
            handleGetUser();
            //REDIRECT BASED ON ACTIVITY. ACTIVITIES COULD BE FIRST TIME PIN CREATION, PIN CHANGE OR PIN RESET FROM ACCOUNT SETTINGS
            setProfile({ ...profile, pinSuccess: true, changePin: false });
            setTimeout(() => {
              setProfile({ ...profile, pinSuccess: false, changePin: false });
            }, 2000);
          }
        });
    } catch (error) {
      console.log(error);
      setMsg({ ...msg, spinner: false });
      setOtp(new Array(6).fill(""));
      setCurrent(new Array(6).fill(""));
      setVerify(new Array(6).fill(""));
      setError(error.response.data.message); 
    }
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered  
      onEscapeKeyDown={(e)=>{e.preventDefault()}}
    >
      <Modal.Header>
        {profile.changePin || profile.resetPin ? (
          <div
            className="modal-header pointer"
            onClick={() =>
              setProfile({ ...profile, changePin: false, resetPin: false })
            }
          >
            <img draggable="false" src={close} alt="close" />
          </div>
        ) : null}
      </Modal.Header>
      <Modal.Body className="modalContent">
        {profile.changePin || profile.resetPin ? (
          <h2>Change PIN</h2>
        ) : (
          <h2>Create PIN</h2>
        )}

        <div className="">
          <div className="content verify-account-form-container w-100 pin-container">
            {profile.changePin || profile.resetPin ? null : (
              <p className="fw-light">Kindly choose your 6-digit PIN</p>
            )}

            <div className="email-sms-otp">
              <div className="email-otp">
                {profile.changePin && (
                  <p className="mt-3">Enter your current PIN</p>
                )}
                {profile.changePin && (
                  <div className="otp-box mb-4">
                    {current.map((data, index) => {
                      return (
                        <input
                          className="otp-field"
                          type="text"
                          name="current"
                          maxLength="1"
                          key={index}
                          value={data}
                          onChange={(e) =>
                            handleChange(e.target, index, "current-pin")
                          }
                          onFocus={(e) => e.target.select()}
                          onKeyDown={(e) => {
                            if (e.keyCode === 8) {
                              // handleDelete(e, index)
                            } else if (e.key === "ArrowLeft") {
                              e.target.previousSibling.focus();
                            } else if (e.key === "ArrowRight") {
                              e.target.nextSibling.focus();
                            }
                          }}
                        />
                      );
                    })}
                  </div>
                )}
                {!profile.changePin || !profile.resetPin ? (
                  <p className="mt-3">Create PIN</p>
                ) : (
                  <p className="mt-3">Enter your new PIN</p>
                )}

                <div className="otp-box mb-4">
                  {otp.map((data, index) => {
                    return (
                      <input
                        className="otp-field"
                        type="text"
                        name="otp"
                        maxLength="1"
                        key={index}
                        value={data}
                        onChange={(e) => handleChange(e.target, index, "email")}
                        onFocus={(e) => e.target.select()}
                        onKeyDown={(e) => {
                          if (e.keyCode === 8) {
                            // handleDelete(e, index)
                          } else if (e.key === "ArrowLeft") {
                            e.target.previousSibling.focus();
                          } else if (e.key === "ArrowRight") {
                            e.target.nextSibling.focus();
                          }
                        }}
                      />
                    );
                  })}
                </div>

                {!profile.changePin || !profile.resetPin ? (
                  <p className="mt-3">Confirm your PIN</p>
                ) : (
                  <p className="mt-3">Confirm your New PIN</p>
                )}
                <div className="otp-box">
                  {verify.map((data, index) => {
                    return (
                      <input
                        className="otp-field"
                        type="text"
                        name="verify"
                        maxLength="1"
                        key={index}
                        value={data}
                        onChange={(e) =>
                          handleChange(e.target, index, "verify")
                        }
                        onFocus={(e) => e.target.select()}
                        onKeyDown={(e) => {
                          if (e.keyCode === 8) {
                            // handleDelete(e, index)
                          } else if (e.key === "ArrowLeft") {
                            e.target.previousSibling.focus();
                          } else if (e.key === "ArrowRight") {
                            e.target.nextSibling.focus();
                          }
                        }}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
            {/* eftre */}
            {error && <div className="error p-3">{error}</div>}

            {(profile.changePin &&
              otp.join("").length === 6 &&
              verify.join("").length === 6 &&
              current.join("").length === 6) ||
            (profile.resetPin &&
              otp.join("").length === 6 &&
              verify.join("").length === 6) ||
            (!profile.resetPin &&
              !profile.changePin &&
              otp.join("").length === 6 &&
              verify.join("").length === 6) ? (
              profile.changePin || profile.resetPin ? (
                <button
                  className={msg.spinner ? "mo-active-btn" : "mo-active-btn"}
                  disabled={msg.spinner ? "{true}" : ""}
                  // msg.spinner? disabled : null

                  onClick={() => {
                    console.log(otp);
                    console.log(verify);
                    console.log(current);

                    if (profile.resetPin) {
                      if (otp.join("") === verify.join("")) {
                        handleCreatePin();
                      } else {
                        setError("PIN fields do not match");
                      }
                    } else if (profile.changePin) {
                      if (otp.join("") === verify.join("")) {
                        setError("");
                        handleUpdatePin();
                      } else {
                        setError("new PIN fields do not match");
                      }
                    } else {
                    }
                  }}
                >
                  {msg.spinner ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Save"
                  )}
                </button>
              ) : (
                <button
                  disabled={msg.spinner ? "{true}" : ""}
                  className="mo-active-btn"
                  onClick={() => {
                    if (otp.join("") === verify.join("")) { 
                      handleCreatePin();
                    } else { 
                      setError("PIN fields do not match");
                    }
                  }}
                >
                  {msg.spinner ? <Spinner size="sm" /> : " Confirm PIN"}
                </button>
              )
            ) : profile.changePin ? (
              <button className="mo-inactive-btn">Save</button>
            ) : profile.resetPin ? (
              <button className="mo-inactive-btn">Save</button>
            ) : (
              <button className="mo-inactive-btn">Confirm PIN</button>
            )}

            {profile.changePin && (
              <p
                className="text-center mt-4 pointer"
                onClick={() => {
                  handleVerification();
                  setProfile({
                    ...profile,
                    changePin: false,
                    verifyEmailPhone: true,
                    selected: {
                      ...profile.selected,
                      resetPin: true,
                      resetPassword: false,
                    },
                  });
                }}
              >
                Can't recall PIN ?{" "}
                <span className="mo-info pointer">Reset</span>
              </p>
            )}

            {success.message && (
              <div className="success">{success.message}</div>
            )}
          </div>
        </div>
        {/* 
                <div className="input-area">
                    <div className="label">
                        <label htmlFor="back">Bank</label>
                    </div>
                    <div className="input">
                        <input type="text" className='accountInput' value={"Mongoro"} disabled/>                 
                    </div>
                </div>
                
                   <div className="input-area">
                    <div className="label">
                        <label htmlFor="enterEvn">Account Number</label>
                    </div>
                    <div className="input">
                        <input type="text" className='accountInput' id="enterBvn"  value={user.account?( user.account.data.accountNumber):null} disabled />
                    </div>
                </div>


                   <div className="input-area">
                    <div className="label">
                        <label htmlFor="enterEvn">Account Name</label>
                    </div>
                    <div className="input">
                        <input type="text" className='accountInput' id="enterBvn"   value={`${nameCase(user.firstName)} ${nameCase(user.middleName)} ${nameCase(user.surname)}`} disabled     />
                    </div>
                </div>



                <div className="buttons">
                    <button className="btn-active ">

                    Continue
                    </button>
                </div> */}
      </Modal.Body>
    </Modal>
  );
}
