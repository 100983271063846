import styled from 'styled-components';

export const InvoiceFlex = styled.div`
    display:flex;
    align-items:flex-start;
    justify-content:space-between;
    gap:20px;
    margin:2rem 0;

    > div {
        width:43%;
    }

    > div:nth-child(2){
        > div {
            display:flex;
            justify-content:space-between;
            flex-wrap:wrap;

            > div {
                width:45%;
            }
        }

        
    }
`

export const ItemTableWrap = styled.div`
    position:relative;
    margin:2rem 0;
`

export const ItemTable = styled.div`
    display:flex;
    justify-content:space-between;
    background: ${p => p.bg || '#fff'};

    > div {
        border-right: 1px solid #737B8B;
        border-bottom: 1px solid #737B8B;
        text-align:center;
        padding:10px 5px;

        > input {
            border:none;
            width:100%;
            text-align:Center;
        }
    }

    > div:first-child{
        width:10%;
    }

    > div:nth-child(2){
        width:25%;
    }

    > div:nth-child(3){
        width:20%;
    }

    > div:nth-child(4){
        width:20%;
    }

    > div:nth-child(5){
        width:20%;
    }

    > div:nth-child(6){
        width:5%;
    }
`

export const DescriptionFrame = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:flex-start;
    
    > div:first-child{
        width:65%;
    }

    > div:nth-child(2){
        width:30%;
        background: #FBF4E6;
        padding:20px;
        margin-top:2rem;

        > div {
            display:flex;
            justify-content:space-between;
        }
    }
`