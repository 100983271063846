import phone from "../../../icons/phone.svg";
import close from "../../../icons/close.svg";
import { useContext, useEffect, useState } from "react";
import { variableManager } from "../../../context-api/VariableContex";
import "../../../styles/dashboard.scss";
import axiosInstance from "../../../utils/axios_instance";
import { IoCheckmarkOutline } from "react-icons/io5";
import { nameCase } from "../../../utils/formatName";

export default function TerminalDetails({ closeFunc, terminalId }) {
  const { user } = useContext(variableManager);

  //STATES
  const [posDetails, setPosDetails] = useState({});
  async function handleGetSinglePOS(terminalId) {
    try {
      const { data } = await axiosInstance.get(`/business/mpos/${terminalId}`);
      setPosDetails(data?.data);
      console.log(data.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    handleGetSinglePOS(terminalId);
  }, [terminalId]);

  return (
    <div className="pos-details-container ticket-details-container">
      <div className="header">
        <div className="id">
          User ID
          <p>#012342432</p>
        </div>
        <div className="contact">
          <div className="icon">
            <img src={phone} alt="phone" />
          </div>
          <div className="details">
            <p className="faint">PHONE</p>
            <b>+2348154799404</b>
          </div>
        </div>
        <div className="ticket-status">
          <span className="close">
            <sup>
              <img
                draggable="false"
                src={close}
                alt="close"
                className="pointer"
                onClick={() => {
                  closeFunc();
                }}
              />
            </sup>
          </span>
        </div>
      </div>

      <div className="post-details  ticket-details mb-4">
        <div className="author">
          <div className="avatar">
            <img src={user.image} alt="user" />
          </div>
          <div className="name ">
            <div className="rank amount fw-bold">User</div>
            <div className="first-name ">{posDetails?.businessName}</div>
            <p className="faint">{posDetails?.email}</p>
          </div>
        </div>

        <div className="about-amount-section">
          <div className="about-ticket w-100">
            <div className="item">
              <p className="faint">Created</p>
              <b>{new Date(posDetails?.timeCreated).toDateString()}</b>
            </div>
            <div className="item">
              <p className="faint">POS Type</p>
              <b>{nameCase(posDetails?.type)}</b>
            </div>
            <div className="item">
              <p className="faint">Number of POS</p>
              <b>{posDetails?.quantity}</b>
            </div>{" "}
            {/* <div className="item">
              <p className="faint">Tier category</p>
              <b>Tier 1</b>
            </div> */}
            <div className="item">
              <p className="faint">Status</p>
              <b>{posDetails?.status}</b>
            </div>
          </div>
        </div>
      </div>

      <div className="ticket-bodyp">
        <div className="description">
          <h6>Manage POS Requests and Validation</h6>

          <div className="request-progress">
            <div className="item">
              <div className="completed-tag">
                {" "}
                <IoCheckmarkOutline />
              </div>
              <div className="text-section">
                <div className="title">POS Request</div>
                <div className="text">
                  Your POS Terminal request was sent successfully, You will get
                  a mail upon Approval
                </div>
              </div>
            </div>{" "}
            <div className="item">
              {posDetails?.approvalStatus ? (
                <div className="completed-tag">
                  <IoCheckmarkOutline />
                </div>
              ) : (
                <div className="tag">2</div>
              )}
              <div className="text-section">
                <div className="title">Approval Status</div>
                <div className="text">
                  Your Terminal Request is as been Approved. You will get a
                  notification once it’s being Shipped out for delivery
                </div>
              </div>
              <div
                className={
                  posDetails?.approvalStatus
                    ? "stage-status-success"
                    : "stage-status"
                }
              >
                {posDetails?.approvalStatus ? "Approved" : "Pending"}
              </div>
            </div>
            <div className="item">
              <div className="tag">3</div>
              {posDetails?.approvalStatus && posDetails?.deliveryStatus ? (
                <div className="completed-tag">
                  <IoCheckmarkOutline />
                </div>
              ) : posDetails?.approvalStatus && !posDetails?.deliveryStatus ? (
                <div className="tag">3</div>
              ) : (
                <div className="pending-tag">3</div>
              )}
              <div className="text-section">
                <div className="title">Delivery</div>

                {posDetails.deliveryStatus?.toLowerCase() === "delivered" ? (
                  <div className="text">
                    {" "}
                    Your Terminal has been shipped on <b>23rd Nov, 2023</b> To:
                    <b>
                      +23408167463572; No 24, W.F. Kumuyi Street, Kado, Abuja
                    </b>
                  </div>
                ) : (
                <div className="text">
                  Your Terminal Request is still pending. you will get a notification upon Approval
                </div> 
                )}
              </div>
              <div
                className={
                  posDetails?.deliveryStatus?.toLowerCase() === "delivered"
                    ? "stage-status-success"
                    : posDetails?.deliveryStatus?.toLowerCase() ===
                      "outfordelivery"
                    ? "stage-status-out"
                    : posDetails?.deliveryStatus?.toLowerCase() === "failed" ||
                      posDetails?.deliveryStatus?.toLowerCase() === "canceled"
                    ? "stage-status-failed"
                    : "stage-status"
                }
              >
                {posDetails?.deliveryStatus?.toLowerCase() === "delivered"
                  ? "Delivered"
                  : posDetails?.deliveryStatus?.toLowerCase() === "pending"
                  ? "Pending"
                  : posDetails?.deliveryStatus?.toLowerCase() === "canceled"
                  ? "Canceled"
                  : posDetails?.deliveryStatus?.toLowerCase() ===
                    "outfordelivery"
                  ? "Out for Delivery"
                  : posDetails?.deliveryStatus?.toLowerCase() === "failed"
                  ? "Failed"
                  : null}
              </div>
            </div>
            <div className="item last-item">
              <div className=" pending-tag ">4</div>
              {posDetails?.deliveryStatus?.toLowerCase() === "delivered" &&
              posDetails?.activationStatus ? (
                <div className="completed-tag">
                  <IoCheckmarkOutline />
                </div>
              ) : posDetails?.deliveryStatus?.toLowerCase() === "delivered" &&
                !posDetails?.activationStatus ? (
                <div className="tag">2</div>
              ) : (
                <div className="pending-tag">2</div>
              )}
              <div className="text-section">
                <div className="title">Activation and Authorization</div>
                <div className="text">
                  Activation nand Authorization of yout terminal is still
                  pending{" "}
                </div>
              </div>
              <div
                className={
                  posDetails?.activationStatus
                    ? "stage-status-success"
                    : "stage-status"
                }
              >
                {posDetails?.activationStatus ? "Activated" : "Pending"}
              </div>{" "}
            </div>
          </div>
        </div>
      </div>

      <div className="ticket-footer"></div>
    </div>
  );
}
