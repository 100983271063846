import React, { useState, useEffect, useContext } from "react";
import {
  ModalWrap,
  ModalChild,
  ModalHeader,
  InputField,
  UnderlayText,
  UserProfileCard,
  IconCard,
  AvatarImage,
} from "../../styles/all";
import * as FeatherIcon from "react-feather";
import { Button } from "./subAccounts/style";
import axios from "axios";
import { useNavigate } from "react-router";
import { ClipLoader } from "react-spinners";
import Alert from "../../reusable/alert";
import { KycManager } from "../../context-api/KycContext";
import { variableManager } from "../../context-api/VariableContex";
import EnterPin from "./subAccounts/enterPin";
import axiosInstance from "../../utils/axios_instance";

const EscrowTransfer = ({ closeFunc, action, subUser }) => {
  const { payModalShow, setPayModalShow, showCreate, setShowCreate } =
    useContext(KycManager);
  const [filled, setFilled] = useState(false);
  const [bankName, setBankName] = useState("");

  const { user, handleValidateBalance } = useContext(variableManager);
  const width = window.innerWidth;
  const navigate = useNavigate();
  const [showPin, setShowPin] = useState(false);
  const [accounts, setAccounts] = useState({});
  const [parent, setParent] = useState({});
  const [selectedOption, setSelectedOption] = useState("1");
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [pin, setPin] = useState("");
  const [onProcess, setOnProcess] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  // Call Function
  const getAccounts = () => {
    axiosInstance
      .get(`/business/sub/dropdown?paginate=false&limit=100000000000`)
      .then((res) => {
        console.log(res);
        setAccounts(res.data.data.invites);
        setParent(res.data.data.parents);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          localStorage.clear();
          navigate("/login");
        }
      });
  };

  const basicPayload = {
    accountType: selectedOption,
    amount,
    narration: description,
    serviceType: "Payment",
    pin,
    walletTag:
      selectedOption === "1"
        ? subUser
          ? subUser.walletTag
          : ""
        : user
        ? user.walletTag
        : "",
  };

  const createTransfer = (e) => {
    e.preventDefault();
    setOnProcess(true);
    if (amount <= (subUser ? subUser.escrowBalance : 0)) {
      axiosInstance
        .post(`/business/sub/transfer`, basicPayload)
        .then((res) => {
          setSuccess(true);
          setOnProcess(false);
          action();
        })
        .catch((err) => {
          setOnProcess(false);
          setError(err.response.data.message);
          setTimeout(() => {
            setError("");
          }, 4000);
        });
    } else {
      setError(
        "Insufficient funds. Kindly fund your wallet or send a lower amount"
      );
    }
  };

  // Get Users
  useEffect(() => {
    getAccounts();
  }, []);

  const toPin = (e) => {
    e.preventDefault();
    setShowPin(true);
  };

  return (
    <>
      <ModalWrap style={success || showPin ? { visibility: "hidden" } : {}}>
        <ModalChild
          style={{
            height: width > 728 ? "60vh" : "100%",
          }}
        >
          <ModalHeader>
            <h3>Transfer from Escrow Wallet</h3>
            <i>
              <FeatherIcon.X onClick={() => closeFunc()} />
            </i>
          </ModalHeader>
          <form onSubmit={(e) => toPin(e)}>
            <UnderlayText>
              Please, enter a new email address to Invite New Users
            </UnderlayText>
            <div className="input-area">
              <InputField
                style={{
                  padding: "0",
                  border: "none",
                }}
              ></InputField>
              <div>
                <label htmlFor>Transfer To</label>
                <select
                  required
                  placeholder={""}
                  style={{
                    boxSizing: "border-box",
                  }}
                >
                  <option value="">Select option</option>
                  <option value={accounts ? accounts.accountType : ""}>
                    {/* {
                        user?.accounts?.length?(
                            user.accounts.map((account)=>{
                                return <option value=""></option>
                            })
                        ):null
                    } */}
                    {accounts ? `${accounts.name} (Sub Account)` : ""}
                  </option>
                  <option value={parent ? parent.accountType : ""}>
                    {/* {parent ? `${parent.name} (Your account)` : ""} */}
                    {parent ? `${user.businessName} (Your account)` : ""}
                  </option>
                </select>
              </div>
              <div>
                <label htmlFor>Amount (&#8358;)</label>
                <input
                  required
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  placeholder={""}
                  type="number"
                />
              </div>
              <div>
                <label htmlFor>Narration</label>
                <input
                  required
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder={""}
                  type="text"
                />
              </div>
            </div>
            <Button
              width="100%"
              bg="#333"
              color="#fff"
              type="submit"
              disabled={onProcess}
              style={
                onProcess
                  ? {
                      cursor: "not-allowed",
                      margin: "2rem 0 0 0",
                    }
                  : {
                      margin: "2rem 0 0 0",
                    }
              }
            >
              {onProcess ? <ClipLoader color="#fff" size={18} /> : "Create"}
            </Button>
          </form>
        </ModalChild>
      </ModalWrap>
      {showPin ? (
        <EnterPin
          closeFunc={() => setShowPin(false)}
          action={setPin}
          handleTransfer={createTransfer}
        />
      ) : null}
      {success ? (
        <Alert
          closeFunc={() => {
            setSuccess(false);
            closeFunc();
            navigate("/user/dashboard/sub-accounts");
          }}
          img="/success.png"
          message="Transfer Successful"
          miniMessage="You have successfully made completed your transfer."
        />
      ) : null}
      {error ? (
        <Alert
          closeFunc={() => setError("")}
          img="/warning.png"
          message="An error occured!"
          miniMessage={error}
        />
      ) : null}
    </>
  );
};

export default EscrowTransfer;
