import React, { useState } from "react";
import CardEmptyState from "./CardEmptyState";
import ProcessingCard from "./ProcessingCard";
import TrackCard from "./TrackCard";

const CardRight = () => {
  const [manageState, setManageState] = useState({
    emptyState: false,
    selectedState: true,
    trackingState: false,
  });
  return (
    <div className="manageCard-rightContainer">
      {manageState.emptyState && <CardEmptyState />}
      {manageState.selectedState && <ProcessingCard />}
      {manageState.trackingState && <TrackCard />}
    </div>
  );
};

export default CardRight;
