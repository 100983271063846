import React, { useState } from "react";
import Card from "../../../asset/icon/Card.svg";
import RightIcon from "../../../asset/icon/RightIcon.svg";
import ManageCardModal from "./ManageCardModal";

const ProcessingCard = () => {
  const [cardIsReady] = useState(true);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <ManageCardModal open={open} handleClose={handleClose} />
      <div className="manageCard-stateContainer">
        <img src={Card} alt="empty state" />
        {!cardIsReady ? (
          <>
            <p>We’ve received your request</p>
            <p>
              We are currently processing your card and would soon send it on
              it’s way to you
            </p>
          </>
        ) : (
          <div className="manageCard-showDetailsBtn">
            <button>
              Show details
              <img src={RightIcon} alt="right" />
            </button>
          </div>
        )}
        {!cardIsReady ? (
          <button>Track Card</button>
        ) : (
          <button onClick={handleOpen}>Manage Card</button>
        )}
      </div>
    </>
  );
};

export default ProcessingCard;
