import { useContext, useState } from "react";
import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { KycManager } from "../../context-api/KycContext";
import { variableManager } from "../../context-api/VariableContex";
import "../../styles/modals.scss";
import doc_upload_success from "../../images/doc_upload_success.svg";
export default function KycDocumentUploadsSuccess(props) {
  const {
    // msg,
    setModalShowBvn,
    setModalShowNin,
    toverify,
    setToverify,
    // handleVerifyBvn,
    showVeriSuccess,
    setShowVeriSuccess,
    handleGenerateAccount,
    handleBankList,
    modalShowDocumentUpload,
    setModalShowDocumentUpload,
  } = useContext(KycManager);
  const [bvn, setBvn] = useState(false);

  const { msg, setMsg, handleVerifyBvn, handleFetchBVN } =
    useContext(variableManager);
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        onHide={() => {
          setShowVeriSuccess({ ...showVeriSuccess, error: "" });
          setMsg({ ...msg, spinner: false });
        }}
      ></Modal.Header>
      <Modal.Body className="modalContent text-center width-90">
        <center>
          <img src={doc_upload_success} draggable="false" alt="Upload success" />
        </center>

        <h2>Documents Successfully Uploaded</h2>
        <p>Business documents uploaded. Kindly provide us with your BVN</p>

        <div className="buttons">
          <button
            className={toverify.bvn ? "btn-active" : "btn-active"}
            disabled={msg.spinner ? "{true}" : ""}
            onClick={() => {
                setModalShowDocumentUpload({
                    ...modalShowDocumentUpload,
                    success: false,
                  });
                setModalShowBvn(true); 
            }}
          >
            {msg.spinner ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              "Continue to BVN"
            )}
          </button>{" "}
          <button
            className={"mo-skip-btn"}
            onClick={() => {
              setModalShowDocumentUpload({
                ...modalShowDocumentUpload,
                success: false,
              });
            }}
          >
            {" "}
            Skip
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
