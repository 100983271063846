import { Modal } from "@mui/material";

import CardRequest from "./CardRequest";
import { useState } from "react";
import ConfirmCardDetails from "./ConfirmCardDetails";

const CardDetailsInputModal = ({ open, handleClose }) => {
  const [step, setStep] = useState(0);

  const handleForwardStep = () => {
    setStep(step + 1);
  };

  const handleBackwardStep = () => {
    setStep(step - 1);
  };

  const stepComponent = () => {
    switch (step) {
      case 0:
        return (
          <CardRequest handleClose={handleClose} next={handleForwardStep} />
        );
      case 1:
        return (
          <ConfirmCardDetails
            handleClose={handleClose}
            prev={handleBackwardStep}
          />
        );
      //   case 2:
      //     return <Completed />;
      default:
        return null;
    }
  };
  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <div className="manageCard-modalContainer">{stepComponent()}</div>
      </Modal>
    </div>
  );
};

export default CardDetailsInputModal;
