import { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import pinsuccess from "../../../icons/pinsuccess.svg";
import close from "../../../icons/close.svg";
import "../../../styles/modals.scss";
import { KycManager } from "../../../context-api/KycContext";
import axios from "axios";
import { variableManager } from "../../../context-api/VariableContex";
import { Spinner } from "react-bootstrap";

export default function BillsPrompt(props) {
  const { setShowBill, showBill, handleBillStatusChange, } = useContext(KycManager);
  const { msg } = useContext(variableManager);
 
  return (
    <div className="sendpayment">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <div
            className="modal-header pointer"
            onClick={() =>
              handleBillStatusChange("switchOff")
            }
          >
            <img draggable="false" src={close} alt="close" />
          </div>
        </Modal.Header>
        <Modal.Body className="modalContent">
          <div className="detail">
            <h5>
              Are you sure you want to delete this{" "}
              {showBill.global.deletePrompt && showBill.actionFrom.airtime
                ? "beneficiary"
                : showBill.global.deletePrompt.beneficiary
                ? "beneficiary"
                : "beneficiary"}{" "}
              ?
            </h5>
          </div>

          <div className="d-flex justify-content-between align-items-center">
            {showBill.global.deletePrompt.airtimeBeneficiary && msg.spinner ? (
              <div className="mo-danger">Removing Beneficiary...</div>
            ) : showBill.global.deletePrompt.device && msg.spinner ? (
              <div className="mo-danger">Removing device...</div>
            ) : (
              <b
                className="mo-danger pointer"
                onClick={() => {
                  if (showBill.global.deletePrompt) {
                    if (showBill.actionFrom.airtime) {
                      // handleDeleteAirtimeBeneficiary()
                    }
                  }
                }}
              >
                Yes, Delete{" "}
                {showBill.global.deletePrompt && showBill.actionFrom.airtime
                  ? "Airtime Beneficiary"
                  : showBill.global.deletePrompt && showBill.actionFrom.cableTv
                  ? "CableTV Beneficiary"
                  : showBill.global.deletePrompt && showBill.actionFrom.electricity
                  ? "CableTV Beneficiary"
                  :"Device"}
              </b>
            )}

            {msg.spinner ? (
              <Spinner />
            ) : (
              <button
                className="mo-active-btn w-25"
                onClick={() =>
                  handleBillStatusChange("switchOff")
                }
              >
                Cancel
              </button>
            )}
          </div>

          {msg.success && <p className="mo-success">{msg.success}</p>}

          {/* {showBill.global.deletePrompt.device && msg.success?(setShowBill({...showBill, deletePrompt:{...showBill.global.deletePrompt, device:false}})):null } */}

          {msg.error && (
            <div className="mo-danger ">
              {msg.error}{" "}
              <span
                className="pointer text-dark"
                onClick={() => {
                  if (showBill.global.deletePrompt.airtimeBeneficiary) {
                    // handleDeleteBank(showBill.global.deletePrompt.airtimeBeneficiaryId);
                  } else if (showBill.global.deletePrompt.device) {
                    // handleLoginActivity(
                    //   showBill.global.deletePrompt.deviceId,
                    //   "delete"
                    // );
                  }
                }}
              >
                Retry
              </span>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}
